<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end"
      >
        <b-button
          v-b-modal.modal-scrollable
          :style="{ background: 'linear-gradient(to right, OrangeRed , DarkRed)', border: 'none', color: 'white' }"
        >
          <feather-icon
            icon="BookOpenIcon"
            style="width: 17px; height: 17px; margin-right: 3px"
          />
          Read Me !!
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="modal-scrollable"
      ref="modalScrollable"
      centered
      title="Feedback Form"
    >
      <b-list-group>
        <b-list-group-item> <b-badge
          variant="primary"
          pill
        >
          score : 5
        </b-badge> Consistently exceeds expectations, demonstrates exceptional skills and mastery of job requirements
        </b-list-group-item>
        <b-list-group-item><b-badge
          variant="primary"
          pill
        >
          score : 4
        </b-badge> Consistently meets and often exceeds expectations
        </b-list-group-item>
        <b-list-group-item><b-badge
          variant="primary"
          pill
        >
          score : 3
        </b-badge> Meets expectations
        </b-list-group-item>
        <b-list-group-item><b-badge
          variant="primary"
          pill
        >
          score : 2
        </b-badge> Partially meets expectations, requires improvement in certain areas
        </b-list-group-item>
        <b-list-group-item><b-badge
          variant="primary"
          pill
        >
          score : 1
        </b-badge> Unsatisfactory
        </b-list-group-item>
      </b-list-group>
      <template #modal-footer>
        <!-- Conditionally hide OK button -->
        <b-button
          variant="primary"
          @click="onOk"
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    onOk() {
      this.$refs.modalScrollable.hide() // Close the modal
    },
  },
}
</script>
