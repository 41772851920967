export default [
  {
    path: '/user-training',
    name: 'user-training',
    component: () => import('@/views/user-training/UserTraining.vue'),
    meta: {
      title: 'User Training',
      requiresAuth: false,
      action: 'browes',
      resource: 'training',
      pageTitle: 'User Training',
      breadcrumb: [
        {
          text: 'User Training',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-training/add',
    name: 'add-user-training',
    component: () => import(
      '@/views/user-training/control-user-training/AddEditUserTraining.vue'
    ),
    meta: {
      title: 'Add User Training',
      requiresAuth: false,
      action: 'create',
      resource: 'user training',
      pageTitle: 'Add User Training',
      breadcrumb: [
        {
          text: 'User Training List',
          to: '/user-training',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-training/:id',
    name: 'edit-user-training',
    component: () => import(
      '@/views/user-training/control-user-training/AddEditUserTraining.vue'
    ),
    meta: {
      title: 'Edit User Training ',
      requiresAuth: false,
      pageTitle: 'User Training',
      breadcrumb: [
        {
          text: 'Uesr Training',
          to: '/user-training',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
