<template>
  <div>
    <!-- CertificatesStatus Card -->
    <b-card
      class="readonlyDiv"
      :style="{ opacity: isVisable.certificatesStatus ? '1' : '0.7' }"
    >
      <div class="have-icon">
        <h1>Status Certificates</h1>
        <feather-icon
          v-if="!isVisable.certificatesStatus"
          icon="EditIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('certificatesStatus')"
        />
        <feather-icon
          v-else
          icon="XSquareIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('certificatesStatus')"
        />
      </div>
      <div :style="{ pointerEvents: isVisable.certificatesStatus ? 'auto' : 'none' }">
        <CertificatesStatus />
      </div>
    </b-card>
    <!-- Permission Certificates Card -->
    <b-card
      class="readonlyDiv"
      :style="{ opacity: isVisable.permissionCertificates ? '1' : '0.7' }"
    >
      <div class="have-icon">
        <h1>Permission Certificates</h1>
        <feather-icon
          v-if="!isVisable.permissionCertificates"
          icon="EditIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('permissionCertificates')"
        />
        <feather-icon
          v-else
          icon="XSquareIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('permissionCertificates')"
        />
      </div>
      <div :style="{ pointerEvents: isVisable.permissionCertificates ? 'auto' : 'none' }">
        <PermissionCertificates />
      </div>
    </b-card>

    <!-- WorkCertificates Card -->
    <b-card
      class="readonlyDiv"
      :style="{ opacity: isVisable.workCertificates ? '1' : '0.7' }"
    >
      <div class="have-icon">
        <h1>Work Certificates</h1>
        <feather-icon
          v-if="!isVisable.workCertificates"
          icon="EditIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('workCertificates')"
        />
        <feather-icon
          v-else
          icon="XSquareIcon"
          class="cursor-pointer"
          size="25"
          @click="toggleEdit('workCertificates')"
        />
      </div>
      <div :style="{ pointerEvents: isVisable.workCertificates ? 'auto' : 'none' }">
        <WorkCertificates />
      </div>
    </b-card>
  </div>
</template>

<script>
import CertificatesStatus from './CertificatesStatus.vue'
import WorkCertificates from './WorkCertificates.vue'
import PermissionCertificates from './PermissionCertificates.vue'

export default {
  components: { WorkCertificates, CertificatesStatus, PermissionCertificates },
  data() {
    return {
      isVisable: {
        certificatesStatus: false,
        workCertificates: false,
        permissionCertificates: false,
      },
    }
  },
  methods: {
    toggleEdit(section) {
      Object.keys(this.isVisable).forEach(key => {
        this.isVisable[key] = key === section ? !this.isVisable[key] : false
      })
    },
  },
}
</script>

  <style lang="scss">
  .readonlyDiv {
    opacity: 0.7;
    transition: opacity 0.3s ease; /* Optional smooth effect */
    .have-icon {
      opacity: 1 !important;
      color: black;
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
