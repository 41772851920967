<template>
  <validation-observer v-slot="{ invalid }">
    <b-row>
      <div
        v-for="(section, sectionIndex) in sections"
        :key="sectionIndex"
        class="like-table mb-4 mr-1"
        style="width: 48%;"
      >
        <b-col
          class="text-center"
          md="12"
        >
          <h2 class="pb-3 text-underline">
            <span
              v-b-popover.hover.top="{ variant: 'Primary' , customClass: 'popover-main-title'}"
              :title="section.mainTitle"
            >
              {{ section.title }}
            </span>
          </h2>
        </b-col>
        <b-row
          v-for="(item, questionIndex) in section.questions"
          :key="questionIndex"
        >
          <b-col
            md="6"
            sm="12"
            class="d-flex align-items-center"
          >
            <h4>{{ item }}</h4>
          </b-col>

          <b-col
            md="6"
            sm="12"
            class="py-1"
          >
            <validation-provider
              v-slot="{ errors }"
              :rules="'required'"
              :name="`${section.title} - ${item}`"
            >
              <b-input-group style="max-width: 500px;">
                <b-input-group-prepend>
                  <b-button
                    v-if="!localReadonlyData"
                    @click="clearRating(sectionIndex, questionIndex)"
                  >
                    Clear
                  </b-button>
                </b-input-group-prepend>
                <b-form-rating
                  :key="ratingKeys[sectionIndex][questionIndex]"
                  v-model="ratings[sectionIndex][questionIndex]"
                  color="#ff8800"
                  :readonly="localReadonlyData"
                  @input="emitValidationState(invalid)"
                />
                <b-input-group-append>
                  <b-input-group-text
                    class="justify-content-center"
                    style="min-width: 3em;"
                  >
                    {{ ratings[sectionIndex][questionIndex] }}
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
              <small
                v-if="errors[0]"
                class="text-danger"
              >  This field is required. Please provide a valid rating. </small>
            </validation-provider>
          </b-col>
        </b-row>
      </div>
    </b-row>
  </validation-observer>
</template>
<script>
export default {
  props: {
    getOldRatingData: {
      type: Array,
      required: true,
    },
    readonlyData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localReadonlyData: this.readonlyData,
      ratings: [],
      ratingKeys: [],
      sections: [
        {
          mainTitle: 'Functional Skills',
          title: 'Quality of Work',
          questions: [
            'Accuracy, neatness and timeliness of work',
            'Adherence to duties and procedures in Job Description and Work Instructions',
            'Synchronization with organizations/functional goals',
          ],
        },
        {
          mainTitle: 'Functional Skills',
          title: 'Work Habits',
          questions: [
            'Punctuality to workplace',
            'Attendance',
            'Does the employee stay busy, look for things to do, takes initiatives at workplace',
            'Submits reports on time and meets deadlines',
          ],
        },
        {
          mainTitle: 'Functional Skills',
          title: 'Job Knowledge',
          questions: [
            'Skill and ability to perform job satisfactorily',
            'Shown interest in learning and improving',
            'Problem solving ability',
          ],
        },
        {
          mainTitle: 'Interpersonal Skills',
          title: 'Interpersonal Relations/Behaviour',
          questions: [
            'Responds and contributes to team efforts',
            'Responds positively to suggestions and instructions and criticism',
            'Keeps supervisor informed of all details',
            'Adapts well to changing circumstances',
            'Seeks feedback to improve',
          ],
        },
        {
          mainTitle: 'Leadership Skills',
          title: 'Leadership Skills',
          questions: [
            'Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles',
            'Innovative thinking - contribution to organizations and functions and personal growth',
            'Work motivation',
          ],
        },
        {
          mainTitle: 'Employee Engagement Skills',
          title: 'Employee Engagement Skills',
          questions: [
            'Punctuality to workplace',
            'Attendance',
            'Does the employee stay busy',
            'look for things to do',
            'takes initiatives at workplace',
          ],
        },
      ],

    }
  },
  watch: {
    getOldRatingData: {
      handler(newData) {
        this.updateOldRatings(newData)
      },
      immediate: true, // Trigger immediately with the current value
      deep: true, // Watch deeply for nested changes
    },
    readonlyData: {
      handler(newValue) {
        this.localReadonlyData = newValue
      },
      immediate: true,
    },
  },
  created() {
    this.updateOldRatings(this.getOldRatingData)
    this.ratings = this.sections.map(section => Array(section.questions.length).fill(null))
    this.ratingKeys = this.sections.map(section => Array(section.questions.length).fill(0))
  },
  methods: {
    updateOldRatings(data) {
      this.ratings = Array.isArray(data)
        ? data.map(row => (Array.isArray(row)
          ? row.map(item => (item === null ? 0 : item))
          : []))
        : []
    },
    clearRating(sectionIndex, questionIndex) {
      this.ratings[sectionIndex][questionIndex] = null
      this.$set(this.ratingKeys[sectionIndex], questionIndex, Date.now())
    },
    emitValidationState(invalid) {
      const areAllRatingsFilled = this.ratings.every(section => section.every(rating => rating !== null))
      this.$emit('updateValidationState', { invalid, areAllRatingsFilled })
    },
    getAllRatings() {
      const result = {}
      const sections = [
        { title: 'quality_of_work', questions: ['q1', 'q2', 'q3'] },
        { title: 'work_habits', questions: ['q1', 'q2', 'q3', 'q4'] },
        { title: 'job_knowledge', questions: ['q1', 'q2', 'q3'] },
        { title: 'interpersonal_skills', questions: ['q1', 'q2', 'q3', 'q4', 'q5'] },
        { title: 'leadership_skills', questions: ['q1', 'q2', 'q3'] },
        { title: 'hr_skills', questions: ['q1', 'q2', 'q3', 'q4', 'q5'] },
      ]

      sections.forEach((section, sectionIndex) => {
        section.questions.forEach((question, questionIndex) => {
          const key = `${section.title}_${question}`
          result[key] = this.ratings?.[sectionIndex]?.[questionIndex] ?? null
        })
      })

      this.$emit('updateRatings', result)
    },
  },
}
</script>
<style>
.like-table{
    border: 1px solid #ebe9f1;
    padding: 25px;
    border-radius: 20px;
}
.popover-main-title .popover-header{
    font-size: 18px !important;
    text-wrap: nowrap !important;
    width: fit-content !important;
}
.popover-main-title .arrow::after{
  border-top-color:  #297cd6 !important;

}
</style>
