<template>

  <b-card>
    <div
      v-if="loadtable"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <validation-observer
      v-else
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="section-components"
          >

            <b-form-group
              label="Section Components "
              label-for="Section Components"
            >
              <b-form-select
                id="section-components"
                v-model="requirements.section_component_id"
                :options="optionsSectionComponents"
                trim
                placeholder="Section_Components"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Label Name EN"
              label-for="Label name en"
            >
              <b-form-input
                id="name_en"
                v-model="requirements.label_lang.name_en"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Label Name AR"
              label-for="label name ar"
            >
              <b-form-input
                id="name_ar"
                v-model="requirements.label_lang.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="required"
            rules="required"
          >
            <b-form-group
              label="required"
              label-for="required"
            >
              <b-form-select
                id="required"
                v-model="requirements.required"
                :options="optionsRequirements"
                trim
                placeholder="Required"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="value_ar"
            rules="required"
          >
            <b-form-group
              label="Value Ar"
              label-for="value ar"
            >
              <b-form-input
                id="value_ar"
                v-model="requirements.value_lang.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="value_ar"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="isNotesSelected"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Value En"
            rules="required"
          >
            <b-form-group
              label="value En"
              label-for="value en"
            >
              <b-form-input
                id="value_en"
                v-model="requirements.value_lang.name_en"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Value En"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-row
          v-if="isSelection"
          class=" m-auto w-100"
        >

          <b-col
            v-for="(selection, index) in requirements.selections"
            :key="index"
            md="10"
          >
            <b-row class=" m-auto w-100">
              <b-col md="9">
                <validation-provider
                  #default="validationContext"
                  :name="'selections value ' + index"
                  rules="required"
                >
                  <b-form-group
                    :label="'Selection value ' + (index + 1)"
                    :label-for="'selection_' + index"
                  >
                    <b-form-input
                      :id="'selection_' + index"
                      v-model="requirements.selections[index]"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Set Selections Value"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="d-flex justify-content-center align-items-center"
              >
                <b-button
                  v-if="requirements.selections.length > 1"
                  variant="danger"
                  @click="removeSelection(index)"
                >
                  Remove
                </b-button>
              </b-col>

            </b-row>

          </b-col>

          <b-col
            md="2"
            class="d-flex justify-content-center align-items-center"
          >
            <span>
              <b-button
                variant="primary"
                style="border-radius: 50%;padding: 8px;"
                @click="addSelection"
              >
                +
              </b-button>
            </span>

          </b-col>
        </b-row>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addrequirements()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addrequirements()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      loadtable: false,
      validationErrors: {},
      errors: {},
      optionsSectionComponents: [],
      optionsRequirements: [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' },
      ],

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const requirements = ref({
      selections: [''],
      label_lang: {
        name_ar: '',
        name_en: '',
      },
      value_lang: {
        name_ar: '',
        name_en: '',
      },
    })
    return {
      getValidationState,
      requirements,
    }
  },
  computed: {
    isNotesSelected() {
      return this.requirements.section_component_id === 1
    },
    isSelection() {
      return this.requirements.section_component_id === 8
    },
  },
  watch: {
    'requirements.section_component_id': function () {
      // Force the reactivity update for the computed property
      this.$forceUpdate()
    },
  },
  mounted() {
    this.showrequirements()
    this.getSectionComponents()
  },
  methods: {
    getSectionComponents() {
      this.loadtable = true
      axios.get('/certificate/section/components').then(res => {
        this.optionsSectionComponents = decryptData(res.data.payload).data.section_components.map(item => ({
          value: item.id,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },
    addSelection() {
      if (!Array.isArray(this.requirements.selections)) {
        this.requirements.selections = []
      }
      this.requirements.selections.push('')
    },
    removeSelection(index) {
      // Remove the selection at the specified index
      this.requirements.selections.splice(index, 1)
    },
    showrequirements() {
      if (this.$route.params.id) {
        axios.get(`certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components/${this.$route.params.SE_id}/section/${this.$route.params.RQ_id}/requirement/${this.$route.params.id}`).then(res => {
          this.requirements = decryptData(res.data.payload).data.requirement

          const sectionComponent = decryptData(res.data.payload).data.requirement.section_component_name
          if (sectionComponent === 'selection') {
            this.requirements.section_component_id = 8
          } else if (sectionComponent === 'notes') {
            this.requirements.section_component_id = 1
          } else if (sectionComponent === 'written') {
            this.requirements.section_component_id = 2
          } else if (sectionComponent === 'check list') {
            this.requirements.section_component_id = 4
          } else if (sectionComponent === 'check list with file') {
            this.requirements.section_component_id = 5
          } else if (sectionComponent === 'date') {
            this.requirements.section_component_id = 7
          }
        })
      } else {
        return false
      }
      return true
    },
    addrequirements() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        formData.append('_method', 'PUT')
        formData.append('section_component_id', this.requirements.section_component_id)
        formData.append('label_name_en', this.requirements.label_lang.name_en)
        formData.append('label_name_ar', this.requirements.label_lang.name_ar)
        formData.append('required', this.requirements.required)
        formData.append('value_en', this.requirements.value_lang.name_en)
        formData.append('value_ar', this.requirements.value_lang.name_ar)
        this.requirements.selections.forEach((selection, index) => {
          formData.append(`selections[${index}]`, selection)
        })

        axios.post(`certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components/${this.$route.params.SE_id}/section/${this.$route.params.RQ_id}/requirement/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'requirements' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true

        const formData = new FormData()

        formData.append('section_component_id', this.requirements.section_component_id)
        formData.append('label_name_en', this.requirements.label_lang.name_en)
        formData.append('label_name_ar', this.requirements.label_lang.name_ar)
        formData.append('required', this.requirements.required)
        formData.append('value_en', this.requirements.value_lang.name_en)
        formData.append('value_ar', this.requirements.value_lang.name_ar)
        this.requirements.selections.forEach((selection, index) => {
          formData.append(`selections[${index}]`, selection)
        })

        axios.post(`certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components/${this.$route.params.SE_id}/section/${this.$route.params.RQ_id}/requirement`, formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'requirements' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
