export default [
  //   "admin","manager","super-manager","process-manager","hr","accountant","super-accountant","markter",engineer-allocation,engineer
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'fa-solid fa-house',
    role: [
      'admin',
      'manager',
      'super-manager',
      'hr',
      'process-manager',
      'accountant',
      'super-accountant',
      'markter',
      'engineer-allocation',
      'engineer',
    ],
  },
  {
    title: 'Workflow Center',
    icon: 'fa-solid fa-school',
    children: [
      {
        title: 'Requests',
        icon: 'fa-solid fa-file-circle-plus',
        route: 'requests',
        role: [
          'admin',
          'manager',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
          'hr',
          'markter',
          'engineer-allocation',
        ],
      },
      {
        title: 'Overdue Requests',
        icon: 'fa-solid fa-triangle-exclamation',
        route: 'requests-over-due',
        role: [
          'admin',
          'manager',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
          'hr',
          'markter',
          'engineer-allocation',
        ],
      },
      {
        title: 'Delay Justifications',
        route: 'late-reasons',
        icon: 'fa-solid fa-question',

        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'process-manager', 'hr'],
      },
    ],
  },
  {
    title: 'People Hub',
    icon: 'fa-solid fa-users',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
    children: [
      {
        title: 'USERS',
        route: 'user-site',
        icon: 'fa-solid fa-person',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'process-manager'],
      },
      {
        title: 'Assets',
        route: 'devices',
        icon: 'fa-solid fa-desktop',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Engineer Setup',
        route: 'engineers',
        icon: 'fa-solid fa-person-circle-check',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'process-manager'],
      },
      {
        title: 'New People',
        route: 'employees',
        icon: 'fa-solid fa-person-through-window',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'hr'],
      },
      {
        title: 'office',
        route: 'office',
        icon: 'fa-solid fa-building',
        action: 'browes',
        role: [
          'admin',
          'manager',
          'super-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'User Training',
        route: 'user-training',
        icon: 'fa-solid fa-user-check',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
    ],
  },
  {
    title: 'Process Settings',
    icon: 'fa-solid fa-screwdriver-wrench',
    role: ['admin', 'manager', 'super-manager'],
    children: [
      {
        title: 'certificate',
        route: 'main-certificate',
        icon: 'fa-certificate',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Departments',
        route: 'departments',
        icon: 'fa-solid fa-industry',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Training',
        route: 'training',
        icon: 'fa-solid fa-chalkboard-user',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },

      {
        title: 'Post',
        icon: 'fa-solid fa-newspaper',
        route: 'post',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'hr'],
      },

      {
        title: 'Product Category',
        route: 'product',
        icon: 'fa-brands fa-product-hunt',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Source Account',
        route: 'source-account',
        icon: 'fa-regular fa-address-card',
        role: ['admin', 'manager', 'super-manager'],
      },
    ],
  },
  {
    title: 'Audit & Service',
    icon: 'fa-solid fa-ticket-simple',
    role: ['admin', 'manager', 'super-manager'],
    children: [
      {
        title: 'All Service',
        route: 'all-service',
        icon: 'fa-brands fa-servicestack',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Service Need Approval',
        route: 'service-need-approval',
        icon: 'fa-solid fa-person-circle-check',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Service Need Set Money',
        route: 'service-need-set-money',
        icon: 'fa-solid fa-money-check',
        action: 'browes',
        role: [
          'admin',
          'manager',
          'super-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'Audit Set Money',
        route: 'set-money',
        icon: 'fa-solid fa-money-check',
        action: 'browes',
        role: [
          'admin',
          'manager',
          'super-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'Audit Assigned',
        route: 'audit-assigned',
        icon: 'fa-regular fa-building',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
      {
        title: 'Audit Not Assigned',
        route: 'audit-not-assigned',
        icon: 'fa-regular fa-building',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager'],
      },
    ],
  },
  {
    title: 'HR',
    icon: 'fa-solid fa-user-tie',
    role: ['admin', 'manager', 'super-manager'],
    children: [
      {
        title: 'Personnel Evaluation',
        icon: 'fa-solid fa-square-poll-vertical',
        route: 'personnel-evaluation',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'hr'],
      },
      {
        title: 'Performance Evaluation',
        icon: 'fa-solid fa-chart-simple',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'hr'],
        children: [
          {
            title: 'Under HR',
            route: 'performance-evaluation',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'hr', 'super-accountant'],
          },
          {
            title: 'Done',
            route: 'performance-evaluation-done',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'hr', 'super-accountant'],
          },
        ],
      },
      {
        title: 'Request Inspection',
        icon: 'fa-solid fa-person-shelter',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'hr'],
        children: [
          {
            title: 'Completed',
            route: 'request-inspection-completed',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'hr', 'super-accountant'],
          },
          {
            title: 'Pending',
            route: 'request-inspection-pending',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'hr', 'super-accountant'],
          },
        ],
      },
      {
        title: 'Schedule',
        icon: 'fa-regular fa-calendar-days',
        action: 'browes',
        role: ['admin', 'manager', 'hr'],
        children: [
          {
            title: 'Pending & Rejected',
            route: 'schedule-pending',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'accountant', 'super-accountant'],
          },
          {
            title: 'Approved',
            route: 'schedule',
            icon: 'fa-regular fa-circle',
            action: 'browes',
            role: ['admin', 'super-manager', 'accountant', 'super-accountant'],
          },
        ],
      },
    ],
  },
  {
    title: 'Sales',
    icon: 'fa-regular fa-credit-card',
    role: ['admin', 'manager', 'super-manager'],
    children: [
      {
        title: 'Offer',
        route: 'offer',
        icon: 'fa-solid fa-money-bill',
        action: 'browes',
        role: ['admin', 'manager', 'markter'],
      },
      {
        title: 'Client',
        route: 'delegator',
        icon: 'fa-solid fa-user-tag',
        action: 'browes',
        role: ['admin', 'manager', 'accountant', 'super-accountant', 'markter'],
      },
      {
        title: 'Facilities',
        route: 'facility',
        icon: 'fa-solid fa-landmark-flag',
        action: 'browes',
        role: [
          'admin',
          'manager',
          'super-manager',
          'accountant',
          'super-accountant',
        ],
      },
    ],
  },
  {
    title: 'Task Management',
    icon: 'fa-solid fa-bars-progress',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
    children: [
      {
        title: 'My Created Tasks',
        route: 'Task Management Created',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
      {
        title: 'My Assigned Tasks',
        route: 'Task Management Assigned',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'process-manager',
          'accountant',
          'super-accountant',
        ],
      },
    ],
  },
  {
    title: 'Generate Report',
    icon: 'fa-solid fa-floppy-disk',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'hr'],
    children: [
      {
        title: 'Create report',
        route: 'auto-generate-report',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'accountant',
          'super-accountant',
          'engineer-allocation',
        ],
      },
      {
        title: 'Templates',
        route: 'templates-auto-generate-report',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: [
          'admin',
          'super-manager',
          'accountant',
          'super-accountant',
          'engineer-allocation',
        ],
      },
      {
        title: 'Word Templates',
        route: 'report',
        icon: 'fa-regular fa-circle',
        action: 'browes',
        role: ['admin', 'manager', 'super-manager', 'engineer-allocation'],
      },
    ],
  },
  {
    title: 'Analysis',
    route: 'analysis',
    icon: 'fa-solid fa-chart-gantt',
    role: ['admin', 'manager', 'super-manager', 'process-manager'],
  },
  {
    title: 'Support Chat',
    route: 'support',
    icon: 'fa-regular fa-comment',
    action: 'browes',
    role: ['admin', 'manager', 'super-manager', 'markter'],
  },
  // {
  //   title: 'Engineers Courses',
  //   route: 'engineers-courses',
  //   icon: 'fa-solid fa-graduation-cap',
  //   action: 'browes',
  //   role: ['admin', 'manager', 'super-manager', 'hr'],
  // },
  // {
  //   title: "Accounting",
  //   icon: "fa-solid fa-receipt",
  //   role: ["admin", "manager", "super-manager", "process-manager"],
  //   children: [
  //     {
  //       title: "Accounts Tree",
  //       route: "accounting",
  //       icon: "fa-regular fa-circle",
  //       action: "browes",
  //       role: [
  //         "admin",
  //         "super-manager",
  //         "process-manager",
  //         "accountant",
  //         "super-accountant"
  //       ]
  //     },
  //     {
  //       title: "Park Entries",
  //       route: "Park Entries",
  //       icon: "fa-regular fa-circle",
  //       action: "browes",
  //       role: [
  //         "admin",
  //         "super-manager",
  //         "process-manager",
  //         "accountant",
  //         "super-accountant"
  //       ]
  //     },
  //     {
  //       title: "Post Entries",
  //       route: "Post Entries",
  //       icon: "fa-regular fa-circle",
  //       action: "browes",
  //       role: [
  //         "admin",
  //         "super-manager",
  //         "process-manager",
  //         "accountant",
  //         "super-accountant"
  //       ]
  //     }
  //   ]
  // },
]
