var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{ref:"countriesTable",attrs:{"api-url":_vm.apiUrl,"add-component-name":_vm.addComponentName,"edit-component":_vm.editComponent,"delete-content":false,"block-content":false,"edit-content":false,"view-content":true,"add-type":_vm.addType,"columns":_vm.filteredColumns,"type":_vm.type},scopedSlots:_vm._u([{key:"cell(auto_proceed)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add auto proceed'),expression:"'+ add auto proceed'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",staticStyle:{"padding":"2px 0px"}},[(data.item.auto_proceed === 1)?_c('span',{on:{"click":function($event){return _vm.AddAutoProceed(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"CheckIcon","size":"25","color":"success"}})],1):_c('span',{on:{"click":function($event){return _vm.AddAutoProceed(data.item.id)}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"20","color":"red"}})],1)])]},null,data)]}},{key:"cell(offer)",fn:function(data){return [(data.item.offer === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add offer'),expression:"'+ add offer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'add-offer-delegator',
            params: { id: data.item.id }
          })}}}):_vm._e(),_vm._t("show",function(){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add offer'),expression:"'+ add offer'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",on:{"click":function($event){return _vm.$router.push({
              name: 'add-offer-delegator',
              params: { id: data.item.id }
            })}}},[(data.item.offer)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.offer.name)+" - "+_vm._s(data.item.offer.amount)+" % ")]):_vm._e()])]},null,data)]}},{key:"cell(view_details)",fn:function(data){return [_vm._t("show",function(){return [_c('div',{staticClass:"text-center"},[(_vm.viewContent)?_c('span',{attrs:{"title":"View"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.$router.push({
                  name: 'show-delegator',
                  params: { id: data.item.id }
                })}}})],1):_vm._e()])]},null,data)]}},{key:"cell(wallet_balance)",fn:function(data){return [(data.item.wallet_balance === null)?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add wallet balance'),expression:"'+ add wallet balance'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer-empty text-center",staticStyle:{"width":"100% !important","height":"24px !important"},on:{"click":function($event){return _vm.$router.push({
            name: 'add-balance',
            params: { id: data.item.id }
          })}}}):_vm._e(),_vm._t("show",function(){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.topright",value:('+ add wallet balance'),expression:"'+ add wallet balance'",modifiers:{"hover":true,"topright":true}}],staticClass:"hover-pointer text-center",on:{"click":function($event){return _vm.$router.push({
              name: 'add-balance',
              params: { id: data.item.id }
            })}}},[(data.item.wallet_balance)?_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(data.item.wallet_balance)+" "+_vm._s(data.item.wallet_currency)+" ")]):_vm._e()])]},null,data)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }