<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :delete-content="true"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(actions_evaluations)="data">
        <div class="py-1">
          <b-button
            size="sm"
            :style="`background-color: ${getBackgroundColor(data.item.source)} !important; border: none`"

            @click="
              $router.push({
                name: 'add-auto-generate-report',
                params: { name : `${data.item.source}` , id: `${data.item.id}`},
              })
            "
          >
            {{ data.item.source }}
          </b-button>
        </div>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'report-template',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'report_name', label: 'report name' },
        { key: 'actions_evaluations', label: 'Generate New Report' },
        { key: 'actions' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = 'report-template?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    getBackgroundColor(source) {
      switch (source) {
        case 'jeem-IECEE':
          return '#b3661c'
        case 'jeem-quality':
          return '#d57922'
        case 'jeem-conformity':
          return '#f78c27'
        case 'sfda':
          return '#6e1fd4'
        case 'sls':
          return '#2bb1ba'
        case 'saber':
          return '#734864'
        case 'saber-shipment':
          return '#8c5a78'
        default:
          return '#b3661c' // Default color
      }
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
