<template>
  <div>
    <general-table
      ref="reportTable"
      :api-url="apiUrl"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :viw-component="viwComponent"
      :columns="columns"
      :type="type"
    >
      <template #cell(ce_level_before)="data">
        <div class="d-flex align-items-center justify-content-center px-3">
          <ul>
            <li
              v-for="(item , index) in data.item.ce_level_before"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </template>
      <template #cell(ce_level_after)="data">
        <div class="d-flex align-items-center justify-content-center  px-3">
          <ul>
            <li
              v-for="(item , index) in data.item.ce_level_after"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </template>
      <template #cell(next_evaluation_date)="data">
        <span>{{ getNewDate(data.item.next_evaluation_date) }}</span>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import { convertDate } from '@/libs/getDate'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'https://gulftic-system.fci.group/api/personnel-evaluation',
      viewContent: false,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      viwComponent: 'view-personnel-evaluation',
      columns: [
        { key: 'id', sortable: true },
        { key: 'employee_user.name', label: 'Employee Name' },
        // { key: 'external/other name',},
        // { key: 'branch',},
        // { key: 'scheme owner',},
        { key: 'ce_level_before', label: 'Ce Level Before Evaluation' },
        { key: 'conclusion', label: 'Conclusion' },
        { key: 'ce_level_after', label: 'Ce Level After Evaluation' },
        { key: 'evaluator_user.name', label: 'Evaluator Name' },
        // { key: 'evaluation_date', label: 'Evaluation Date' },
        { key: 'next_evaluation_date', label: 'Next Evaluation Date' },
        { key: 'activities', label: 'Activities' },
        { key: 'remarks', label: 'Remarks' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'https://gulftic-system.fci.group/api/personnel-evaluation?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    getNewDate(date) {
      return convertDate(date)
    },
  },
}
</script>

<style></style>
