<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
    >

      <template #cell(actions_inspection)="data">
        <feather-icon
          icon="EditIcon"
          class="cursor-pointer"
          @click="
            $router.push({
              name: 'edit-request-inspection',
              params: {
                id: data.item.id,
                name: data.item.inspector && data.item.inspector.name
                  ? data.item.inspector.name
                  : 'No Inspector Assign'
              }
            })
          "
        />
      </template>

    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'request-inspection?status=pending',
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        // { key: 'request_id', label: 'Request Id' },
        { key: 'request.request_code', label: 'Request Code' },
        { key: 'inspector.name', label: 'Inspector' },
        { key: 'date', label: 'date' },
        { key: 'actions_inspection' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'request-inspection?status=pending?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

    <style></style>
