<template>
  <b-row class="mb-2 ">
    <b-col
      cols="6"
      class="d-flex flex-column justify-content-center align-iteam-center"
    >
      <b-row class="align-items-center h-100">
        <b-col
          v-if="searchBox"
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-if="!dataLoad"
            v-model="localSearchQuery"
            class="d-inline-block"
            :placeholder="
              ShowDateInput ? 'Search & Filter...' : 'Search...'
            "
            @keyup.enter="searchTable"
          />

        </b-col>
        <b-col
          cols="5"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div
            v-if="ShowDateInput"
            class="w-100"
          >
            <v-select
              v-if="!dataLoad"
              v-model="LocalSelectedItem"
              class="d-inline-block w-100"
              :options="SelectedItemSearchOptions"
              placeholder="Select By Coulumn"
              label="text"
              :searchable="true"
              :clearable="false"
              @input="handleSearchItems"
            />
          </div>

        </b-col>

        <b-col
          v-if="ShowDateInput"
          cols="1"
          md="1"
          class="bg-balck"
        >
          <b-btn
            v-if="!dataLoad"
            pill
            variant="outline-primary"
            @click="addNewSearchAndSelectedItem"
          >
            +
          </b-btn>
        </b-col>

      </b-row>

      <!-- Dynamically added search and select inputs -->
      <b-row
        v-for="(item, index) in LocalSearchItems"
        :key="index"
        class="mt-2"
      >
        <b-col
          v-if="!dataLoad"
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-form-input
            v-model="item.searchQuery"
            class="d-inline-block"
            placeholder="Search..."
            @keyup.enter="searchTable"
          />
        </b-col>

        <b-col
          v-if="!dataLoad"
          cols="5"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="item.selectedOption"
            class="d-inline-block w-100"
            :options="SelectedItemSearchOptions"
            placeholder="Select By Column"
            label="text"
            :searchable="true"
            :clearable="false"
          />
        </b-col>

        <b-col
          v-if="!dataLoad"
          cols="1"
          md="1"
        >
          <b-btn
            pill
            variant="outline-danger"
            @click="removeSearchAndSelectedItem(index)"
          >
            -
          </b-btn>
        </b-col>
      </b-row>

    </b-col>

    <b-col
      v-if="ShowDateInput"
      cols="6"
      class="mt-2 d-flex align-items-center justify-content-end"
    >
      <b-btn
        v-if="!dataLoad"
        variant="primary"
        @click="searchTable"
      >Search</b-btn>
    </b-col>

  </b-row>
</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    searchBox: {
      type: Boolean,
      default: () => true,
    },
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
    ShowDateInput: {
      type: Boolean,
      default: false,
    },
    getAllData: {
      type: Function,
      default: () => () => {},
    },
    SelectedItemSearchOptions: {
      type: Array,
      default: () => [],
    },
    searchAndSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSearchQuery: this.$route.query.search || '',
      LocalSearchItems: [],
      LocalSelectedItem: '' || { value: 'all', text: 'All Data' },
    }
  },
  computed: {
    handleSearchItems() {
      return this.$emit('handelSelectedItem', this.LocalSelectedItem)
    },
  },

  mounted() {
    this.getRouting()
  },
  methods: {
    getRouting() {
      if (this.$route.query.search) {
        this.$emit('handleSearchQuery', this.$route.query?.search)
        this.localSearchQuery = this.$route.query?.search
        this.searchTable()
      }
    },

    async searchTable() {
      // Check if localSearchQuery is empty
      if (this.localSearchQuery === '') {
        await this.$router.push({ query: { ...this.$route.query, search: this.localSearchQuery } })
        this.getAllData()
      } else {
        // Check if the current query matches the new search query
        if (this.$route.query.search !== this.localSearchQuery) {
          await this.$router.push({ query: { ...this.$route.query, search: this.localSearchQuery } })
        }
        this.getAllData()
      }
    },

    addNewSearchAndSelectedItem() {
      // Add a new object with initial values for localSearchQuery and selectedOption
      this.LocalSearchItems.push({
        searchQuery: '',
        selectedOption: null,
      })
      this.$emit('handleSearchItemsArray', this.LocalSearchItems)
    },
    removeSearchAndSelectedItem(index) {
      // Remove the item at the specified index
      this.LocalSearchItems.splice(index, 1)
      this.$emit('handleSearchItemsArray', this.LocalSearchItems)
    },

  },
}
</script>
