<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :block-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
      :add-component-name="addComponentName"
      :add-type="addType"
      :edit-content="true"
      :delete-content="true"
      :edit-component="editComponent"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      viewContent: true,
      addType: 'Add Source Account',
      addComponentName: 'add-source-account',
      type: 'page',
      filter: {},
      editComponent: 'edit-source-account',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'password', label: 'Password' },
        { key: 'region', label: 'Region' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      const base = 'source-account?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  mounted() {
    // console.log('Count in mounted:', this.role)
  },

  methods: {
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style></style>
