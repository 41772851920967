export default [
  {
    path: '/delegator',
    name: 'delegator',
    component: () => import('@/views/delegator/Delegator.vue'),
    meta: {
      title: 'Client ',
      requiresAuth: false,
      action: 'browes',
      resource: 'delegator',
      pageTitle: 'Client',
      breadcrumb: [
        {
          text: 'Client',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/show-delegator/:id',
    name: 'show-delegator',
    component: () => import('@/views/delegator/ShowInfoDelegator.vue'),
    meta: {
      title: 'show-client',
      requiresAuth: false,
      action: 'browes',
      resource: 'show-delegator',
      pageTitle: 'show-client',
      breadcrumb: [
        {
          text: 'Client',
          to: '/delegator',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-auto-proceed/:id',
    name: 'add-auto-proceed',
    component: () => import('@/views/delegator/control-delegator/AddAutoProceed.vue'),
    meta: {
      title: 'add-auto-proceed',
      requiresAuth: false,
      action: 'browes',
      resource: 'add-auto-proceed',
      pageTitle: 'add-auto-proceed',
      breadcrumb: [
        {
          text: 'delegator',
          to: '/delegator',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-balance/:id',
    name: 'add-balance',
    component: () => import('@/views/delegator/control-delegator/AddBalance.vue'),
    meta: {
      title: 'add-balance',
      requiresAuth: false,
      action: 'browes',
      resource: 'add-balance',
      pageTitle: 'add-balance',
      breadcrumb: [
        {
          text: 'delegator',
          to: '/delegator',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-offer-delegator/:id',
    name: 'add-offer-delegator',
    component: () => import('@/views/delegator/control-delegator/AddOffer.vue'),
    meta: {
      title: 'add-offer ',
      requiresAuth: false,
      action: 'browes',
      resource: 'add-offer',
      pageTitle: 'add-offer',
      breadcrumb: [
        {
          text: 'delegator',
          to: '/delegator',
        },
        {
          text: 'Info',
          active: true,
        },
      ],
    },
  },
  //   {
  //     path: "/delegator/add",
  //     name: "add-delegator",
  //     component: () => import("@/views/offer/control-offer/AddEditOffer.vue"),
  //     meta: {
  //       title: "Add Offer ",
  //       requiresAuth: false,
  //       action: "create",
  //       resource: "offer",
  //       pageTitle: "Offer",
  //       breadcrumb: [
  //         {
  //           text: "Offer",
  //           to: "/offer"
  //         },
  //         {
  //           text: "Add",
  //           active: true
  //         }
  //       ]
  //     }
  //   },
  //   {
  //     path: "/offer/edit/:id",
  //     name: "edit-offer",
  //     component: () => import("@/views/offer/control-offer/AddEditOffer.vue"),
  //     meta: {
  //       title: "Edit Offer ",
  //       requiresAuth: false,
  //       pageTitle: "Offer",
  //       breadcrumb: [
  //         {
  //           text: "Offer",
  //           to: "/offer"
  //         },
  //         {
  //           text: "Edit",
  //           active: true
  //         }
  //       ]
  //     }
  //   }
]
