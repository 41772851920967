/* eslint-disable */

import axios from "axios";
import Swal from "sweetalert2";

export default function createInvoice(invoiceId, officeId, reqestCode) {
  Swal.fire({
    title: "Download Invoice?",
    text: `Invoice For request ${reqestCode}!`,
    // icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, downlaod it!",
    showLoaderOnConfirm: true,
    preConfirm: async () => {
      try {
        const response = await axios.post(
          "zoho-invoice",
          {
            office_id: JSON.stringify(officeId),
            invoice_id: invoiceId
          },
          { responseType: "blob" }
        );
        if (response.status === 200) {
          const now = new Date();
          const formattedDate = now.toISOString().split("T")[0];
          const InvoiceName = `Invoice ${reqestCode} ${formattedDate} `;
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = InvoiceName;
          link.click();
        }
      } catch (error) {
        Swal.showValidationMessage(`
          Request failed: ${error}
        `);
      }
    },
    allowOutsideClick: () => !Swal.isLoading()
  }).then(result => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "Download Invoice Successfully",
        icon: "success",
        draggable: true
      });
    }
  });
}
