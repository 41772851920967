<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <!-- Title and Date Inputs -->
        <b-col md="6">
          <b-form-group
            label="Title"
            label-for="Title"
          >
            <b-form-input
              id="title"
              v-model="entriesForm.title"
              trim
              placeholder="Title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Date"
            label-for="date"
          >
            <b-form-input
              id="date"
              v-model="entriesForm.date"
              type="date"
              trim
              placeholder="Date"
            />
          </b-form-group>
        </b-col>

        <!-- Description Input -->
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="entriesForm.description"
              trim
              placeholder="Description"
            />
          </b-form-group>
        </b-col>

        <!-- Transactions Section with Labels -->
        <b-col md="12">
          <b-form-group label="Transactions">
            <!-- Always Display Balance -->
            <b-col cols="12">
              <h3
                class="font-weight-bold text-center"
                :class="{ 'text-danger': balance !== 0, 'text-success': balance === 0 }"
              >
                <span :style="`border: 3px solid ${balance !== 0 ? '#eb5454' : '#28c76f'};display: inline-block; padding: 13px;`">
                  Current Balance: {{ balance }}
                </span>
              </h3>
            </b-col>
            <b-row
              v-for="(transaction, index) in transactions"
              :key="index"
              class="transaction-row"
              style="margin-top: 1rem; border: 1px solid #ccc; margin-left: 8px; width: 99%; padding: 8px"
            >
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group label="Account">
                  <v-select
                    v-model="transaction.account_id"
                    :options="accountOptions"
                    placeholder="Select Account"
                    label="text"
                    :searchable="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group label="Type">
                  <b-form-select
                    v-model="transaction.type"
                    :options="transactionTypes"
                    placeholder="Select Type"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group label="Currency">
                  <v-select
                    v-model="transaction.currency"
                    :options="currencyOptions"
                    placeholder="Select Currency"
                    label="text"
                    :searchable="true"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group label="Rate">
                  <b-form-input
                    v-model="transaction.rate"
                    placeholder="Rate"
                    type="number"
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="2"
              >
                <b-form-group label="Amount">
                  <b-form-input
                    v-model="transaction.amount"
                    placeholder="Amount"
                    type="number"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button for Rows After the First Two -->
              <b-col
                v-if="index > 1"
                cols="12"
                md="12"
                class="text-right"
              >
                <b-button
                  variant="danger"
                  size="sm"
                  @click="removeTransaction(index)"
                >
                  Remove
                </b-button>
              </b-col>
            </b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                variant="secondary"
                class="mt-2"
                @click="addTransaction"
              >
                + Add Transaction
              </b-button>
            </b-col>
          </b-form-group>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>

      <!-- Save/Add Buttons -->
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || balance !== 0 || Object.values(errors).length > 0"
            @click="addEntries"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || balance !== 0 || Object.values(errors).length > 0"
            @click="addEntries"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            /> Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    'v-select': VSelect, // Register vue-select component
  },
  data() {
    return {
      validationErrors: {},
      errors: {},
      loader: false,
      // Initialize currency options
      currencyOptions: [],
      accountOptions: [],
      transactionTypes: [
        { value: 'debit', text: 'Debit' },
        { value: 'credit', text: 'Credit' },
      ],
      transactions: [
        {
          account_id: '', currency: '', rate: '', type: 'debit', amount: '',
        },
        {
          account_id: '', currency: '', rate: '', type: 'credit', amount: '',
        },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const entriesForm = ref({})
    return {
      getValidationState,
      entriesForm,
    }
  },
  computed: {
    balance() {
      return this.transactions.reduce((total, transaction) => {
        const transactionAmount = transaction.amount * transaction.rate || 0
        return transaction.type === 'debit'
          ? total + transactionAmount
          : total - transactionAmount
      }, 0)
    },
  },
  mounted() {
    // Fetch currency options from the API
    axios
      .get('https://gulftic-system.fci.group/api/currency/available')
      .then(res => {
        this.currencyOptions = decryptData(res.data.payload).data?.currencies?.map(currency => ({
          value: currency,
          text: currency,
        }))
      })

    // Fetch accounts from the API
    axios
      .get('https://gulftic-system.fci.group/api/admin/accounting-account/sub')
      .then(res => {
        const accounts = decryptData(res.data.payload)?.data?.accounts || []
        this.accountOptions = accounts.map(account => ({
          value: account.id, // Account code as value
          text: `(${account.code}) ${account.name}`, // Account name as text
        }))
      })
    // Fetch accounts from the API
    axios
      .get(`accounting-entry/${this.$route.params.id}`)
      .then(res => {
        const parkEntries = decryptData(res.data.payload)?.data?.entry
        this.entriesForm = {
          date: parkEntries.date,
          description: parkEntries.description,
          title: parkEntries.title,
        }
        this.transactions = parkEntries.transactions.map(transaction => ({
          amount: transaction.amount,
          rate: transaction.rate,
          type: transaction.type,
          currency: {
            value: transaction.currency, // Currency as value
            text: transaction.currency, // Currency as text
          },
          account_id: {
            value: transaction.account_id, // Account code as value
            text: `(${transaction.account.code}) ${transaction.account.name}`, // Account name as text
          },
        }))

        // console.log(parkEntries)
      })
  },
  methods: {
    addTransaction() {
      this.transactions.push({
        account_id: '',
        currency: '',
        rate: '',
        type: 'debit',
        amount: '',
      })
    },
    removeTransaction(index) {
      this.transactions.splice(index, 1)
    },
    addEntries() {
      const allDataForm = {
        ...this.entriesForm,
        transactions: this.transactions.map(transaction => ({
          amount: transaction.amount,
          currency: transaction.currency.value, // Extract only the value
          rate: transaction.rate,
          type: transaction.type,
          account_id: transaction.account_id.value, // Extract only the value
        })),
      }
      // console.log('allData', allDataForm)

      this.loader = true
      const formData = new FormData()

      // Use Object.entries to iterate over allDataForm and append fields
      Object.entries(allDataForm).forEach(([key, value]) => {
        if (key === 'transactions') {
          value.forEach((transaction, index) => {
            Object.entries(transaction).forEach(([transactionKey, transactionValue]) => {
              formData.append(`transactions[${index}][${transactionKey}]`, transactionValue)
            })
          })
        } else {
          formData.append(key, value)
        }
      })

      const url = this.$route.params.id
        ? `accounting-entry/${this.$route.params.id}`
        : 'accounting-entry'
      const method = this.$route.params.id ? 'PUT' : 'POST'
      if (method === 'PUT') formData.append('_method', 'PUT')

      axios
        .post(url, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'Park Entries' })
            this.$toasted.show(
              this.$route.params.id ? 'Updated Successfully' : 'Created Successfully',
              {
                position: 'top-center',
                duration: 3000,
              },
            )
          }
        })
        .catch(error => {
          if (error.response && error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
