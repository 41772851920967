<template>
  <b-row>
    <!-- <b-row class="w-100">
      <b-col md="12" class="text-center py-2">
       <h1 style="color: #0081f2;"> Number Of Requests: </h1>
      </b-col>
    </b-row> -->
    <b-row class="w-100 d-flex justify-content-center">

      <b-col
        v-for="(item, index) in dataTable"
        :key="index"
        md="2"
        class="h-100 w-100 d-flex align-items-center justify-space-evenly"
      >
        <b-row :class="`w-100 h-100 box box${index + 1}`">
          <b-col
            md="12"
          >
            <h2 class="text-light">
              {{ item[0] }}
            </h2>
            <h5 class="text-center text-light w-100">
              <span>Number of Requests:</span>  {{ item[1] }}
            </h5>
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-center"
          />
        </b-row>
      </b-col>
    <!--
    <b-col md="2">
      <div class="box box2">
        <div class="details">
          <h3>422</h3>
          <h4>VIEWS</h4>
        </div>
        <div>
          <feather-icon
            icon="ArrowUpRightIcon"
          />
        </div>
      </div>
    </b-col>
    <b-col md="2">

      <div class="box box3">
        <div class="details">
          <h3>311</h3>
          <h4>LEADS</h4>
        </div>
        <div>
          <feather-icon
            icon="ArrowUpRightIcon"
          />
        </div>
      </div>
    </b-col>
    <b-col md="2">

      <div class="box box4">
        <div class="details">
          <h3>22</h3>
          <h4>SALES</h4>
        </div>
        <div>
          <feather-icon
            icon="ArrowUpRightIcon"
          />
        </div>
      </div>
    </b-col>
    <b-col md="2">

      <div class="box box4">
        <div class="details">
          <h3>22</h3>
          <h4>SALES</h4>
        </div>
        <div>
          <feather-icon
            icon="ArrowUpRightIcon"
          />
        </div>
      </div>
    </b-col>
    <b-col md="2">

      <div class="box box4">
        <div class="details">
          <h3>22</h3>
          <h4>SALES</h4>
        </div>
        <div>
          <feather-icon
            icon="ArrowUpRightIcon"
          />
        </div>
      </div>
    </b-col> -->

    </b-row>
  </b-row>
</template>

<script>

export default {
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
  },
  // data() {
  //   return {
  //     dataTable: [],
  //   }
  // },
}
</script>
<style scoped>
.box {
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  text-shadow: 0 1px 1px 1px #666;
  box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
  border-radius: 5px;
}
.box1 {
  background-image: linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%);
}

.box2 {
  background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);

}

.box3 {
  background-image: linear-gradient( 135deg, #c793aa 10%, #8c5a78 100%);

}

.box4 {
  /* background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%); */
  background-image: linear-gradient( 135deg, #f7c486 10%,  #de7e23 100%);
}
.box5 {
  background-image: linear-gradient( 135deg,   #99ccff 10%, #4d1694 100%);
}
/* .box6 {
  background-image: linear-gradient( 135deg, #EE9AE5 10%, #6e1fd4 100%);
} */
</style>
