<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="issue"
            rules="required"
          >
            <b-form-group
              label="Task"
              label-for="Task"
            >
              <b-form-input
                id="issue"
                v-model="taskForm.issue"

                :state="getValidationState(validationContext)"
                trim
                placeholder="add your task"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="priority"
            rules="required"
          >
            <b-form-group
              label="priority"
              label-for="priority"
            >
              <v-select
                id="priority"
                v-model="taskForm.priority"
                :options="priorityOptions"
                :state="getValidationState(validationContext)"
                trim
                placeholder="choose priority"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="required action"
            rules="required"
          >
            <b-form-group
              label="Required Action"
              label-for="Required Action"
            >
              <b-form-input
                id="required_action"
                v-model="taskForm.required_action"
                :state="getValidationState(validationContext)"
                trim
                placeholder="add your required action"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="due date"
            rules="required"
          >
            <b-form-group
              label="Due Date"
              label-for="Due Date"
            >
              <b-form-input
                id="due_date"
                v-model="taskForm.due_date"
                type="date"
                :state="getValidationState(validationContext)"
                trim
                placeholder="due date"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Request Number"
            label-for="Request Number"
          >
            <b-form-input
              id="request_id"
              v-model="taskForm.request_id"
              trim
              placeholder="Add Your Request Number (If Any)"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="assigned to"
            rules="required"
          >
            <b-form-group
              label="Assigned to"
              label-for="Assigned to"
            >
              <v-select
                id="assigned_to"
                v-model="taskForm.assigned_to"
                :options="AssignedToOptions"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Select a person or multiple people to assign"
                multiple
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="10">
          <b-form-group
            label="Attachments"
            label-for="Attachments"
          >
            <b-form-file
              id="attachments"
              v-model="taskForm.attachments"
              multiple
              trim
              :accept="'.jpg, .jpeg, .png, .pdf, .doc, .docx'"
              placeholder="Accepts: Images, PDF, Word files (DOC, DOCX)"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="haveAttatchmentList"
          md="12"
        >
          <div
            v-for="attachment in attatchmentList"
            :key="attachment.id"
            class="attachment-item"
          >

            <template>
              <a
                :href="attachment.url"
                target="_blank"
                class="attachment-link"
                style="text-decoration: underline;"
              >
                {{ attachment.url }}
              </a>
            </template>
            <b-button
              class="ml-5"
              variant="outline-danger"
              style="border-radius: 50%; padding: 5px"
              @click="deleteAttachment(attachment.id)"
            >
              X
            </b-button>
          </div>
        </b-col>

        <b-col md="12">

          <b-form-group
            label="Description"
            label-for="Description"
          >
            <b-form-textarea
              id="description"
              v-model="taskForm.description"
              type="date"
              trim
              placeholder="write your description"
            />
          </b-form-group>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addTask()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addTask()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'

import decryptData from '@/store/auth/decryptData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
    'v-select': VSelect,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      priorityOptions: ['low', 'medium', 'high'],
      AssignedToOptions: [],
      haveAttatchmentList: false,
      attatchmentList: [],

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const taskForm = ref({})
    return {
      getValidationState,
      taskForm,
    }
  },
  mounted() {
    this.getAssignedToList()
    this.showTask()
  },
  methods: {

    getAssignedToList() {
      axios.get('https://gulftic-system.fci.group/api/task/assign-list').then(res => {
        const allAssignedTo = decryptData(res.data.payload)?.data
        this.AssignedToOptions = allAssignedTo ? allAssignedTo.map(item => ({
          label: `${item.name} - ${item.email}`,
          value: {
            id: item.id,
            type: item.type,
          },
        })) : []
      })
    },

    deleteAttachment(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(`https://gulftic-system.fci.group/api/task/created/${this.$route.params.id}/delete-file/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.showTask()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    showTask() {
      this.loader = true
      if (this.$route.params.id) {
        axios.get(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`).then(res => {
          const getAllData = decryptData(res.data.payload)
          this.taskForm.issue = getAllData.data.issue
          this.taskForm.priority = getAllData.data.priority
          this.taskForm.due_date = getAllData.data.due_date
          this.taskForm.required_action = getAllData.data.required_action
          this.taskForm.description = getAllData.data.description
          this.taskForm.request_id = getAllData.data.request_id ? getAllData.data.request_id : ''
          if (getAllData.data.attachments.length > 0) {
            this.haveAttatchmentList = true
            this.attatchmentList = getAllData.data.attachments.map(item => ({
              id: item.id,
              url: item.url,
            }))
          }
          this.taskForm.assigned_to = getAllData.data.assigned.map(item => ({
            label: `${item.user.name} - ${item.user.email}`,
            value: {
              id: item.user_id,
              type: item.user_type,
            },
          }))
        })
      } else {
        this.loader = false
        return false
      }
      this.loader = false
      return true
    },
    addTask() {
      if (this.$route.params.id) {
        this.loader = true

        const formData = new FormData()
        formData.append('_method', 'PUT')
        Object.entries(this.taskForm).forEach(([key, value]) => {
          if (key === 'assigned_to' && Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(`assigned_to[${index}][id]`, item.value.id)
              formData.append(`assigned_to[${index}][type]`, item.value.type)
            })
          } else if (key === 'attachments' && Array.isArray(value)) {
            value.forEach((file, index) => {
              formData.append(`attachments[${index}]`, file)
            })
          } else {
            formData.append(key, value)
          }
        })
        axios
          .post(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'Task Management Created' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.loader = true

        const formData = new FormData()

        Object.entries(this.taskForm).forEach(([key, value]) => {
          if (key === 'assigned_to' && Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.append(`assigned_to[${index}][id]`, item.value.id)
              formData.append(`assigned_to[${index}][type]`, item.value.type)
            })
          } else if (key === 'attachments' && Array.isArray(value)) {
            value.forEach((file, index) => {
              formData.append(`attachments[${index}]`, file)
            })
          } else {
            formData.append(key, value)
          }
        })

        axios
          .post('https://gulftic-system.fci.group/api/task', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'Task Management Created' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },

  },

}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
