<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
    >
      <template #cell(show_details)="row">
        <div class="py-1">
          <b-button
            size="sm"
            class="mr-2"
            @click="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>

          <b-form-checkbox
            v-model="row.detailsShowing"
            style="display: none;"
            @change="row.toggleDetails"
          />
        </div>
      </template>

      <template #row-details="row">
        <b-row
          class="mb-2"
          style="width: 100%; height: 100%;"
        >
          <b-col
            sm="3"
            lg="12"
            class="d-flex align-items-center justify-content-end pt-1"
          >
            <b-button
              class="currency-conversion-div-button"
              variant="primary"
              @click="showAddCurrencyConversion(row.item.id , row.item.name)"
            >Add New Currency Conversion
            </b-button>
          </b-col>
          <b-col
            sm="3"
            lg="12"
            class="d-flex flex-wrap align-items-center justify-content-start py-2"
          >
            <div
              v-for="(item, index) in CurrencyConversionFilterData(row.item.id)"
              :key="index"
              class="currency-conversion-div"
            >
              <span class="px-1">Conversion Rate : </span>
              <b-badge
                pill
                variant="primary"
              >
                <span>{{ item.currency }}</span> =  <span>{{ item.conversion_rate }}</span> <span>{{ row.item.currency }}</span>
              </b-badge>
              <span class="px-1">
                <feather-icon
                  icon="EditIcon"
                  class="cursor-pointer"
                  @click="editDeleteCurrencyConversion(row.item.id , row.item.name, item.currency , item.conversion_rate , item.id )"
                />
              </span>
              <span>
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer"
                  @click="showDeleteCurrencyConversion(item.id, row.item.name )"
                />
              </span>
            </div>
          </b-col>
        </b-row>
      </template>
    </general-table>

    <delete-currency-conversion-modal
      v-if="deleteCurrencyConversionModal"
      :show-modal="deleteCurrencyConversionModal"
      :office-name="OfficeName"
      :office-id-delete="officeIdDelete"
      @refreshCurrencyConversions="getCurrencyConversion"
      @update:showModal="deleteCurrencyConversionModal = $event"
    />

    <add-currency-conversion-modal
      v-if="showCurrencyConversionModal"
      :show-modal="showCurrencyConversionModal"
      :office-name="OfficeName"
      :office-id="officeId"
      @refreshCurrencyConversions="getCurrencyConversion"
      @update:showModal="showCurrencyConversionModal = $event"
    />

    <edit-currency-conversion-modal
      v-if="editCurrencyConversionModal"
      :show-modal="editCurrencyConversionModal"
      :office-name="OfficeName"
      :office-id="officeId"
      :item-currency="itemCurrency"
      :item-conversion-rate="itemConversionRate"
      :item-id="itemId"
      @refreshCurrencyConversions="getCurrencyConversion"
      @update:showModal="editCurrencyConversionModal = $event"
    />

  </div>
</template>

<script>
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import decryptData from '@/store/auth/decryptData'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import DeleteCurrencyConversionModal from './control-office/DeleteCurrencyConversion.vue'
import AddCurrencyConversionModal from './control-office/AddCurrencyConversionModal.vue'
import EditCurrencyConversionModal from './control-office/EditCurrencyConversionModal.vue'

export default {
  components: {
    GeneralTable,
    DeleteCurrencyConversionModal,
    AddCurrencyConversionModal,
    EditCurrencyConversionModal,
  },
  data() {
    return {
      APIURL: 'office',
      addType: 'Add Office',
      addComponentName: 'add-office',
      editComponent: 'edit-office',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'country', label: 'Country' },
        { key: 'currency', label: 'Currency' },
        { key: 'iban', label: 'Iban' },
        { key: 'swift_code', label: 'Swift Code' },
        { key: 'bank_account_number', label: 'Bank Account Number' },
        { key: 'bank_account_name', label: 'Bank Account Name' },
        { key: 'check_name', label: 'Check Name' },
        { key: 'show_details', label: 'Currency Conversion' },
        { key: 'actions' },
      ],
      CurrencyConversionData: [],
      showCurrencyConversionModal: false,
      deleteCurrencyConversionModal: false,
      editCurrencyConversionModal: false,
      officeIdDelete: '',
      OfficeName: '',
      officeId: '',
      itemCurrency: '',
      itemConversionRate: '',
      itemId: '',
    }
  },

  computed: {
    apiUrl() {
      let base = 'office?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {
    this.getCurrencyConversion()
  },
  methods: {
    async  getCurrencyConversion() {
      const res = await axios.get('currency')
      this.CurrencyConversionData = decryptData(res.data.payload).data.data
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    CurrencyConversionFilterData(id) {
      return this.CurrencyConversionData.filter(item => item.office_id === id)
    },
    showAddCurrencyConversion(id, name) {
      this.showCurrencyConversionModal = true
      this.OfficeName = name
      this.officeId = id
    },
    showDeleteCurrencyConversion(id, name) {
      this.deleteCurrencyConversionModal = true
      this.OfficeName = name
      this.officeIdDelete = id
    },
    editDeleteCurrencyConversion(id, name, currency, conversionRate, itemId) {
      this.editCurrencyConversionModal = true
      this.OfficeName = name
      this.officeId = id
      this.itemCurrency = currency
      this.itemConversionRate = conversionRate
      this.itemId = itemId
    },
  },
}
</script>

<style lang="scss">
.currency-conversion-div{
  border: 1.5px solid grey;
  border-radius: 8px;
  display: flex;
  margin: 10px 10px;
  padding: 10px 10px ;
}
.currency-conversion-div:hover{
 background-color: #ebe9e9;
 color: black;
 border: 1.5px solid black !important;
}
</style>
