<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        <ReadFeedback />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <PersonnelEvaluation
          :personnel-evaluation-data="personnelEvaluationData"
          :managers-data="managersData"
          :peer-data="peerData"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="hasManagerScoreData"
      class="py-2 mb-2"
      style="border: 1px solid #ccc; border-radius: 15px;"
    >
      <b-col cols="12">
        <h3 class="text-primary text-underline">
          Manager Performance Evaluation :
        </h3>
      </b-col>
      <b-col
        v-if="managerScore.functional_skills_score"
        cols="4"
      >
        <h5>Functional Skills Score</h5>
        <b-progress
          height="20px"
          :max="5"
        >
          <b-progress-bar
            show-progress
            :value="managerScore.functional_skills_score"
            :label="`${(managerScore.functional_skills_score)}`"
            variant="secondary"
            :animated="true"
          />
        </b-progress>
      </b-col>
      <b-col
        v-if="managerScore.leadership_skills_score"
        cols="4"
      >
        <h5>Leadership Skills Score</h5>
        <b-progress
          height="20px"
          :max="5"
        >
          <b-progress-bar
            height="30px"
            :value="managerScore.leadership_skills_score"
            :label="`${(managerScore.leadership_skills_score)}`"
            variant="primary"
            :animated="true"
          />
        </b-progress>
      </b-col>
      <b-col
        v-if="managerScore.interpersonal_skills_score"
        cols="4"
      >
        <h5>Interpersonal Skills Score</h5>
        <b-progress
          height="20px"
          :max="5"
        >
          <b-progress-bar
            height="30px"
            :value="managerScore.interpersonal_skills_score"
            :label="`${(managerScore.interpersonal_skills_score)}`"
            variant="info"
            :animated="true"
          />
        </b-progress>
      </b-col>

      <b-col
        v-if="managerScore.comment"
        cols="12"
      >
        <label for="Comments">Manager Comment</label>
        <textarea
          id="manager-omments"
          v-model="managerScore.comment"
          class="form-control"
          readonly
        />
      </b-col>

    </b-row>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Name"
            label-for="Name"
          >
            <b-form-input
              id="from-user-name"
              v-model="userName"
              type="text"
              trim
              readonly
            />
          </b-form-group>

        </b-col>
        <b-col md="6">
          <b-form-group
            label="Email"
            label-for="Email"
          >
            <b-form-input
              id="from-user-email"
              v-model="userEmail"
              type="text"
              trim
              readonly
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          class="py-1"
        >
          <validation-provider
            #default="validationContext"
            name="Comments"
            rules="required"
          >
            <div class="form-floating">
              <label for="Comments">Comments</label>

              <textarea
                id="Comments"
                v-model="evaluationForm.hr_comment"
                :state="getValidationState(validationContext)"
                class="form-control"
                placeholder="Leave a comment here"
                :readonly="readonlyData"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </div>
          </validation-provider>
        </b-col>
      </b-row>
      <tableRateEvaluation
        ref="tableRateEvaluation"
        :get-old-rating-data="getOldRatingData"
        :readonly-data="readonlyData"
        @updateRatings="updateRatings"
        @updateValidationState="updateValidationState"
      />
      <b-row v-if="!readonlyData">
        <b-col md="6">
          <div class="form-check py-1">
            <input
              v-model="evaluationForm.status"
              class="form-check-input mt-0"
              type="radio"
              name="status"
              value="under hr review"
              style="width: 25px; height: 25px;"
            >
            <label
              class="form-check-label ml-1"
              style="font-size: 20px;"
            >
              Under HR Review
            </label>
          </div>
        </b-col>
        <b-col md="6"><div class="form-check py-1">
          <input
            v-model="evaluationForm.status"
            class="form-check-input mt-0"
            type="radio"
            name="status"
            value="done"
            style="width: 25px; height: 25px;"
          >
          <label
            class="form-check-label ml-1"
            style="font-size: 20px;"
          >
            Done
          </label>
        </div></b-col>
      </b-row>
      <b-row>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!readonlyData">
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="onSave"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'
import tableRateEvaluation from './tableRateEvaluation.vue'
import ReadFeedback from './ReadFeedback.vue'
import PersonnelEvaluation from './PersonnelEvaluation.vue'

export default {
  components: {
    tableRateEvaluation,
    ReadFeedback,
    PersonnelEvaluation,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      areAllRatingsFilled: false,
      required,
      loader: false,
      errors: {},
      ratingsData: {},
      userName: '',
      userEmail: '',
      managerScore: {
        functional_skills_score: null,
        leadership_skills_score: null,
        interpersonal_skills_score: null,
        comment: null,
      },
      managersData: null,
      peerData: null,
      personnelEvaluationData: {},
      getOldRatingData: [],
      readonlyData: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const evaluationForm = ref({
      status: 'under hr review',
    })
    return {
      getValidationState,
      evaluationForm,
    }
  },
  computed: {
    hasManagerScoreData() {
      return Object.values(this.managerScore).some(score => score !== null)
    },
  },
  mounted() {
    if (this.$route.params.show === 'show-info') {
      this.readonlyData = true
    } else {
      this.readonlyData = false
    }
    // console.log(this.readonlyData)
    this.showPerformanceEvaluation()
  },
  methods: {
    showPerformanceEvaluation() {
      if (this.$route.params.id) {
        axios.get(`https://gulftic-system.fci.group/api/performance-evaluation/${this.$route.params.id}`).then(res => {
          // this.evaluationForm = decryptData(res.data.payload).data.evaluation
          if (res.status === 200) {
            const evaluationData = decryptData(res.data.payload).data.evaluation
            // console.log(evaluationData)
            this.userName = evaluationData.user.name
            this.userEmail = evaluationData.user.email

            this.managersData = evaluationData.manager.name
            this.peerData = evaluationData.peer.name
            // console.log(evaluationData.manager)

            this.managerScore.functional_skills_score = evaluationData.functional_skills_score
            this.managerScore.leadership_skills_score = evaluationData.leadership_skills_score
            this.managerScore.interpersonal_skills_score = evaluationData.interpersonal_skills_score
            this.managerScore.comment = evaluationData.manager_comment

            this.evaluationForm.employee_id = evaluationData.user.id
            this.evaluationForm.status = evaluationData.status
            this.evaluationForm.hr_comment = evaluationData.hr_comment

            this.getOldRatingData = [
              [
                evaluationData.quality_of_work_q1,
                evaluationData.quality_of_work_q2,
                evaluationData.quality_of_work_q3,
              ],
              [
                evaluationData.work_habits_q1,
                evaluationData.work_habits_q2,
                evaluationData.work_habits_q3,
                evaluationData.work_habits_q4,
              ],
              [
                evaluationData.job_knowledge_q1,
                evaluationData.job_knowledge_q2,
                evaluationData.job_knowledge_q3,
              ],
              [
                evaluationData.interpersonal_skills_q1,
                evaluationData.interpersonal_skills_q2,
                evaluationData.interpersonal_skills_q3,
                evaluationData.interpersonal_skills_q4,
                evaluationData.interpersonal_skills_q5,
              ],
              [
                evaluationData.leadership_skills_q1,
                evaluationData.leadership_skills_q2,
                evaluationData.leadership_skills_q3,
              ],
              [
                evaluationData.hr_skills_q1,
                evaluationData.hr_skills_q2,
                evaluationData.hr_skills_q3,
                evaluationData.hr_skills_q4,
                evaluationData.hr_skills_q5,
              ],
            ]
            this.getPersonnelEvaluation(evaluationData.user.id)
          }
        })
      } else {
        return false
      }
      return true
    },

    getPersonnelEvaluation(id) {
      axios.get(`personnel-evaluation/${id}`).then(res => {
        if (res.status === 200) {
          // console.log(id)
          const evaluationData = decryptData(res.data.payload).data
          this.personnelEvaluationData = evaluationData
          // console.log(evaluationData)
        }
      })
    },
    updateValidationState({ areAllRatingsFilled }) {
      this.areAllRatingsFilled = areAllRatingsFilled
    },
    updateRatings(ratings) {
      this.ratingsData = ratings
    },

    onSave() {
      this.$refs.tableRateEvaluation.getAllRatings() // Trigger the child method
      this.editPerformanceEvaluation(this.ratingsData)
    },
    editPerformanceEvaluation(updatedRatings) {
      this.ratingsData = updatedRatings // Save the ratings data

      const getALlData = { ...this.ratingsData, ...this.evaluationForm }
      this.loader = true

      const formData = new FormData()
      formData.append('_method', 'PUT')

      Object.keys(getALlData).forEach(key => {
        formData.append(key, getALlData[key])
      })

      axios
        .post(`/performance-evaluation/${this.$route.params.id}`, formData)
        .then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'performance-evaluation' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
