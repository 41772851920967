<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row style="display: none;">
        <b-col md="6">
          <!-- Name Field -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="engineerStatusForm.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
                readonly
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Email Field -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="engineerStatusForm.email"
                type="email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
                readonly
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">

          <b-form-group
            label="Permission"
            label-for="Permission"
          >
            <b-form-checkbox-group
              id="status_id"
              v-model="engineerStatusForm.status_id"
              class="d-flex flex-column"
              :options="optionsStatus"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Allocation Sources"
            label-for="Allocation Sources"
          >
            <b-form-checkbox-group
              id="allocation_sources"
              v-model="engineerStatusForm.allocation_sources"
              class="d-flex flex-column"
              :options="optionsAllocationSources"
              multiple
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Errors Display -->
      <b-row v-if="Object.values(errors).length > 0">
        <b-col cols="12">
          <p
            v-for="(error, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error }}
          </p>
        </b-col>
      </b-row>

      <!-- Save Button -->
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loaderStatus"
            variant="primary"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserStatusSite"
          >
            Save Changes
          </b-button>
          <b-button
            v-else
            variant="primary"
            disabled
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      loader: false,
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
      loaderStatus: false,
      id: this.$store.state.generalIds.id,
      optionsStatus: [],
      AllDataCerStatus: {},
      optionsAllocationSources: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    const engineerStatusForm = ref({
      name: '',
      email: '',
      status_id: [],
      allocation_sources: [],
    })

    return {
      getValidationState,
      engineerStatusForm,
    }
  },

  mounted() {
    this.getEngineer()
    this.showEngineer()
  },

  methods: {
    getEngineer() {
      this.loadtable = true
      axios
        .get('engineer-status')
        .then(res => {
          // console.log(decryptData(res.data.payload))
          this.optionsStatus = decryptData(res.data.payload).data.status.map(item => ({
            value: item.id,
            text: item.status,
          }))
          this.optionsAllocationSources = [
            {
              value: 'saber',
              text: 'Saber',
            },
            {
              value: 'jeem1',
              text: 'Jeem 1',
            },
            {
              value: 'sfda',
              text: 'SFDA',
            },
            {
              value: 'moiat',
              text: 'Moiat',
            },
            {
              value: 'sls',
              text: 'SLS',
            },
            {
              value: 'qfda',
              text: 'QFDA',
            },
          ]
        })
        .finally(() => {
          this.loadtable = false
        })
    },
    async  showEngineer() {
      if (this.$route.params.id) {
        this.loader = true
        try {
          const response = await axios.get(`/register/site/user/${this.$route.params.id}`)
          if (response.status === 200) {
            const userData = decryptData(response.data.payload).data.user
            // console.log(decryptData(response.data.payload))
            this.engineerStatusForm.name = userData.name
            this.engineerStatusForm.email = userData.email
            this.engineerStatusForm.status_id = userData.status.map(item => item.id)
            this.engineerStatusForm.allocation_sources = userData.allocation_sources.map(item => item)
          }
        } catch (error) {
          this.loader = false
          if (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'can\'t get user information',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }
      }
    },

    addUserStatusSite() {
      if (this.$route.params.id) {
        this.loaderStatus = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        Object.keys(this.engineerStatusForm).forEach(key => {
          let value = this.engineerStatusForm[key]
          if (Array.isArray(value)) {
            value = value.filter(item => item !== null)
          }
          if (key === 'status_id' || key === 'allocation_sources') {
            if (value === null || (Array.isArray(value) && value.length === 0)) {
              formData.append(`${key}[]`, '') // Append empty array for the key
            } else if (Array.isArray(value)) {
              value.forEach(item => {
                formData.append(`${key}[]`, item)
              })
            }
          } else {
            formData.append(key, value)
          }
        })

        axios
          .post(`/engineer/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Updated Successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.showEngineer()
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loaderStatus = false
          })
      }
    },
  },
}
</script>
