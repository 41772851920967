var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('h1',{staticClass:"text-center py-2 text-underline"},[_vm._v(" Generate Report ")]),_c('b-row',[_c('b-col',{staticClass:"py-2 text-center",attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],staticClass:"py-1 px-3",style:({ background: 'linear-gradient(to right, #b3661c , #ed9339)', border: 'none', color: 'white' })},[_vm._v(" Generate Report for Jeem1 ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-1"}},[_c('b-row',{staticClass:"py-1",attrs:{"align-h":"around"}},[_c('b-button',{staticStyle:{"background-color":"#b3661c !important","border":"none"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'jeem-IECEE'},
                })}}},[_vm._v(" IECEE ")]),_c('b-button',{staticStyle:{"background-color":"#d57922 !important","border":"none"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'jeem-quality'},
                })}}},[_vm._v(" Quality ")]),_c('b-button',{staticStyle:{"background-color":"#f78c27 !important","border":"none"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'jeem-conformity'},
                })}}},[_vm._v(" Conformity ")])],1)],1)],1),_c('b-col',{staticClass:"py-2 text-center",attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-2",modifiers:{"collapse-2":true}}],staticClass:"py-1 px-3",style:({ background: 'linear-gradient(to right, #6e1fd4 , #0b0315)', border: 'none', color: 'white' })},[_vm._v(" Generate SFDA Report ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-2"}},[_c('b-row',{staticClass:"py-1",attrs:{"align-h":"around"}},[_c('b-button',{staticStyle:{"background-color":"#6e1fd4 !important","border":"none"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'sfda'},
                })}}},[_vm._v(" SFDA ")])],1)],1)],1),_c('b-col',{staticClass:"py-2 text-center",attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-3",modifiers:{"collapse-3":true}}],staticClass:"py-1 px-3",style:({ background: 'linear-gradient(to right, #ff0000 , #7f0000)', border: 'none', color: 'white' })},[_vm._v(" Generate Moiat Report ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-3"}},[_c('b-row',{staticClass:"py-1",attrs:{"align-h":"around"}})],1)],1),_c('b-col',{staticClass:"py-2 text-center",attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-4",modifiers:{"collapse-4":true}}],staticClass:"py-1 px-3",style:({ background: 'linear-gradient(to right, #33c9d4 , #2bb1ba)', border: 'none', color: 'white' })},[_vm._v(" Generate SLS Report ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-4"}},[_c('b-row',{staticClass:"py-1",attrs:{"align-h":"around"}},[_c('b-button',{staticStyle:{"background-color":"#2bb1ba !important","border":"none"},attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'sls'},
                })}}},[_vm._v(" Sls ")])],1)],1)],1),_c('b-col',{staticClass:"py-2 text-center",attrs:{"cols":"6"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-5",modifiers:{"collapse-5":true}}],staticClass:"py-1 px-3",style:({ background: 'linear-gradient(to right, #8c5a78 , #401b35)', border: 'none', color: 'white' })},[_vm._v(" Generate Saber Report ")]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-5"}},[_c('b-row',{staticClass:"py-1",attrs:{"align-h":"around"}},[_c('b-button',{style:({background: 'linear-gradient(to right, #734864 , #734864)', border: 'none'}),attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'saber'},
                })}}},[_vm._v(" Saber ")]),_c('b-button',{style:({background: 'linear-gradient(to right, #8c5a78 , #8c5a78)', border: 'none'}),attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
                  name: 'add-auto-generate-report',
                  params: { name : 'saber-shipment'},
                })}}},[_vm._v(" Saber Shipment ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }