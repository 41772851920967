<template>
  <div>
    <general-table
      ref="generalTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
    >
      <!-- Custom Header Template -->
      <template #thead-top>
        <tr>
          <th rowspan="2" />
          <th rowspan="2" />
          <th
            colspan="2"
            class="text-center"
          >
            Holiday Balance
          </th>
          <th rowspan="2" />
          <th rowspan="2" />
          <th rowspan="2" />
          <th rowspan="2" />
          <th rowspan="2" />
          <th rowspan="2" />
          <th rowspan="2" />
        </tr>
        <tr />
      </template>

      <!-- Status Cell Customization -->
      <template #cell(type)="data">
        <span
          :style="`color: ${data.item.type === 'rejected' ? 'red' : 'green'}`"
        >
          {{ data.item.type }}
        </span>
      </template>
      <!-- Schedule Approval Cell Customization -->
      <template #cell(schedule_approval)="data">
        <div
          v-if="data.item.type === 'pending'"
          style="padding: 3px 0px;"
        >
          <span
            class="cursor-pointer"
            @click="approvalSchedule(data.item.id)"
          >
            <img
              src="@/assets/images/icons/validity.png"
              width="35"
              height="35"
              class="text-center"
            >
          </span>
        </div>
      </template>
      <!-- Status Cell current_holiday_balance -->
      <template #cell(current_holiday_balance)="data">
        <span
          v-b-tooltip.hover
          class="cursor-pointer"
          title="Update Current Holiday Balance"
          @click="editHolidayBalance(data.item.user.id , data.item.user.current_holiday_balance , 'current')"
        >
          {{ data.item.user.current_holiday_balance , }}
        </span>
      </template>
      <!-- Status Cell default_holiday_balance -->
      <template #cell(default_holiday_balance)="data">
        <span
          v-b-tooltip.hover
          class="cursor-pointer"
          title="Update Default Holiday Balance"
          @click="editHolidayBalance(data.item.user.id , data.item.user.default_holiday_balance , 'default')"
        >
          {{ data.item.user.default_holiday_balance }}
        </span>
      </template>

    </general-table>
  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'employee-schedule?pending_rejected=true',
      addType: 'Add Schedule',
      addComponentName: 'add-schedule',
      editComponent: 'edit-schedule',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'user.name', label: 'Name' },
        { key: 'current_holiday_balance', label: 'Current' },
        { key: 'default_holiday_balance', label: 'Default' },
        { key: 'description', label: 'Description' },
        { key: 'from', label: 'From' },
        { key: 'to', label: 'To' },
        { key: 'status', label: 'Type' },
        { key: 'type', label: 'Status' },
        { key: 'schedule_approval', label: 'Need Approval' },
        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'employee-schedule?pending_rejected=true?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      return base.slice(0, -1)
    },
  },

  methods: {
    async callFunction() {
      // Access the GeneralTable component using the ref and call getAllData
      if (this.$refs.generalTable) {
        await this.$refs.generalTable.getAllData(1) // Pass page number as needed
      }
    },
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
    editHolidayBalance(id, balanceNumber, balanceType) {
      this.$swal({
        title: `Update ${balanceType} holiday balance !!`,
        // text: `You won't be able to Approval this schedule! , ${id} , ${balance_number} , `,
        icon: 'question',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off',
        },
        inputValue: balanceNumber,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
        preConfirm: async login => {
          try {
            let balanceData = {}
            if (balanceType === 'current') {
              balanceData = {
                current_holiday_balance: login,
              }
            } else {
              balanceData = {
                default_holiday_balance: login,
              }
            }

            const githubUrl = `user/${id}/holiday-balance`
            const response = await axios.put(githubUrl, balanceData)

            if (response.status === 200) {
              this.$swal('Updated!', 'Update Successfully.', 'success')
              this.callFunction()
            }
          } catch (error) {
            this.swal.showValidationMessage(`
              Request failed: ${error}
            `)
          }
        },
      })
    },

    approvalSchedule(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to Approval this schedule!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approval',
        cancelButtonText: 'Disapproval',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(
              `https://gulftic-system.fci.group/api/admin/employee-schedule/${id}/approve`,
            )
            .then(() => {
              this.$swal('Approved!', 'Approve Successfully.', 'success')
              this.callFunction()
            })
            .finally(() => {
              this.loading = false
            })
        } else if (result.dismiss === 'cancel') {
          axios
            .put(
              `https://gulftic-system.fci.group/api/admin/employee-schedule/${id}/reject`,
            )
            .then(() => {
              this.$swal('Disapproved!', 'Disapprove Successfully.', 'danger')
              this.callFunction()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

  },
}
</script>

<style>
.text-center {
  text-align: center;
}
</style>
