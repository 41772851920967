<template>
  <b-card>
    <div>
      <b-table-simple
        hover
        small
        caption-top
        stacked
      >
        <caption>Personnel Evaluation</caption>
        <colgroup><col><col></colgroup>
        <colgroup><col><col><col></colgroup>
        <colgroup><col><col></colgroup>
        <b-tbody>
          <b-tr>
            <b-th
              rowspan="3"
              class="text-center"
              variant="info"
            >
              Personnel Evaluation Form
            </b-th>
            <b-th
              stacked-heading="Employee Name"
              class="text-left"
            >
              <!-- {{ evaluationForm?.employee_user?.name }} -->
              {{ evaluationForm && evaluationForm.employee_user && evaluationForm.employee_user.name }}
            </b-th>
            <!-- <b-th
              stacked-heading="External/Others Name"
              class="text-left"
            /> -->
            <b-th
              stacked-heading="Date Of Joining"
              class="text-left"
            >
              {{ evaluationForm && evaluationForm.employee_user && evaluationForm.employee_user.joined_at }}
            </b-th>
            <b-th
              v-if="evaluationForm.employee_user.office"
              stacked-heading="Branch"
              class="text-left"
            >
              {{ evaluationForm && evaluationForm.employee_user && evaluationForm.employee_user.office.name }}
            </b-th>
            <!-- <b-th
              stacked-heading="Applicable Product Group"
              class="text-left"
            /> -->
            <b-th
              stacked-heading="CE Level Before Evaluation"
              class="text-left"
            >
              <ul class="px-4">
                <li
                  v-for="(item, index) in evaluationForm.ce_level_before"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </b-th>
            <b-th
              stacked-heading="Application Number"
              class="text-left"
            >
              {{ evaluationForm && evaluationForm.request && evaluationForm.request.request_code }}
            </b-th>
            <!-- <b-th
              stacked-heading="Applicable Standards"
              class="text-left"
            /> -->
            <!-- <b-th
              stacked-heading="Scheme Owner"
              class="text-left"
            /> -->
            <b-th
              stacked-heading="Activities"
              class="text-left"
            >
              {{ evaluationForm.activities }}
            </b-th>
          </b-tr>

          <b-tr>
            <b-th
              rowspan="3"
              class="text-center"
              variant="warning"
            >
              Competence
            </b-th>
            <b-td stacked-heading="Completed training related to proudct evaluated (specify the product standard)">
              {{ evaluationForm.completed_training }}  ---- {{ evaluationForm.completed_training_remark }}
            </b-td>
            <b-td
              stacked-heading="With Relevant Educational Background"
            >
              {{ evaluationForm.educational_background }}  ---- {{ evaluationForm.educational_background_remark }}
            </b-td>
            <b-td stacked-heading="At least 2 years work experience related to the Job performed">
              {{ evaluationForm.two_year_job_experience }}  ---- {{ evaluationForm.two_year_job_experience_remark }}

            </b-td>
            <b-td stacked-heading="Knowledge of applicable technical regulation">
              {{ evaluationForm.knowledge_of_tr }}  ---- {{ evaluationForm.knowledge_of_tr_remark }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th
              rowspan="3"
              class="text-center"
              variant="dark"
            >
              Demonstrable relevant job skills
            </b-th>
            <b-td stacked-heading="Correctly interprets the requirements of regulation and standard">
              {{ evaluationForm.correctly_interprets }}  ---- {{ evaluationForm.correctly_interprets_remark }}
            </b-td>
            <b-td stacked-heading="Can conduct evaluation independently">
              {{ evaluationForm.evaluation_independently }}  ---- {{ evaluationForm.evaluation_independently_remark }}

            </b-td>
            <b-td stacked-heading="Certification activities are done according to technical requirement">
              {{ evaluationForm.certification_activities }}  ---- {{ evaluationForm.certification_activities_remark }}
            </b-td>
            <b-td stacked-heading="Output is delivered within the specified time">
              {{ evaluationForm.output_is_delivered }}  ---- {{ evaluationForm.output_is_delivered_remark }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th
              rowspan="3"
              class="text-center"
              variant="success"
            >
              Quality Of Output
            </b-th>
            <b-td
              stacked-heading="Used correct forms"
            >
              {{ evaluationForm.used_correct_forms }}  ---- {{ evaluationForm.used_correct_forms_remark }}
            </b-td>
            <b-td stacked-heading="Followed applicable Gulftic procedures">
              {{ evaluationForm.followed_procedure }}  ---- {{ evaluationForm.followed_procedure_remark }}
            </b-td>
            <b-td stacked-heading="Issued certificate with complete and correct details">
              {{ evaluationForm.correct_cert_details }}  ---- {{ evaluationForm.correct_cert_details_remark }}
            </b-td>
            <b-td stacked-heading="Professional conduct according to Gulftic Policies (impartiality , confidentiality , non-discriminatory , key values )">
              {{ evaluationForm.gulftic_policies }}  ---- {{ evaluationForm.gulftic_policies_remark }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th
              rowspan="3"
              class="text-center"
              variant="danger"
            >
              Conclusion
            </b-th>
            <b-td stacked-heading="Conclusion">
              {{ evaluationForm.conclusion }}
            </b-td>
            <b-td
              stacked-heading="EC Level After Evaluation"
            >
              <ul class="px-4">
                <li
                  v-for="(item, index) in evaluationForm.ce_level_after"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </b-td>
            <b-td
              v-if="evaluationForm.evaluator_user"
              stacked-heading="Evaluator Name"
            >
              {{ evaluationForm && evaluationForm.employee_user && evaluationForm.evaluator_user.name }}
            </b-td>
            <b-td stacked-heading="Evaluation Date ">
              {{ getNewDate(evaluationForm.created_at) }}
            </b-td>
            <b-td stacked-heading="Next Evaluation Date ">
              {{ getNewDate(evaluationForm.next_evaluation_date) }}
            </b-td>
            <b-td
              stacked-heading="Remark"
            >
              {{ evaluationForm.remarks }}
            </b-td>

          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-card>
</template>

<script>
import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'
import { convertDate } from '@/libs/getDate'

export default {
  data() {
    return {
      loader: false,
      evaluationForm: [],
    }
  },
  mounted() {
    this.showPerformanceEvaluation()
  },
  methods: {
    showPerformanceEvaluation() {
      if (this.$route.params.id) {
        axios.get(`https://gulftic-system.fci.group/api/personnel-evaluation/${this.$route.params.id}`).then(res => {
          if (res.status === 200) {
            this.evaluationForm = decryptData(res.data.payload).data
            // console.log(this.evaluationForm)
          }
        })
      } else {
        return false
      }
      return true
    },
    getNewDate(date) {
      return convertDate(date)
    },
  },
}
</script>
