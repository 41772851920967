<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <!-- Dynamic Sub-Certificates based on selected Certificate Groups -->
      <b-row>
        <!-- Certificates Field -->
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Certificates"
            rules="required"
          >
            <b-form-group
              label="Certificates"
              label-for="certificates"
            >
              <b-form-checkbox-group
                id="certificates_id"
                v-model="engineerForm.certificates"
                class="d-flex flex-column"
                :options="optionsStatusId"
                :state="getValidationState(validationContext)"
                multiple
              />
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-for="(group, index) in selectedCertificateGroups"
          :key="index"
          md="6"
        >
          <validation-provider
            #default="validationContext"
            :name="`subCertificates${index}`"
            rules="required"
          >
            <b-form-group
              :label="`Sub Certificates for ${group.text}`"
              :label-for="`sub_certificates_${index}`"
            >
              <b-form-checkbox-group
                :id="`sub_certificates_${index}`"
                v-model="engineerForm.subCertificates[group.value]"
                class="d-flex flex-column"
                :state="getValidationState(validationContext)"
                multiple
              >
                <div
                  v-for="certificate in group.certificates"
                  :key="certificate.value"
                  class="d-flex align-items-start flex-column"
                >
                  <b-form-checkbox :value="certificate.value">
                    {{ certificate.text }}
                  </b-form-checkbox>
                  <span
                    v-if="certificate.span"
                    style="color: #3c9ade; margin-left: 8px;"
                  >Status: [{{ certificate.span }}]</span>
                </div>
              </b-form-checkbox-group>
            </b-form-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Errors Display -->
      <b-row v-if="Object.values(errors).length > 0">
        <b-col cols="12">
          <p
            v-for="(error, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error }}
          </p>
        </b-col>
      </b-row>

      <!-- Save Button -->
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserSite"
          >
            Save Changes
          </b-button>
          <b-button
            v-else
            variant="primary"
            disabled
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      loader: false,
      errors: {},
      optionsStatusId: [],
      allCertificates: [],
      loaderStatus: false,
      id: this.$store.state.generalIds.id,
      optionsStatus: [],
      AllDataCerStatus: {},
      optionsAllocationSources: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    const engineerForm = ref({
      name: '',
      email: '',
      certificates: [],
      subCertificates: {},
    })
    return {
      getValidationState,
      engineerForm,
    }
  },

  computed: {
    selectedCertificateGroups() {
      return this.engineerForm.certificates
        .map(cert => {
          const group = this.allCertificates.find(g => g.value === cert)
          if (group) {
            return {
              ...group,
              certificates: group.certificates.map(certif => ({
                value: certif.id,
                text: `${certif.name.ar} - ${certif.name.en}`,
                span: `${this.AllDataCerStatus[certif.id]?.join(' - ') ?? ''}`,
              })),
            }
          }
          return null
        })
        .filter(group => group !== null)
    },
  },

  mounted() {
    this.getEngineerStatus()
    this.showCertificates()
  },

  methods: {
    showCertificates() {
      if (this.$route.params.id) {
        axios.get(`/engineer/${this.$route.params.id}`).then(res => {
          const userData = decryptData(res.data.payload).data.engineer.certificates_groups
          this.engineerForm.certificates = userData.map(item => item.name.ar)
          userData.forEach(item => {
            const subCerts = item.certificates.map(cert => cert.id)
            const statuses = item.certificates.map(cert => ({
              id: cert.id,
              statuses: cert.statuses.map(status => status.status.status),
            }))
            statuses.forEach(status => {
              this.AllDataCerStatus[status.id] = status.statuses
            })
            this.$set(this.engineerForm.subCertificates, item.name.ar, subCerts)
          })
        })
      }
    },
    getEngineerStatus() {
      this.loader = true
      axios
        .get('certificate')
        .then(res => {
          this.allCertificates = decryptData(res.data.payload).data.groups.map(item => ({
            value: item.name.ar,
            text: `${item.name.ar} - ${item.name.en}`,
            certificates: item.certificates,
          }))
          this.optionsStatusId = this.allCertificates.map(item => ({
            value: item.value,
            text: item.text,
          }))
        })
        .finally(() => {
          this.loader = false
        })
    },
    addUserSite() {
      this.loader = true
      const formData = new FormData()

      Object.keys(this.engineerForm.subCertificates).forEach(key => {
        this.engineerForm.subCertificates[key].forEach(subCert => {
          formData.append('certificates[]', subCert)
        })
      })

      axios
        .post(`/engineer/${this.$route.params.id}/certificate`, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
        })
    },

  },
}
</script>
