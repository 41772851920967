<template>
  <div>
    <general-table
      :api-url="APIURL"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :columns="columns"
      :type="type"
      :per-page="perPage"
      :add-component-name="addComponentName"
      :add-type="addType"
    >
      <template #cell(engineers)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-50 cursor-pointer"
              @click="ApprovalRow(data.item.engineers)"
            />
          </div>
        </slot>
      </template>
      <template #cell(components)="data">
        <slot
          name="info"
          v-bind="data"
        >
          <div class="text-center">
            <span title="View">
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'components',
                    params: { CM_id: data.item.id, Components_Name: data.item.name.en }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(copy_certificate_components)="data">
        <slot
          name="info"
          v-bind="data"
        >
          <div class="text-center">
            <span title="Copy">
              <feather-icon
                icon="CopyIcon"
                class="cursor-pointer"
                @click="toggleModal(data.item.id)"
              />
              <b-modal
                :id="`modal-${data.item.id}`"
                :visible="modalShow[data.item.id] || false"
                centered
                size="lg"
                :title="`Copy Certificate Components`"
                ok-only
                ok-variant="dark"
                ok-title="Close"
                @hide="closeModal(data.item.id)"
              >
                <h3 class="my-4">provide the ID of the certificate the component should be added</h3>
                <div
                  v-if="loader"
                  class="d-flex align-items-center justify-content-center"
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem;"
                    label="Large Spinner"
                    variant="primary"
                  />
                </div>
                <div v-else>
                  <b-form-tags
                    v-model="copyArrayComponents"
                    no-outer-focus
                    class="mb-2"
                    type="number"
                  >
                    <!-- <template #invalid-tag="{ tag }">
                    </template> -->
                    <template v-slot="{ inputAttrs, inputHandlers, addTag, removeTag }">
                      <!-- <span class="text-danger">The tag "{{ tag }}" is invalid. Only numbers are allowed.</span> -->
                      <b-input-group aria-controls="my-custom-tags-list">
                        <input
                          v-bind="inputAttrs"
                          type="number"
                          placeholder="New tag - Press enter to add"
                          class="form-control"
                          v-on="inputHandlers"
                        >
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            @click="addTag()"
                          >Add</b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <ul
                        id="my-custom-tags-list"
                        class="list-unstyled d-inline-flex flex-wrap mb-0"
                        aria-live="polite"
                        aria-atomic="false"
                        aria-relevant="additions removals"
                      >
                        <b-card
                          v-for="tag in copyArrayComponents"
                          :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                          :key="tag"
                          tag="li"
                          class="mt-1 mr-1"
                          body-class="py-1 pr-2 text-nowrap"
                        >
                          <strong>{{ tag }}</strong>
                          <b-button
                            variant="link"
                            size="sm"
                            :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                            @click="removeTag(tag)"
                          >remove</b-button>
                        </b-card>
                      </ul>
                    </template>
                  </b-form-tags>
                </div>
                <b-alert
                  v-model="showDismissibleAlert"
                  variant="danger"
                >
                  {{ errorMes }}
                </b-alert>
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    @click="CopyComponentsAPi(data.item.id)"
                  >Copy Components {{ data.item.id }}</b-button>
                </div>
              </b-modal>
            </span>
          </div>
        </slot>
      </template>

    </general-table>

  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: `certificate/${this.$route.params.MCR_id}`,
      addType: 'Add Certificate',
      addComponentName: 'add-certificate',
      errorMes: '',
      editComponent: 'edit-certificate',
      viewContent: true,
      type: 'page',
      perPage: 50,
      modalShow: {},
      loader: false,
      copyArrayComponents: [],
      showDismissibleAlert: false,
      columns: [
        { key: 'id', sortable: false },
        { key: 'name.ar', label: 'Certificate Name Ar' },
        { key: 'name.en', label: 'Certificate Name En' },
        { key: 'description.ar', label: 'Description Certificate Ar' },
        { key: 'description.en', label: 'Description Certificate En' },
        { key: 'copy_certificate_components', label: 'Copy Certificate Components' },
        { key: 'components', label: 'show components' },
        { key: 'engineers', label: 'Engineers' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    formatEngineerNames(names) {
      return `
         <table style="border: 0.5px solid; border-collapse: collapse; margin-top: 18px!important; text-align: center; margin: auto;">
          <tr style="background-color: #f3f2f7">
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">#</th>
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">Engineer Names</th>
          </tr>
          ${names
    .split(', ')
    .map(
      (name, index) => `
            <tr>
              <td style="border: 1px solid; padding: 8px;">${index + 1}</td>
              <td style="border: 1px solid; padding: 8px;">${name}</td>
            </tr>`,
    )
    .join('')}
        </table>
      `
    },
    CopyComponentsAPi(id) {
      this.loader = true
      // console.log(this.certificateData)
      const formData = new FormData()

      formData.append('src_certificate_id', id)
      this.copyArrayComponents.forEach((item, index) => {
        formData.append(`dst_certificate_ids[${index}]`, item) // Append the value of each item
      })

      axios
        .post(
          'certificate/copy-components',
          formData,
        )
        .then(res => {
          if (res.status === 200) {
            this.closeModal(id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Copy Successfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.showDismissibleAlert = true
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.errorMes = errorMessage
          }
        })
        .finally(() => {
          this.loader = false
        })
    },
    ApprovalRow(name) {
      this.$swal({
        title: 'Engineer List',
        html: this.formatEngineerNames(name),
        // text: this.formatEngineerNames(name),
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Close',
      })
    },
    toggleModal(id) {
      // Toggle the modal visibility for the given ID
      this.$set(this.modalShow, id, !this.modalShow[id])
    },
    closeModal(id) {
      // Close the modal when it's hidden
      this.$set(this.modalShow, id, false)
      this.copyArrayComponents = []
      this.errorMes = ''
      this.showDismissibleAlert = false
    },
  },
}
</script>

<style></style>
