<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="sourceAccountForm.name"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="sourceAccountForm.email"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <div class="d-flex align-items-center">
                <b-form-input
                  id="password"
                  v-model="sourceAccountForm.password"
                  :type="`${showPassword ? 'text' : 'password'}`"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Password"
                  style="width: 95%;"
                />
                <b-input-group-prepend class="d-flex align-items-center cursor-pointer">
                  <transition name="toggle-icon">
                    <span
                      v-if="showPassword"
                      key="show"
                      class="password-icon"
                      @click="showPassword = !showPassword"
                    >🤫</span>
                  </transition>
                  <transition name="toggle-icon">
                    <span
                      v-if="!showPassword"
                      key="hide"
                      class="password-icon"
                      @click="showPassword = !showPassword"
                    >🫣</span>
                  </transition>
                </b-input-group-prepend>
              </div>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Region"
            rules="region"
          >
            <b-form-group
              label="Region"
              label-for="region"
            >
              <b-form-input
                id="region"
                v-model="sourceAccountForm.region"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Region"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addSourceAccount()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addSourceAccount()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

// eslint-disable-next-line no-unused-vars

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      validationErrors: {},
      errors: {},
      showPassword: false,
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const sourceAccountForm = ref({})
    return {
      getValidationState,
      sourceAccountForm,
    }
  },
  mounted() {
    this.showSourceAccount()
  },
  methods: {

    showSourceAccount() {
      this.loader = true
      if (this.$route.params.id) {
        axios.get(`source-account/${this.$route.params.id}`).then(res => {
          this.sourceAccountForm = decryptData(res.data.payload).data.source_account
        })
      } else {
        this.loader = false
        return false
      }
      this.loader = false
      return true
    },

    addSourceAccount() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.sourceAccountForm) {
          formData.append(key, this.sourceAccountForm[key])
        }

        axios.post(`source-account/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'source-account' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.sourceAccountForm) {
          formData.append(key, this.sourceAccountForm[key])
        }

        axios.post('source-account', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'source-account' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .toggle-icon-enter-active,
.toggle-icon-leave-active {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.toggle-icon-enter {
  transform: translateY(100%);
  opacity: 0;
}

.toggle-icon-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
.password-icon {
  font-size: 38px;
  display: inline-block;
  position: absolute;
}
  </style>
