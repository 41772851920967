<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <b-col
      class="content-header-left mb-2"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div
            class="breadcrumb-wrapper "
            style="width: 100% !important;"
          >
            <b-breadcrumb>
              <b-breadcrumb-item to="/dashboard">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in computedBreadcrumbs"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >

                <span
                  v-if="item.fullText"
                  v-b-popover.hover="{ variant: 'primary', content: `${item.fullName}` }"
                  :title="item.fullText"
                >
                  {{ item.text }}
                </span>
                <span
                  v-else
                >
                  {{ item.text }}
                </span>

              </b-breadcrumb-item>

              <b-breadcrumb-item
                v-if="this.$route.params.name"
                :active="true"
              >
                {{ this.$route.params.name }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: {
    computedBreadcrumbs() {
      return (this.$route.meta.breadcrumb || []).map(item => {
        // Replace placeholders in `item.to` with route parameters
        if (item.to && item.to.includes(':')) {
          let path = item.to
          Object.keys(this.$route.params).forEach(key => {
            path = path.replace(`:${key}`, this.$route.params[key])
          })

          let nameUsed = 'N/A'

          if (item.text === 'Certificate') {
            nameUsed = this.$route.params.Certificate_Name || 'N/A'
          } else if (item.text === 'Components') {
            nameUsed = this.$route.params.Components_Name || 'N/A'
          } else if (item.text === 'Sections') {
            nameUsed = this.$route.params.Section_Name || 'N/A'
          } else if (item.text === 'Requirements') {
            nameUsed = this.$route.params.Requirements_Name || 'N/A'
          }

          const truncatedName = nameUsed.split(' ').length > 3
            ? `${nameUsed.split(' ').slice(0, 2).join(' ')}...`
            : nameUsed

          const textWithId = `${item.text} *${truncatedName}*`

          return {

            ...item, to: path, text: textWithId, fullText: `${item.text}`, fullName: `${nameUsed}`,
          }
        }
        return item
      })
    },
  },
}
</script>
