export default [
  {
    path: '/performance-evaluation',
    name: 'performance-evaluation',
    component: () => import('@/views/performance-evaluation/PerformanceEvaluation.vue'),
    meta: {
      title: 'Performance Evaluation ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Performance Evaluation',
      pageTitle: 'Performance Evaluation',
      breadcrumb: [
        {
          text: 'Performance Evaluation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/performance-evaluation-done',
    name: 'performance-evaluation-done',
    component: () => import('@/views/performance-evaluation/PerformanceEvaluationDone.vue'),
    meta: {
      title: 'Performance Evaluation ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Performance Evaluation',
      pageTitle: 'Performance Evaluation',
      breadcrumb: [
        {
          text: 'Performance Evaluation',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },

  {
    path: '/performance-evaluation/edit/:id',
    name: 'edit-performance-evaluation',
    component: () => import(
      '@/views/performance-evaluation/control-performance-evaluation/AddEditPerformanceEvaluation.vue'
    ),
    meta: {
      title: 'Edit Performance Evaluation ',
      requiresAuth: false,
      pageTitle: 'Performance Evaluation',
      breadcrumb: [
        {
          text: 'edit performance evaluation',
          to: '/performance-evaluation',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/performance-evaluation/:show/:id',
    name: 'show-performance-evaluation',
    component: () => import(
      '@/views/performance-evaluation/control-performance-evaluation/AddEditPerformanceEvaluation.vue'
    ),
    meta: {
      title: 'Show Performance Evaluation ',
      requiresAuth: false,
      pageTitle: 'Performance Evaluation',
      breadcrumb: [
        {
          text: 'show performance evaluation',
          to: '/performance-evaluation',
        },
        {
          text: 'Show',
          active: true,
        },
      ],
    },
  },
]
