export default [
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/Support.vue'),
    meta: {
      title: 'Support Chat',
      requiresAuth: false,
      action: 'browes',
      resource: 'Support Chat',
      pageTitle: 'Support Chat',
      breadcrumb: [
        {
          text: 'Support Chat',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
