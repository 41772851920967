<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(actions_evaluations)="data">
        <span>
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer"
            @click="
              $router.push({
                name: 'edit-performance-evaluation',
                params: { id: data.item.id }
              })
            "
          />
        </span>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'performance-evaluation/under-review',
      viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        // { key: 'status', label: 'status' },
        { key: 'from', label: 'from' },
        { key: 'to', label: 'to' },
        { key: 'user.name', label: 'user name' },
        { key: 'actions_evaluations', label: 'actions' },
      ],
    }
  },
  computed: {
    apiUrl() {
      let base = 'performance-evaluation/under-review?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  mounted() {},

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style>
