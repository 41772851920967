export const convertDate = dateString => {
  // Parse the date string into a Date object
  const date = new Date(dateString)

  // Get the year, month, and day from the Date object
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based, so add 1
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0')

  // Format the date as yyyy/mm/dd
  return `${year}/${month}/${day}`
}

export const convertDateWithTime = timestampString => {
  try {
    if (!timestampString) {
      throw new Error(
        'Invalid date format: timestampString is undefined or null',
      )
    }

    let date

    // Check if the timestamp is in ISO format (with 'T' and possibly with milliseconds)
    if (timestampString.includes('T') && timestampString.includes('Z')) {
      // Directly parse ISO string with 'Z' as UTC
      date = new Date(timestampString)
    } else {
      // Handle the format without 'T' and 'Z' (e.g., "2024-08-29 08:35:29")
      // Append 'T' and 'Z' to force UTC interpretation
      const utcTimestamp = `${timestampString.replace(' ', 'T')}Z`
      date = new Date(utcTimestamp)
    }

    // Detect the user's current timezone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Convert the date to the user's local time in the detected timezone
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // Use 24-hour format
      timeZone: userTimeZone,
    }

    // Format the date in the local time of the user
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
      date,
    )

    return formattedDate
  } catch (error) {
    /* eslint-disable-next-line */
    console.error("Error in convertDateWithTime:", error.message);
    return 'Invalid Date' // Fallback for invalid dates
  }
}
