<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :add-type="addType"
      :columns="filteredColumns"
      :type="type"
    >
      <template #cell(auto_proceed)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div
            v-b-popover.hover.topright="'+ add auto proceed'"
            style="padding: 2px 0px;"
            class="hover-pointer text-center"
          >
            <span
              v-if="data.item.auto_proceed === 1"
              @click="AddAutoProceed(data.item.id)"
            >
              <feather-icon
                icon="CheckIcon"
                size="25"
                color="success"
              />
            </span>
            <span
              v-else
              @click="AddAutoProceed(data.item.id)"
            >
              <feather-icon
                icon="XIcon"
                size="20"
                color="red"
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(offer)="data">
        <div
          v-if="data.item.offer === null"
          v-b-popover.hover.topright="'+ add offer'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'add-offer-delegator',
              params: { id: data.item.id }
            })
          "
        />
        <slot
          name="show"
          v-bind="data"
        >
          <div
            v-b-popover.hover.topright="'+ add offer'"
            class="hover-pointer text-center"
            @click="
              $router.push({
                name: 'add-offer-delegator',
                params: { id: data.item.id }
              })
            "
          >
            <span
              v-if="data.item.offer"
              class="text-nowrap"
            >
              {{ data.item.offer.name }} - {{ data.item.offer.amount }} %
            </span>
          </div>
        </slot>
      </template>
      <template #cell(view_details)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <span
              v-if="viewContent"
              title="View"
            >
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'show-delegator',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
      <template #cell(wallet_balance)="data">
        <div
          v-if="data.item.wallet_balance === null"
          v-b-popover.hover.topright="'+ add wallet balance'"
          style="width: 100% !important; height: 24px !important"
          class="hover-pointer-empty text-center"
          @click="
            $router.push({
              name: 'add-balance',
              params: { id: data.item.id }
            })
          "
        />
        <slot
          name="show"
          v-bind="data"
        >
          <div
            v-b-popover.hover.topright="'+ add wallet balance'"
            class="hover-pointer text-center"
            @click="
              $router.push({
                name: 'add-balance',
                params: { id: data.item.id }
              })
            "
          >
            <span
              v-if="data.item.wallet_balance"
              class="text-nowrap"
            >
              {{ data.item.wallet_balance }}  {{ data.item.wallet_currency }}
            </span>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      addComponentName: '',
      editComponent: '',
      addType: '',
      viewContent: true,
      type: 'page',
      filter: {},
      loading: false,
      delegatorRole: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'offer', label: 'Offer' },
        { key: 'wallet_balance', label: 'Wallet Balance' },
        // { key: 'wallet_currency', label: 'Wallet Currency' },
        { key: 'nearest_certificate', label: 'Nearest Certificate' },
        { key: 'auto_proceed', label: 'Auto Proceed' },
        { key: 'last_request_date', label: 'Last Request Date' },
        { key: 'view_details', label: 'View Details' },
        // { key: 'add_auto_proceed', label: 'Add Auto Proceed' },
        // { key: 'add_balance', label: 'Add Balance' },
        // { key: 'add_offer', label: 'Add Offer' },
      ],
    }
  },
  computed: {
    ...mapState({
      role: state => state.login.role,
    }),
    filteredColumns() {
      //   "admin",
      // "manager",
      // "super-manager",
      // "hr",
      // "process-manager",
      // "accountant",
      // "super-accountant",
      // "markter"
      if (this.role === 'super-accountant' || this.role === 'accountant') {
        return this.columns.filter(column => [
          'name',
          'email',
          'phone',
          'offer',
          'wallet_balance',
          'auto_proceed',
          'view_details',
          'add_auto_proceed',
          'add_balance',
        ].includes(column.key))
      }
      if (this.role === 'markter') {
        return this.columns.filter(column => [
          'name',
          'email',
          'phone',
          'offer',
          'wallet_balance',
          'auto_proceed',
          'view_details',
          'add_offer',
        ].includes(column.key))
      }
      return this.columns
    },
    apiUrl() {
      const base = 'delegator/signed?'
      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      const queryParams = Object.entries(this.filter)
        .filter(([key, value]) => key && value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return `${base}${queryParams}`
    },
  },
  mounted() {
    this.delegatorRole = this.role
  },
  methods: {
    AddAutoProceed(id) {
      this.$swal({
        title: 'Auto proceed Requests?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'YES',
        cancelButtonText: 'NO',
      })
        .then(result => {
          if (result.isConfirmed) {
            axios
              .post(`delegator/${id}/auto-proceed`, {
                _method: 'PUT',
                auto_proceed: 1,
              })
              .then(() => {
                window.location.reload()
              })
              .finally(() => {
                this.loading = false
              })
          } else if (result.dismiss === 'cancel') {
            axios
              .post(`delegator/${id}/auto-proceed`, {
                _method: 'PUT',
                auto_proceed: 0,
              })
              .then(() => {
                window.location.reload()
              })
              .finally(() => {
                this.loading = false
              })
          }
        })
    },
    filterTypes(type) {
      this.types = this.status.filter(item => item.text !== type)
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-pointer:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 2.5px 0px;
}
.hover-pointer-empty:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
  padding: 12.5px 0px;
}
</style>
