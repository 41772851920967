/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboard from './routes/dashboard'
import authentication from './routes/authentication'
import cities from './routes/cities'
import facility from './routes/facility'
import requests from './routes/requests'
import departments from './routes/departments'
import certificate from './routes/certificate'
import MainCertificate from './routes/mainCertificate'
import Components from './routes/components'
import Section from './routes/sections'
import Requirements from './routes/requirements'
import Offer from './routes/offer'
import UserPanel from './routes/userPanel'
import UserSite from './routes/userSite'
import EngineersCourses from './routes/engineers-courses'
import Engineers from './routes/engineers'
import Courses from './routes/courses'
import office from './routes/office'
import delegator from './routes/delegator'
import requestsOver from './routes/requestsOver'
import setMoney from './routes/set-money'
import auditAssigned from './routes/audit-assigned'
import auditNotAssigned from './routes/audit-not-assigned'
import allService from './routes/all-service'
import serviceNeedApproval from './routes/service-need-approval'
import serviceNeedSetMoney from './routes/service-need-set-money'
import employess from './routes/employess'
import devices from './routes/devices'
import schedule from './routes/schedule'
import setOffice from './routes/set-office'
import report from './routes/report'
import analysis from './routes/analysis'
import sourceAccount from './routes/source-account'
import accounting from './routes/accounting'
import ParkEntries from './routes/park-entries'
import PostEntries from './routes/post-entries'
import TaskManagement from './routes/task-management'
import product from './routes/product'
import training from './routes/training'
import post from './routes/post'
import support from './routes/support'
import lateReasons from './routes/late-reasons'
import UserTraining from './routes/user-training'
import AutoGeneratReport from './routes/auto-generate-report'
import PerformanceEvaluation from './routes/performance-evaluation'
import personnelEvaluation from './routes/personnel-evaluation'
import RequestInspection from './routes/request-inspection'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...accounting,
    ...authentication,
    ...dashboard,
    ...cities,
    ...facility,
    ...requests,
    ...departments,
    ...certificate,
    ...MainCertificate,
    ...Components,
    ...Section,
    ...Requirements,
    ...Offer,
    ...office,
    ...UserPanel,
    ...UserSite,
    ...EngineersCourses,
    ...Courses,
    ...delegator,
    ...Engineers,
    ...requestsOver,
    ...setMoney,
    ...auditAssigned,
    ...auditNotAssigned,
    ...allService,
    ...serviceNeedApproval,
    ...serviceNeedSetMoney,
    ...employess,
    ...devices,
    ...schedule,
    ...setOffice,
    ...report,
    ...analysis,
    ...sourceAccount,
    ...ParkEntries,
    ...PostEntries,
    ...TaskManagement,
    ...product,
    ...training,
    ...post,
    ...support,
    ...lateReasons,
    ...UserTraining,
    ...AutoGeneratReport,
    ...PerformanceEvaluation,
    ...personnelEvaluation,
    ...RequestInspection,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // // console.log(to)
  const isLoggedIn = localStorage.getItem('accessToken')

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !isLoggedIn) next({ name: 'login' })
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  const DEFAULT_TITLE = 'System Gulftic - Dashboard'
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
