<template>
  <div>
    <general-table
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :block-content="false"
      :edit-content="false"
      :view-content="true"
      :viw-component="viwComponent"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :delete-content="false"
    >
      <template #cell(status)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <span
            :style="`cursor: pointer;  color: ${statusColor(data.item.status)};`"
            class="hover-pointer-empty"
            @click="viewStaus(data.item.id)"
          >
            {{ data.item.status }}
          </span>
        </slot>
      </template>
    </general-table>
    <!--  -->
  </div>
</template>

<script>
import axios from 'axios'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'https://gulftic-system.fci.group/api/task/assigned',
      viwComponent: 'view-task',
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'issue', label: 'Issue' },
        { key: 'priority', label: 'Priority' },
        { key: 'due_date', label: 'Due Date' },
        { key: 'description', label: 'Description' },
        { key: 'required_action', label: 'Required Action' },
        { key: 'status', label: 'Status' },
        { key: 'request_id', label: 'Request Id' },
        { key: 'created_by.name', label: 'Created Name' },
        { key: 'created_by.email', label: 'Created Email' },
        { key: 'created_by_type', label: 'Created By Type' },
        { key: 'actions' },
      ],
    }
  },

  computed: {

    apiUrl() {
      let base = 'https://gulftic-system.fci.group/api/task/assigned?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log(this.filterTypes('admin'))
  // },

  methods: {
    statusColor(data) {
      switch (data) {
        case 'in-progress':
          return 'green'
        case 'pending':
          return '#f5851d'
        case 'completed':
          return 'blue'
        case 'cancelled':
          return 'red'
        default:
          return 'black' // Fallback color
      }
    },

    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },

    viewStaus(id) {
      this.$swal({
        title: 'Select status',
        input: 'select',
        inputOptions: {
          'in-progress': 'In Progress',
          completed: 'Completed',
        },
        inputPlaceholder: 'Select Status',
        showCancelButton: true,
        inputValidator: value => new Promise((resolve, reject) => {
          if (value) {
            resolve()
          } else {
            reject(new Error('You need to select a status'))
          }
        }),
      }).then(result => {
        if (result.isConfirmed) {
          const selectedStatus = result.value
          axios.put(`https://gulftic-system.fci.group/api/task/${id}/status`, {
            status: selectedStatus,
          })

            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Status Updated',
                text: `You selected: ${selectedStatus}`,
              }).then(() => {
                // Reload the page if the status update was successful
                window.location.reload()
              })
            }).catch(error => {
              this.$swal({
                icon: 'error',
                title: `Error ${error.data.message}`,
                text: 'Failed to update status.',
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.hover-pointer-empty:hover {
  color: white; /* Change the property you want to affect on hover */
  background-color: #40aaf7;
  cursor: pointer;
}
</style>
