<template>
  <b-modal
    v-model="localShowModal"
    :title="`Delete Currency Conversion From ${officeName}`"
    centered
    :ok-title="`Delete`"
    @ok="deleteCurrencyConversion"
  >
    <p>Are you sure you want to delete this Currency Conversion?</p>
  </b-modal>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DeleteCurrencyConversion',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    officeName: {
      type: String,
      required: true,
    },
    officeIdDelete: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      localShowModal: this.showModal,
    }
  },
  watch: {
    // Watch the prop for changes and update the local property
    showModal(newVal) {
      this.localShowModal = newVal
    },
    // Emit an update event when the local property changes
    localShowModal(newVal) {
      this.$emit('update:showModal', newVal)
      this.$emit('refreshCurrencyConversions')
    },
  },
  methods: {
    async deleteCurrencyConversion() {
      try {
        const res = await axios.delete(`currency/${this.officeIdDelete}`)
        if (res.status === 200 || res.status === 201) {
          this.$emit('refreshCurrencyConversions')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Deleted Successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        if (error.response?.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      }
    },
  },
}
</script>
