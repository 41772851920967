<template>
  <b-card>
    <b-row class="py-2">
      <b-col cols="12"><h1><strong>Request Number : </strong> <span class="text-primary">
        <a
          :href="
            `https://engineers.gulftic.com/orders/order/${$route.params.id}?role=${accessRole}&name=${accessName}&email=${accessEmail}&token=${accessToken}`
          "
          target="_blank"
        >
          {{ $route.query.request_code }}
        </a>
      </span></h1></b-col>
      <b-col cols="12">
        <h3 v-if="$route.query.certificate_ar || $route.query.certificate_en">
          <strong>Certificate : </strong>
          <template v-if="$route.query.certificate_ar && $route.query.certificate_en">
            <span style="color: #898989;">
              {{ $route.query.certificate_ar }} -- {{ $route.query.certificate_en }}
            </span>
          </template>
          <template v-else>
            <span style="color: #898989;">
              {{ $route.query.certificate_ar || $route.query.certificate_en }}
            </span>
          </template>
        </h3>
        <h3 v-else>
          <strong>Certificate : </strong>
          <span class="text-danger">No certificate on this request</span>
        </h3>
      </b-col>
      <b-col cols="12">
        <h3 v-if="$route.query.sub_certificate_ar || $route.query.sub_certificate_en">
          <strong>Sub Certificate : </strong>
          <template v-if="$route.query.sub_certificate_ar && $route.query.sub_certificate_en">
            <span style="color: #898989;">
              {{ $route.query.sub_certificate_ar }} -- {{ $route.query.sub_certificate_en }}
            </span>
          </template>
          <template v-else>
            <span style="color: #898989;">
              {{ $route.query.sub_certificate_ar || $route.query.sub_certificate_en }}
            </span>
          </template>
        </h3>
        <h3 v-else>
          <strong>Sub Certificate : </strong>
          <span class="text-danger">No certificate on this request</span>
        </h3>
      </b-col>
    </b-row>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col
          v-if="jobLoading"
          md="6"
          class="d-flex align-items-center justify-content-center"
        >
          <!-- Loading Indicator -->
          <b-spinner label="Loading..." />
        </b-col>
        <b-col
          v-if="!jobLoading"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="job"
            rules="required"
          >
            <b-form-group
              label="job"
              label-for="job"
            >
              <b-form-select
                id="job"
                v-model="assignEngineerForm.job"
                :state="getValidationState(validationContext)"
                :options="optionJob"
                trim
                placeholder="job"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="loading"
          md="6"
          class="d-flex align-items-center justify-content-center"
        >
          <!-- Loading Indicator -->
          <b-spinner label="Loading..." />
        </b-col>
        <b-col
          v-if="!loading && optionUser.length > 0"
          md="6"
        >
          <!-- Engineer -->
          <validation-provider
            #default="validationContext"
            name="engineer"
            rules="required"
          >
            <b-form-group
              label="engineer"
              label-for="engineer"
            >
              <b-form-select
                id="engineer"
                v-model="assignEngineerForm.user_id"
                :state="getValidationState(validationContext)"
                :options="optionUser"
                trim
                placeholder="Select an engineer"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignEngineer()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignEngineer()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      id: this.$store.state.generalIds.id,
      accessRole: localStorage.getItem('accessRole') || '',
      accessToken: localStorage.getItem('accessToken') || '',
      accessName: localStorage.getItem('name') || '',
      accessEmail: localStorage.getItem('email') || '',
      required,
      allSelected: [],
      users: [],
      optionUser: [],
      optionJob: [
        { value: 'application reviewer', text: 'Application Reviewer' },
        { value: 'first reviewer', text: 'First Reviewer' },
        { value: 'second reviewer', text: 'Second Reviewer' },
      ],
      engineers: [],
      loader: false,
      validationErrors: {},
      errors: {},
      loading: false,
      jobLoading: false,

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const assignEngineerForm = ref({
      job: null,
      user_id: null,
    })
    return {
      getValidationState,
      assignEngineerForm,
    }
  },
  // mounted() {
  //   this.showEngineer()
  // },
  watch: {
    'assignEngineerForm.job': function (newJob) {
      if (newJob) {
        this.fetchEngineersByJob(newJob)
        this.setEngineerByJob(newJob)
      } else {
        this.optionUser = [] // Clear the engineer options if no job is selected
        this.assignEngineerForm.user_id = null
      }
    },
  },
  // mounted() {
  //   if (this.$route.params.type === 'first_reviewer') {
  //     this.assignEngineerForm.job = 'first reviewer'
  //     this.fetchEngineersByJob('first reviewer')
  //   } else if (this.$route.params.type === 'second_reviewer') {
  //     this.assignEngineerForm.job = 'second reviewer'
  //     this.fetchEngineersByJob('second reviewer')
  //   } else if (this.$route.params.type === 'application_reviewer') {
  //     this.assignEngineerForm.job = 'application reviewer'
  //     this.fetchEngineersByJob('application reviewer')
  //   }
  // },
  async mounted() {
    // console.log(this.$route.query)
    const { type } = this.$route.params
    const jobMapping = {
      first_reviewer: 'first reviewer',
      second_reviewer: 'second reviewer',
      application_reviewer: 'application reviewer',
    }

    const selectedJob = jobMapping[type]
    if (selectedJob) {
      await this.getEngineers() // Ensure engineers are fetched before proceeding
      this.assignEngineerForm.job = selectedJob
      this.setEngineerByJob(selectedJob)
      this.fetchEngineersByJob(selectedJob) // Load engineers for job after setting the job
    }
  },

  methods: {
    async getEngineers() {
      this.jobLoading = true
      const url = `requests/${this.$route.params.id}/short-info`
      try {
        const res = await axios.get(url)
        this.engineers = decryptData(res.data.payload).data.request.assigned_to
      } catch (error) {
        this.errors = error.response.data.errors || {}
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error.response.data.message}`,
            icon: 'BellIcon',
            variant: 'error',
          },
        })
      } finally {
        this.jobLoading = false
      }
    },
    fetchEngineersByJob(job) {
      this.loading = true
      const url = `engineer/${this.$route.params.id}/job-filter?job=${job}`
      axios.get(url)
        .then(res => {
          // console.log(decryptData(res.data.payload))
          this.optionUser = decryptData(res.data.payload).data.engineers.map(item => ({
            value: item.id,
            text: item.name,
          }))
        })
        .catch(error => {
          this.errors = error.response.data.errors || {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    setEngineerByJob(job) {
      const engineer = this.engineers.find(findEngineer => findEngineer.job === job)
      if (engineer) {
        this.assignEngineerForm.user_id = engineer.id
      }
    },

    assignEngineer() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        // formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.assignEngineerForm) {
          formData.append(key, this.assignEngineerForm[key])
        }

        axios
          .post(`requests/${this.$route.params.id}/assign`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'requests' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
