<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name product"
              label-for="Name"
            >
              <b-form-input
                id="from-date"
                v-model="productForm.name"
                type="text"
                :state="getValidationState(validationContext)"
                trim
                placeholder="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Department"
          >

            <b-form-group
              label="Department "
              label-for="Department"
            >
              <b-form-select
                id="department_id"
                v-model="productForm.department_id"
                :options="optionsDepertment"
                trim
                placeholder="choose department"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addproduct()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addproduct()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionsDepertment: [],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const productForm = ref({})
    return {
      getValidationState,
      productForm,
    }
  },
  mounted() {
    this.getDepartment()
    this.getProduct()
  },
  methods: {
    getDepartment() {
      this.loadtable = true
      axios.get('departments').then(res => {
        this.optionsDepertment = decryptData(res.data.payload).data.departments.map(item => ({
          value: item.id,
          text: item.title,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },

    getProduct() {
      if (this.$route.params.id) {
        axios.get(`product-categories/${this.$route.params.id}`).then(res => {
          this.productForm = decryptData(res.data.payload).data.productCategory
        })
      } else {
        return false
      }
      return true
    },
    addproduct() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          formData.append(key, this.productForm[key])
        }

        axios.post(`product-categories/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'product' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.productForm) {
          formData.append(key, this.productForm[key])
        }

        axios.post('product-categories', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'product' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
