<template>
  <div>
    <b-card>
      <validation-observer
        ref="infoRules"
        v-slot="{ invalid }"
        tag="form"
      >
        <b-row>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Engineer"
            >

              <b-form-group
                label="Engineer "
                label-for="Engineer"
              >
                <v-select
                  id="engineer"
                  v-model="userTrainingeForm.user_id"
                  :state="getValidationState(validationContext)"
                  :options="optionUser"
                  trim
                  placeholder="Select Engineer"
                  :clearable="false"
                  :disabled="isSelected"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>
          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="training"
            >

              <b-form-group
                label="Training "
                label-for="Training"
              >
                <v-select
                  id="training"
                  v-model="userTrainingeForm.training_id"
                  :state="getValidationState(validationContext)"
                  :options="optionTraining"
                  trim
                  placeholder="Select training"
                  :clearable="false"
                  :disabled="isSelected"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Start Date"
            >

              <b-form-group
                label="Start Date "
                label-for="Start Date"
              >
                <b-form-input
                  id="start_date"
                  v-model="userTrainingeForm.start_date"
                  trim
                  type="date"
                  placeholder="Start Date"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col
            v-if="isSelected"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="End Date"
            >

              <b-form-group
                label="End Date "
                label-for="End Date"
              >
                <b-form-input
                  id="End_date"
                  v-model="userTrainingeForm.end_date"
                  trim
                  type="date"
                  placeholder="End Date"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col
            v-if="isSelected"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Score"
            >

              <b-form-group
                label="Score "
                label-for="Score"
              >
                <b-form-input
                  id="score"
                  v-model="userTrainingeForm.score"
                  trim
                  type="number"
                  placeholder="Score"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col
            v-if="isSelected"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              rules="required"
              name="Expiration Date"
            >

              <b-form-group
                label="Expiration Date "
                label-for="Expiration Date"
              >
                <b-form-input
                  id="expiration_date"
                  v-model="userTrainingeForm.expiration_date"
                  trim
                  type="date"
                  placeholder="Expiration Date"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>

          <b-col
            v-if="isSelected"
            md="6"
          >
            <b-form-group
              label="Certificate"
              label-for="certificate"
            >
              <b-form-file
                id="certificate"
                v-model="userTrainingeForm.certificate"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="handleFileChange"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="ShowCertificateIcon"
            md="6"
          >

            <div
              style="width: 100%; height: 100%;"
            >
              <a
                target="_blank"
                :href="haveCertificate.url"
              >
                <img
                  src="@/assets/images/icons/certificate.svg"
                  style="height: 4rem; width: 4rem"
                >
                <span class="ml-1 toggle-content">{{ haveCertificate.name }}</span>
              </a>
            </div>

          </b-col>

          <!-- Server Errors -->
          <b-col
            v-if="Object.values(errors).length > 0"
            cols="12"
          >
            <p
              v-for="(error, key, index) in errors"
              :key="index"
              class="text-danger small"
            >
              {{ error.toString() }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mt-50"
          >

            <b-button
              v-if="!loader && $route.params.id"
              variant="primary"
              class="mr-1"
              :disabled="invalid || Object.values(errors).length > 0"
              @click="addUserTraining()"
            >
              Save Changes
            </b-button>
            <b-button
              v-if="!loader && !$route.params.id"
              variant="primary"
              class="mr-1"
              :disabled="invalid || Object.values(errors).length > 0"
              @click="addUserTraining()"
            >
              Add
            </b-button>
            <b-button
              v-if="loader"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner
                small
                type="grow"
              />
              Loading...
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>

</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import VSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'
// eslint-disable-next-line no-unused-vars

export default {
  components: {
    'v-select': VSelect,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      optionUser: [],
      optionTraining: [],
      ShowCertificateIcon: false,
      haveCertificate: {
        name: null,
        url: null,
      },
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const userTrainingeForm = ref({
      user_id: {
        value: null,
        label: null,
      },
      training_id: {
        value: null,
        label: null,
      },
      certificate: '',

    })
    return {
      getValidationState,
      userTrainingeForm,
    }
  },
  computed: {
    isSelected() {
      return this.$route.params.id
    },
  },
  mounted() {
    this.getEngineer()
    this.getTraining()
    this.showUserTraining()
  },

  methods: {
    handleFileChange(event) {
      this.ShowCertificateIcon = true
      const file = event.target.files[0] // Get the first file
      this.haveCertificate = {
        name: file.name,
        url: URL.createObjectURL(file),
      }
    },
    getEngineer() {
      axios.get('https://gulftic-system.fci.group/api/engineers/list').then(res => {
        this.optionUser = decryptData(res.data.payload).data.engineers.map(item => ({
          value: item.id,
          label: item.name,
        }))
      })
    },
    getTraining() {
      axios.get('/training/list').then(res => {
        this.optionTraining = decryptData(res.data.payload).data.training.map(item => ({
          value: item.id,
          label: item.name,
        }))
      })
    },

    showUserTraining() {
      if (this.$route.params.id) {
        axios.get(`user-training/${this.$route.params.id}`).then(res => {
          const userTrainingData = decryptData(res.data.payload).data['user training']
          const { certificate } = decryptData(res.data.payload).data
          if (certificate == null) {
            this.ShowCertificateIcon = false
          } else {
            this.ShowCertificateIcon = true
          }
          this.userTrainingeForm.user_id = {
            value: userTrainingData.users.id,
            label: userTrainingData.users.name,
          }
          this.userTrainingeForm.training_id = {
            value: userTrainingData.trainings.id,
            label: userTrainingData.trainings.name,
          }
          this.userTrainingeForm.start_date = userTrainingData.start_date
          this.userTrainingeForm.end_date = userTrainingData.end_date
          this.userTrainingeForm.score = userTrainingData.score
          this.userTrainingeForm.expiration_date = userTrainingData.expiration_date

          this.haveCertificate = {
            name: certificate.name,
            url: certificate.url,
          }
        })
      } else {
        return false
      }
      return true
    },

    addUserTraining() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')

        Object.keys(this.userTrainingeForm).forEach(key => {
          if (key === 'user_id' || key === 'training_id') {
            formData.append(key, this.userTrainingeForm[key]?.value || null)
          } else if (Array.isArray(this.userTrainingeForm[key])) {
            this.userTrainingeForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.userTrainingeForm[key])
          }
        })

        axios.post(`user-training/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'user-training' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()

        Object.keys(this.userTrainingeForm).forEach(key => {
          if (key === 'user_id' || key === 'training_id') {
            formData.append(key, this.userTrainingeForm[key]?.value || null)
          } else if (Array.isArray(this.userTrainingeForm[key])) {
            this.userTrainingeForm[key].forEach(item => {
              formData.append(`${key}[]`, item)
            })
          } else {
            formData.append(key, this.userTrainingeForm[key])
          }
        })

        axios.post('user-training', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'user-training' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
