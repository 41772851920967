<template>
  <b-card>
    <b-card-title style="font-size: xx-large; text-align: center;">
      Client Info
    </b-card-title>
    <b-card-text v-if="delegatorForm.name">
      <strong>Name: </strong>{{ delegatorForm.name }}
    </b-card-text>
    <b-card-text v-if="delegatorForm.email">
      <strong>Email: </strong>{{ delegatorForm.email }}
    </b-card-text>
    <b-card-text v-if="delegatorForm.phone">
      <strong>Phone: </strong>{{ delegatorForm.phone }}
    </b-card-text>
    <b-card-text
      v-if="delegatorForm.offer"
    ><strong>Offer: </strong>{{ delegatorForm.offer.name }} -
      {{ delegatorForm.offer.amount }} %</b-card-text>
    <b-card-text v-if="delegatorForm.wallet_balance">
      <strong>Wallet Balance: </strong>{{ delegatorForm.wallet_balance }}
    </b-card-text>
    <b-card-text><strong>Auto Proceed: </strong>{{ delegatorForm.auto_proceed === 1 ? "true" : "false" }}</b-card-text>

    <b-card-title
      v-if="delegatorForm.certificates.length > 0"
      style="font-size: xx-large;"
    >
      Certificates
    </b-card-title>
    <div
      v-if="delegatorForm.certificates.length > 0"
      style="display: flex; flex-wrap: wrap; gap: 10px;"
    >
      <div
        v-for="certificate in delegatorForm.certificates"
        :key="certificate.id"
        style="border: 1px solid #d8d6de; width: fit-content; padding: 10px;"
      >
        <!-- <b-card-text><strong>Certificate ID: </strong>{{ certificate.id }}</b-card-text> -->
        <b-card-text><strong>Issued At: </strong>{{ certificate.issued_at }}</b-card-text>
        <b-card-text><strong>Expired At: </strong>{{ certificate.expired_at }}</b-card-text>

        <b-card-title
          style="font-size: x-large; border: 1px solid; text-align: center;"
        >
          certificate Info
        </b-card-title>

        <b-card-text><strong>Certificate Name: </strong>{{ certificate.certificate.name.en }}</b-card-text>
        <b-card-text><strong>Certificate Name Arabic: </strong>{{ certificate.certificate.name.ar }}</b-card-text>
        <b-card-text><strong>Certificate Description: </strong>{{ certificate.certificate.description }}</b-card-text>
        <b-card-text><strong>Process Type: </strong>{{ certificate.certificate.process_type }}</b-card-text>
        <b-card-text><strong>Process Time Days: </strong>{{ certificate.certificate.proccess_time_days }}</b-card-text>
        <b-card-text><strong>load : </strong>{{ certificate.certificate.load }}</b-card-text>
        <b-card-text><strong> Expiratino Duration Months: </strong>{{ certificate.request.expiratino_duration_months }}</b-card-text>
        <b-card-text><strong>Faq Text : </strong>{{ certificate.certificate.faq_text }}</b-card-text>
        <b-card-text><strong>Price : </strong>{{ certificate.certificate.price }}</b-card-text>

        <b-card-title
          style="font-size: x-large; border: 1px solid; text-align: center;"
        >
          Request
        </b-card-title>

        <b-card-text><strong>Request Code: </strong>{{ certificate.request.request_code }}</b-card-text>
        <b-card-text><strong>Request Status: </strong>{{ certificate.request.status }}</b-card-text>
        <b-card-text><strong>Request Source: </strong>{{ certificate.request.source }}</b-card-text>
        <b-card-text><strong>Request Certificate Name: </strong>{{ certificate.request.certificate_name.en }}</b-card-text>
        <b-card-text><strong>Request Certificate Name Arabic: </strong>{{ certificate.request.certificate_name.ar }}</b-card-text>
        <b-card-text><strong>Request Payment Status: </strong>{{ certificate.request.payment_status }}</b-card-text>
        <b-card-text><strong>Request Final Price: </strong>{{ certificate.request.final_price }}</b-card-text>
        <b-card-text><strong>Offer Name: </strong>{{
          certificate.request.offer ? certificate.request.offer.name : "N/A"
        }}</b-card-text>
      </div>
    </div>
  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      delegatorForm: [],
    }
  },

  mounted() {
    this.showDelegator()
  },
  methods: {
    showDelegator() {
      if (this.$route.params.id) {
        axios.get(`/delegator/signed/${this.$route.params.id}`).then(res => {
          this.delegatorForm = decryptData(res.data.payload).data.delegator
        })
      } else {
        return false
      }
      return true
    },
  },
}
</script>
