export default [
  {
    path: '/auto-generate-report',
    name: 'auto-generate-report',
    component: () => import('@/views/auto-generate-report/AutoGenerateReport.vue'),
    meta: {
      title: 'Generate Report ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Generate Report',
      pageTitle: 'Generate Report',
      breadcrumb: [
        {
          text: 'Generate Report',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/auto-generate-report/add/:name/:id?',
    name: 'add-auto-generate-report',
    component: () => import(
      '@/views/auto-generate-report/control-auto-generate-report/AddEditAutoGenerateReport.vue'
    ),
    meta: {
      title: 'Add Auto Generate Report ',
      requiresAuth: false,
      action: 'create',
      resource: 'Add Auto Generate Report',
      pageTitle: 'Add Auto Generate Report',
      breadcrumb: [
        {
          text: 'Auto Generate Report',
          to: '/auto-generate-report',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/templates-auto-generate-reportt',
    name: 'templates-auto-generate-report',
    component: () => import('@/views/auto-generate-report/MyTemplates.vue'),
    meta: {
      title: 'Add Auto Generate Report ',
      requiresAuth: false,
      action: 'create',
      resource: 'Add Auto Generate Report',
      pageTitle: 'Add Auto Generate Report',
      breadcrumb: [
        {
          text: 'Auto Generate Report',
          to: '/auto-generate-report',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
