<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
    >
      <template #cell(product_names)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="ArchiveIcon"
              size="16"
              class="mr-50 cursor-pointer"
              @click="ApprovalRow(data.item.products)"
            />
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'departments',
      addType: 'Add Departments',
      addComponentName: 'add-departments',
      editComponent: 'edit-departments',
      viewContent: true,
      type: 'page',
      columns: [
        // { key: 'id', sortable: false },
        { key: 'title', label: 'Title' },
        { key: 'title_ar', label: 'Title Ar' },
        { key: 'title_en', label: 'Title En' },
        { key: 'description', label: 'Description' },
        { key: 'description_ar', label: 'Description Ar' },
        { key: 'description_en', label: 'Description En' },
        { key: 'product_names', label: 'Products List' },
        // { key: 'actions' },
      ],
    }
  },
  methods: {

    formatProductNames(names) {
      return `
         <table style="border: 0.5px solid; border-collapse: collapse; margin-top: 18px !important;  margin: auto;">
          <tr style="background-color: #f3f2f7">
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">#</th>
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">Product Names</th>
          </tr>
          ${names.map((name, index) => `
            <tr>
              <td style="border: 1px solid; padding: 8px !important;">${index + 1}</td>
              <td style="border: 1px solid; padding: 8px !important; text-align: left !important; text-wrap: wrap !important;">${name.name}</td>
            </tr>`).join('')}
        </table>
      `
    },
    ApprovalRow(name) {
      this.$swal({
        title: 'Product List',
        html: this.formatProductNames(name),
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Close',
      })
    },

  },
}
</script>

<style>

</style>
