var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{ref:"countriesTable",attrs:{"api-url":_vm.apiUrl,"delete-content":false,"block-content":false,"edit-content":false,"view-content":false,"columns":_vm.columns,"type":_vm.type},scopedSlots:_vm._u([{key:"cell(actions_inspection)",fn:function(data){return [_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EditIcon"},on:{"click":function($event){return _vm.$router.push({
            name: 'edit-request-inspection',
            params: {
              id: data.item.id,
              name: data.item.inspector && data.item.inspector.name
                ? data.item.inspector.name
                : 'No Inspector Assign'
            }
          })}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }