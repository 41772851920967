export default [
  {
    path: '/late-reasons',
    name: 'late-reasons',
    component: () => import('@/views/late-reasons/LateReasons.vue'),
    meta: {
      title: 'Late Reasons',
      requiresAuth: false,
      action: 'browes',
      resource: 'Late Reasons',
      pageTitle: 'Late Reasons',
      breadcrumb: [
        {
          text: 'Late Reasons',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
