<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
      @submit.prevent
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="postForm.title"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Add Title of this Post"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="category"
            rules="required"
          >
            <b-form-group
              label="Category"
              label-for="Category"
            >
              <b-form-select
                v-model="postForm.category"
                :options="categoryOptions"
                placeholder="Select Category"
                :state="getValidationState(validationContext)"
                :searchable="true"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Tages"
            label-for="Tages"
          >
            <b-row>

              <b-col md="10"><b-form-input
                id="tags"
                v-model="newTag"
                placeholder="Add Tags to Post"
              /></b-col>
              <b-col
                md="2"
                class="w-100"
              ><b-button
                type="input"
                @click="addTag"
              >
                Add
              </b-button></b-col>

            </b-row>
          </b-form-group>
        </b-col>

        <b-col md="4">

          <ul class="tag-list">
            <li
              v-for="(tag, index) in postForm.tags"
              :key="index"
              class="tag-item"
            >
              {{ tag }}
              <button
                class="remove-tag"
                @click.stop="removeTag(index)"
              >
                x
              </button>
            </li>
          </ul>
        </b-col>

        <b-col md="6">
          <b-media
            no-body
            class="mb-1"
          >
            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  v-img
                  :src="postForm.image"
                  width="100"
                  height="100"
                />
              </b-link>
            </b-media-aside>

            <b-media-body>
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload photo
              </b-button>
              <b-form-file
                ref="refInputEl"
                accept=".jpg, .png"
                :hidden="true"
                plain
                @change="changeProfile($event)"
              />
            </b-media-body>
          </b-media>
        </b-col>

        <b-col md="6">
          <b-row>
            <b-col md="12">
              <label>Upload File</label>
              <b-form-file
                ref="fileInput"
                v-model="selectedFiles"
                type="file"
                multiple
                @change="handleFileUpload"
              />
            </b-col>
            <b-col
              md="12"
              class="mt-1"
            >
              <h6 v-if="selectedFiles.length || oldSelectedFiles.length">
                Selected Files:
              </h6>
              <ul v-if="selectedFiles.length">
                <li
                  v-for="(file, index) in selectedFiles"
                  :key="index"
                  class="pb-1 d-flex"
                >
                  {{ file.name }}
                  <b-button
                    variant="danger"
                    size="sm"
                    class="ml-2"
                    @click="removeFile(index)"
                  >
                    Remove
                  </b-button>
                </li>
              </ul>
              <ul v-if="oldSelectedFiles.length">
                <li
                  v-for="file in oldSelectedFiles"
                  :key="file.id"
                  class="pb-1 d-flex"
                >
                  <a
                    :href="file.url"
                    target="_blank"
                  >{{ file.name }}</a>
                  <b-button
                    v-if="!loaderBtn[file.id]"
                    variant="danger"
                    size="sm"
                    class="ml-2"
                    @click="removeOldFile(file.id)"
                  >
                    Remove
                  </b-button>
                  <b-button
                    v-else
                    variant="danger"
                    disabled
                    size="sm"
                    class="ml-2"
                  >
                    <b-spinner
                      small
                      type="grow"
                    />
                    Loading...
                  </b-button>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12">

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="content"
            rules="required"
          >
            <b-form-group
              label="content"
              label-for="content"
            >
              <quillEditor
                id="content"
                v-model="postForm.content"
                :state="getValidationState(validationContext)"
                @input="updateContentAsHtml"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addPost()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addPost()"
          >
            Add Post
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { quillEditor } from 'vuejs-quill'
import Quill from 'quill'
import decryptData from '@/store/auth/decryptData'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      loaderBtn: {},
      validationErrors: {},
      errors: {},
      categoryOptions: ['news', 'announcements'],
      quillInstance: null,
      selectedFiles: [],
      oldSelectedFiles: [],
      postFormContentMiddel: '',
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const postForm = ref({
      title: '',
      content: '',
      category: null,
      image: null,
      tags: [],
    })
    // eslint-disable-next-line camelcase
    const image = ref('')
    const newTag = ref('')

    return {
      getValidationState,
      postForm,
      image,
      newTag,
    }
  },
  mounted() {
    const container = document.createElement('div')
    this.quillInstance = new Quill(container)
    this.showPost()
  },
  methods: {

    handleFileUpload(event) {
      this.selectedFiles = Array.from(event.target.files) // Store selected files
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1) // Remove file from selected files array
    },
    removeOldFile(index) {
      this.$set(this.loaderBtn, index, true)

      axios.delete(`posts/${this.$route.params.id}/attachments`, { data: { attachment_id: index } })
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$delete(this.loaderBtn, index)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Delete successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.showPost()
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Can\'t Delete File',
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.$delete(this.loaderBtn, index)
        })
    },
    updateContentAsHtml(delta) {
      if (this.quillInstance) {
        // Load the Delta object into the Quill instance
        this.quillInstance.setContents(delta)
        this.postFormContentMiddel = this.quillInstance.root.innerHTML
        // console.log(this.postFormContentMiddel)
      }
    },
    addTag() {
      const tag = this.newTag.trim()
      if (tag && !this.postForm.tags.includes(tag)) {
        this.postForm.tags.push(tag)
        this.newTag = '' // Clear the input after adding the tag
      }
    },
    removeTag(index) {
      this.postForm.tags.splice(index, 1)
    },
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    changeProfile(e) {
      const input = e.target
      if (input.files && input.files[0]) {
        const file = input.files[0]
        this.image = file
        const reader = new FileReader()
        reader.onload = er => {
          this.postForm.image = er.target.result
        }

        reader.readAsDataURL(file)
      }
    },
    showPost() {
      if (this.$route.params.id) {
        axios.get(`posts/${this.$route.params.id}`).then(res => {
          const response = decryptData(res.data.payload)
          this.postForm = {
            ...response.data.post,
            image: response.data.post.imageUrl,
          }
          this.image = null
          this.oldSelectedFiles = response.data.post.attachments
        })
      } else {
        return false
      }
      return true
    },

    addPost() {
      if (this.$route.params.id) {
        this.loader = true
        this.postForm.content = this.postFormContentMiddel

        const formData = new FormData()
        formData.append('_method', 'PUT')

        if (this.selectedFiles.length > 0) {
          this.selectedFiles.forEach((file, index) => formData.append(`attachments[${index}]`, file))
        }

        Object.entries(this.postForm).forEach(([key, value]) => {
          if (key === 'tags' && Array.isArray(value)) {
            // Serialize tags array as JSON
            value.forEach((tag, index) => {
              formData.append(`tags[${index}]`, tag)
            })
          } else if (key !== 'image') {
            formData.append(key, value)
          }
        })

        // Append the image as binary data
        if (this.image) {
          formData.append('image', this.image)
        }

        axios
          .post(`posts/${this.$route.params.id}`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'post' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${error.response.data.message}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.loader = true
        this.postForm.content = this.postFormContentMiddel

        const formData = new FormData()

        if (this.selectedFiles.length > 0) {
          this.selectedFiles.forEach((file, index) => formData.append(`attachments[${index}]`, file))
        }

        Object.entries(this.postForm).forEach(([key, value]) => {
          if (key === 'tags' && Array.isArray(value)) {
            value.forEach((tag, index) => {
              formData.append(`tags[${index}]`, tag)
            })
          } else if (key !== 'image') {
            formData.append(key, value)
          }
        })

        if (this.image) {
          formData.append('image', this.image)
        }

        axios
          .post('posts', formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'post' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${errorMessage}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },

  },
}
</script>

<style>
.tag-list {
  list-style: none;
  padding: 0;
}

.tag-item {
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
}

.remove-tag {
  background: none;
  border: none;
  color: red;
  margin-left: 5px;
  cursor: pointer;
}
.ql-formats .ql-image{
  display: none !important;
}
.ql-formats .ql-video{
  display: none !important;
}
</style>
