export default [
  {
    path: '/mainCertificate',
    name: 'main-certificate',
    component: () => import('@/views/main-certificate/MainCertificate.vue'),
    meta: {
      title: 'Main Certificate ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Main Certificate',
      pageTitle: 'Main Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/mainCertificate/add',
    name: 'add-main-certificate',
    component: () => import(
      '@/views/main-certificate/control-main-certificate/AddEditMainCertificate.vue'
    ),
    meta: {
      title: 'Add Main Certificate ',
      requiresAuth: false,
      action: 'create',
      resource: 'certificate',
      pageTitle: 'Add Main Certificate',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/mainCertificate/edit/:id',
  //   name: 'edit-main-certificate',
  //   component: () => import(
  //     '@/views/main-certificate/control-main-certificate/AddEditMainCertificate.vue'
  //   ),
  //   meta: {
  //     title: 'Edit Certificate ',
  //     requiresAuth: false,
  //     pageTitle: 'Edit Main Certificate',
  //     breadcrumb: [
  //       {
  //         text: 'Main Certificate',
  //         to: '/mainCertificate',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
