<template>
  <div class="container py-4">
    <div
      v-if="dataLoad"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>
    <div
      v-else
      class="row"
    >
      <!-- Left Column: Recent Chats -->
      <div
        class="col-md-4"
        style="padding: 0px;"
      >
        <div
          class="card"
          style="box-shadow: none; border-radius: 0px;"
        >
          <div
            class="card-header bg-primary text-white"
            style="border-radius: 0px; border-right: 1px solid #dae1e7;"
          >
            Recent Chats
          </div>
          <div
            class="card-body chat-list overflow-auto"
            style="min-height: 60vh; padding: 0px; border-right: 1px solid #dae1e7;"
          >
            <div
              v-for="(chat, index) in recentChats"
              :key="index"
              class="cursor-pointer"
              style="padding: 6px 10px;"
              :class="{ 'selected-chat': selectedUser === chat }"
              @click="selectChat(chat)"
            >
              <div class="fw-bold">
                {{ chat.email }}
              </div>
              <div class="text-muted">
                {{ chat.lastMessage }}
              </div>
              <div
                class="text-muted"
                style="text-align: end; font-size: 9px; padding-top: 4px;"
              >
                {{ chat.createdAt }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right Column: Chat Window -->
      <div
        class="col-md-8"
        style="padding: 0px;"
      >
        <div
          class="card"
          style="box-shadow: none; border-radius: 0px;"
        >
          <div class="card-header bg-primary text-white">
            Chat with
            <span style="font-weight: bold;">{{
              selectedUser ? selectedUser.user : ""
            }}</span>
          </div>
          <div
            v-if="isSelectedChat"
            ref="chatWindow"
            class="card-body chat-window"
            :style="{ backgroundImage: `url('${backgroundChatImage}')` }"
          >
            <div class="overlay">
              <div
                v-for="(message, index) in selectedChat"
                :key="index"
                class="mb-2"
                :style="`text-align: ${message.it_team_member ? 'right' : 'left'};`"
              >
                <span
                  v-if="message.it_team_member"
                  class="fw-bold"
                  style="font-weight: bold;"
                >
                  <!-- {{ message.it_team_member }}: -->
                </span>
                <span
                  v-else
                  class="fw-bold"
                  style="font-weight: bold;"
                >
                  <b-avatar
                    v-if="message.text || message.file"
                    :text="getInitials(selectedUser.user)"
                    variant="info"
                    size="3.8em"
                  />
                </span>

                <span
                  :class="`chat-text ${message.it_team_member ? 'align-right' : 'align-left'}`"
                >
                  <div class="d-flex flex-column">
                    <template v-if="message.text">{{ message.text }} </template>
                    <template v-if="message.file.url">
                      <audio
                        v-if="isAudio(message.file.url)"
                        style="height: 30px;"
                        controls
                      >
                        <source
                          :src="message.file.url"
                          type="audio/mpeg"
                        >
                        Your browser does not support the audio element.
                      </audio>
                      <a
                        v-else
                        target="_blank"
                        :href="message.file.url"
                      >
                        <feather-icon
                          icon="FileIcon"
                          class="cursor-pointer"
                        />
                        {{ message.file.name }}
                      </a>
                    </template>
                  </div>
                  <span class="date-of-message">({{ convertDate(message.created_at) }})</span>
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="card-body chat-window"
          />

          <div class="card-footer">
            <div class="d-flex align-items-center">
              <input
                v-model="messageForm.text"
                type="text"
                class="form-control mr-1"
                placeholder="Type your message..."
                :disabled="!isSelectedChat"
                @keyup.enter="sendMessage(selectedUser.id)"
              >
              <div
                class="d-flex"
              >
                <feather-icon
                  :class="`${!isSelectedChat ? 'cursor-none' : 'cursor-pointer' } mx-1`"
                  variant="primary"
                  :color="!isSelectedChat ? '#d7d5de' : 'primary'"
                  icon="PaperclipIcon"
                  size="28"
                  @click="triggerFileInput"
                />
                <input
                  ref="fileInput"
                  type="file"
                  class="d-none"
                  :disabled="!isSelectedChat"
                  @change="handleFileSelection"
                >
                <!-- <feather-icon
                  :class="`${!isSelectedChat ? 'cursor-none' : 'cursor-pointer' } mx-1`"
                  variant="primary"
                  :color="!isSelectedChat ? '#d7d5de' : 'primary'"
                  icon="MicIcon"
                  size="28"
                /> -->
                <!--                                 -->
                <div class="recording-controls">
                  <feather-icon
                    v-if="!recording"
                    :class="`${!isSelectedChat ? 'cursor-none' : 'cursor-pointer' } mx-1`"
                    variant="primary"
                    :color="!isSelectedChat ? '#d7d5de' : 'primary'"
                    icon="MicIcon"
                    size="28"
                    @click="toggleRecording"
                  />
                  <feather-icon
                    v-else
                    :class="`${!isSelectedChat ? 'cursor-none' : 'cursor-pointer' } mx-1`"
                    variant="primary"
                    :color="!isSelectedChat ? '#d7d5de' : 'primary'"
                    icon="StopCircleIcon"
                    size="28"
                    @click="toggleRecording"
                  />

                </div>
                <div>
                  <b-spinner
                    v-if="loadingBtn"
                    variant="primary"
                  />

                  <button
                    v-else
                    class="btn btn-primary"
                    :disabled="!isSelectedChat"
                    @click="sendMessage(selectedUser.id)"
                  >
                    Send
                  </button>
                </div>

              </div>

            </div>
            <div class="d-flex align-items-center pt-1">
              <span
                v-if="recording"
                class="start-recording"
              > Recording: <span style="color: red; font-weight: bold; padding: 5px;">{{ formattedTime }}</span>  seconds</span>
              <div
                v-if="audioBlob"
                class="d-flex align-items-center"
                style="background-color: #f2f4f5; border-radius:25px; padding: 5px"
              >
                <audio
                  :src="audioUrl"
                  controls
                />
                <feather-icon
                  class="cursor-pointer mx-2"
                  color="#b0b0b0"
                  icon="XIcon"
                  size="25"
                  style="border: 1px solid #b0b0b0; border-radius: 50%;"
                  @click="clearRecording"
                />
              </div>
            </div>
            <div
              v-if="messageForm.file"
              class="d-flex align-items-center pt-1"
            >
              <span
                class="text-primary "
              >{{ fileName }}</span>
              <feather-icon
                class="cursor-pointer mx-2"
                color="red"
                icon="XIcon"
                size="20"
                @click="removeFile"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'
import { convertDateWithTime } from '@/libs/getDate'
import backgroundChatImage from '@/assets/images/other/background-chat.jpg'

export default {
  data() {
    return {
      // List of recent chats will be populated by the API response
      recentChats: [],
      selectedChat: null,
      selectedUser: null,
      isSelectedChat: false,
      loadingBtn: false,
      backgroundChatImage,
      dataLoad: false,
      fileName: '',
      /// //// voice data///////
      recording: false,
      recorder: null,
      audioBlob: null,
      audioUrl: null,
      timer: 0,
      timerInterval: null,
      message: '',
    }
  },
  setup() {
    // eslint-disable-next-line camelcase

    const messageForm = ref({
      text: '',
      file: null,
    })
    return {
      // getValidationState,
      messageForm,
    }
  },
  computed: {
    formattedTime() {
      const minutes = String(Math.floor(this.timer / 60)).padStart(2, '0')
      const seconds = String(this.timer % 60).padStart(2, '0')
      return `${minutes}:${seconds}`
    },
  },
  created() {
    // Fetch the data when the component is created
    this.fetchChats()
  },
  // mounted() {
  // // Poll for new messages every 5 seconds
  //   setInterval(() => {
  //     if (this.selectedUser) {
  //       this.selectChat(this.selectedUser)
  //     }
  //   }, 5000)
  // },
  methods: {
    async toggleRecording() {
      if (!this.isSelectedChat) {
        // Stop the function if no chat is selected
        return // Exit early
      }
      if (this.recording) {
        this.stopRecording()
      } else {
        await this.startRecording()
      }
    },
    async startRecording() {
      this.recording = true
      this.timer = 0
      this.timerInterval = setInterval(() => {
        this.timer += 1
      }, 1000)

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      this.recorder = new MediaRecorder(stream)
      const chunks = []

      this.recorder.ondataavailable = event => {
        chunks.push(event.data)
      }

      this.recorder.onstop = () => {
        this.audioBlob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' })
        this.audioUrl = URL.createObjectURL(this.audioBlob)
        stream.getTracks().forEach(track => track.stop())
      }

      this.recorder.start()
    },
    stopRecording() {
      this.recording = false
      clearInterval(this.timerInterval)
      this.recorder.stop()
    },
    clearRecording() {
      this.audioBlob = null
      this.audioUrl = null
    },
    removeFile() {
      this.messageForm.file = null
      this.$refs.fileInput.value = '' // Clear the file input value
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    handleFileSelection(event) {
      const file = event.target.files[0]
      if (file) {
        this.messageForm.file = file
        this.fileName = file.name
      }
    },
    isImage(url) {
      // Check if the file is an image
      return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url)
    },
    isAudio(url) {
      // Check if the file is an MP3
      return /\.mp3$/i.test(url)
    },
    // Fetch the recent chats from the API
    async fetchChats() {
      this.dataLoad = true
      try {
        const response = await axios.get('/support-chat')
        const data = decryptData(response.data.payload)?.data?.messages

        // console.log(data)

        // Transform the API response into the desired structure
        this.recentChats = this.transformChats(data)
      } catch (error) {
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Have an error to get recent chats',
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        }
      } finally {
        this.dataLoad = false
      }
    },
    // Transform the response data into the format for recentChats
    convertDate(date) {
      // console.log(date)
      return convertDateWithTime(date)
    },
    transformChats(data) {
      // Create a map to group messages by sender
      const chatsMap = {}

      // eslint-disable-next-line no-unused-vars
      Object.entries(data).forEach(([key, messageData]) => {
        const sender = messageData?.[0].sender.name
        const message = {
          user: sender,
          text: messageData?.[0].text,
          email: messageData?.[0].sender.email,
        }

        if (chatsMap[sender]) {
          chatsMap[sender].messages.push(message)
          chatsMap[sender].lastMessage = this.truncateMessage(messageData?.[0].text)
          chatsMap[sender].createdAt = messageData?.[0].createdAt
        } else {
          // If this is the first message for this sender, create a new entry
          chatsMap[sender] = {
            user: sender,
            email: messageData?.[0].sender.email,
            lastMessage: this.truncateMessage(messageData?.[0].text),
            createdAt: convertDateWithTime(messageData?.[0].created_at),
            messages: [message],
            id: messageData?.[0].id,
            sender_id: messageData?.[0].sender_id,
          }
        }
      })
      return Object.values(chatsMap)
    },
    truncateMessage(message) {
      if (message) {
        const words = message.split(' ')
        if (words.length > 10) {
          return `${words.slice(0, 10).join(' ')}...`
        }
        return message
      }
      return ''
    },
    async selectChat(chat) {
      // console.log(chat)
      // this.isSelectedChat = false
      this.isSelectedChat = true
      try {
        const response = await axios.get(`/support-chat/${chat.sender_id}`)
        if (response.status === 200) {
          this.selectedUser = chat
        }
        const data = decryptData(response.data.payload).data.messages
        // console.log(data)

        this.selectedChat = data.slice().reverse()
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error retrieving recent chats',
            icon: 'BellIcon',
            variant: 'error',
          },
        })
      }
    },
    getInitials(name) {
      if (name) {
        return name
          .split(' ')
          .map(word => word.charAt(0).toUpperCase())
          .slice(0, 2)
          .join('.')
      }
      return ''
    },

    // Handle sending a message
    sendMessage(messageId) {
      this.loadingBtn = true
      if (!this.messageForm.text.trim() && !this.messageForm.file && !this.audioBlob) {
        this.loadingBtn = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cannot send an empty message.',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        return
      }

      const formData = new FormData()

      // Append text message
      if (this.messageForm.text) {
        formData.append('text', this.messageForm.text)
      }

      // Append file if it exists
      if (this.messageForm.file) {
        formData.append('file', this.messageForm.file)
      }

      if (this.audioBlob) {
        formData.append('file', this.audioBlob, 'audio.mp3')
      }

      // Ensure messageId exists before making the API request
      if (messageId) {
        axios
          .post(`/support-chat/${messageId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data', // Ensures the proper content type
            },
          })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Message sent successfully.',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              // Optionally reset the form and fetch updated chat
              this.messageForm.text = ''
              this.messageForm.file = null
              this.audioBlob = null
              this.selectChat(this.selectedUser) // Refresh chat
              this.loadingBtn = false
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }).finally(() => {
            this.loadingBtn = false
          })
      }
    },
    scrollToBottom() {
      const { chatWindow } = this.$refs
      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight
      }
    },

  },
}
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
.selected-chat {

  background-color: #e6e6f4;
}
 /* Chat Text */
.chat-text {
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  min-width: 120px;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  margin: 0px 15px 0px 15px;
  top: 15px;
}

.chat-text::before,
.chat-text::after {
  content: '';
  position: absolute;
  border-style: solid;
}

.chat-text::before {
  border-width: 15px 15px 15px 0px;
  border-color: transparent white transparent transparent;
  top: -9.6px;
  right: -6px;
  transform: rotate(45deg);
  display: none;

}

.chat-text::after {
  border-width: 15px 15px 15px 0px;
  border-color: transparent white transparent transparent;
  top: -9.6px;
  left: -6px;
  transform: rotate(135deg);
  display: none;
}
.align-left{
  padding-bottom: 20px;
  .date-of-message{
   position: absolute;
   right: 3px;
   bottom: 3px;
   text-wrap: nowrap;
}
}
.align-right{
  padding-bottom: 20px;
  .date-of-message{
   position: absolute;
   left: 3px;
   bottom: 3px;
}
}
.date-of-message{
  font-size: 9px;
  color: #aba6a8;
}
.chat-text.align-left::after {
  display: block;
}
/* Overlay styles */
.overlay {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  padding: 15px 15px;
  min-height: 60vh !important;
}
 /* Chat window */
 .chat-window {
  position: relative;
  min-height: 60vh;
  max-height: 60vh;
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}
.chat-window::-webkit-scrollbar {
  width: 5px;
  height: auto;
}
.chat-text.align-right::before {
  display: block;
}

.chat-window::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.chat-window::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chat-window::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* recording */
.start-recording {
  background-color: #f7f7f7;
  padding: 1vh 2vw;
  border-radius: 18px ;
}

</style>
