<template>
  <b-row
    v-if="personnelEvaluationData"
    class="my-1"
  >
    <b-col
      cols="12"
      md="4"
    >
      <p v-if="managersData">
        Manager : {{ managersData }}
      </p>
      <p v-if="peerData">
        Peer : {{ peerData }}
      </p>
      <p v-if="personnelEvaluationData.request_count">
        Total Requests : {{ personnelEvaluationData.request_count }}
      </p>
      <p
        v-for="(value, key) in personnelEvaluationData.certificate_name_count"
        :key="key"
      >
        {{ key }}: {{ value }}
      </p>
      <p
        v-for="(value, key) in personnelEvaluationData.source_count"
        :key="key"
      >
        {{ key }}: {{ value }}
      </p>

      <div
        id="chart"
        style="width: 100%;"
      >
        <apexchart
          v-if="series.length"
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="series"
          class="chart-performance"
        />
      </div>
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <b-row class="h-100">
        <b-col
          v-for="(item , index) in questionsData"
          :key="index"
          cols="3"
        >
          <h5>{{ item.question_name.replaceAll(/_/g, ' ') }}</h5>
          <b-progress
            :max="100"
            height="20px"
            class="mb-3"
          >
            <b-progress-bar
              :value="item.yes_percentage"
              :label="`${(item.yes_percentage)} Yes`"
              variant="success"
              :animated="false"
            />
            <b-progress-bar
              :value="item.no_percentage"
              :label="`${(item.no_percentage)} No`"
              variant="danger"
              :animated="false"
            />
            <b-progress-bar
              :value="item.na_percentage"
              :label="`${(item.na_percentage)} Not Available`"
              variant="warning"
              :animated="false"
            />
          </b-progress>

        </b-col>

      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    personnelEvaluationData: {
      type: Object,
      default: null,
    },
    managersData: {
      type: String,
      default: null,
    },
    peerData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      questionsData: [],
      series: [], // Initialize with an empty array
      chartOptions: {
        chart: {
          height: 350,
          type: 'radialBar', // Ensure 'type' is defined
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 225,
            hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              dropShadow: {
                enabled: true,
                top: 3,
                blur: 4,
                opacity: 0.5,
              },
            },
            track: {
              background: '#fff',
              strokeWidth: '67%',
              dropShadow: {
                enabled: true,
                top: -3,
                blur: 4,
                opacity: 0.7,
              },
            },
            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                color: '#888',
                fontSize: '17px',
              },
              value: {
                color: '#111',
                fontSize: '36px',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ABE5A1'],
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Final Rate'],
      },
    }
  },
  watch: {
    personnelEvaluationData: {
      immediate: true, // React on the initial value
      handler(newValue) {
        if (newValue && newValue.final_rate != null) {
          // Update series when personnelEvaluationData changes
          this.series = [newValue.final_rate]
        }
        if (newValue && newValue.questions != null) {
          // Update series when personnelEvaluationData changes
          this.questionsData = newValue.questions || []
        }
      },
    },
  },
}
</script>
