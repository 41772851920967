<template>
  <div>
    <div
      v-if="dataLoad"
      class=" text-center my-2"
    >
      <b-spinner
        type="grow"
        label="Loading..."
      />
    </div>

    <b-card
      v-else
      title="Created By :"
      class="mb-3"
    >
      <div class=" px-5">
        <b-row>
          <b-col
            cols="12"
            class="w-100 d-flex justify-content-around flex-wrap"
          >
            <p style="font-size: x-large;">
              <strong>Task ID :</strong> <span>{{ tasksData.id }}</span>
            </p>
            <p style="font-size: x-large;">
              <strong>Name:</strong> <span>{{ tasksData.created_by.name }}</span>
            </p>
            <p style="font-size: x-large;">
              <strong>Email:</strong> <span>{{ tasksData.created_by.email }}</span>
            </p>
          </b-col></b-row>
      </div>
      <!-- <p><strong>ID:</strong> {{ tasksData.id }}</p> -->
      <div class="mt-1 show-data-tasks">
        <p v-if="tasksData.description">
          <strong>Description:</strong> {{ tasksData.description }}
        </p>
        <p v-if="tasksData.due_date">
          <strong>Due Date:</strong> {{ tasksData.due_date }}
        </p>
        <p v-if="tasksData.created_by_type">
          <strong>Created By Type:</strong> {{ tasksData.created_by_type }}
        </p>
        <p><strong>Is Late ? </strong> {{ tasksData.is_late ? 'Yes' : 'No' }}</p>
        <p v-if="tasksData.issue">
          <strong>Issue : </strong> {{ tasksData.issue }}
        </p>
        <p v-if="tasksData.priority">
          <strong>Priority : </strong> {{ tasksData.priority }}
        </p>
        <p v-if="tasksData.request_id">
          <strong>Request Id : </strong> {{ tasksData.request_id }}
        </p>
        <p v-if="tasksData.required_action">
          <strong>Required Action : </strong> {{ tasksData.required_action }}
        </p>
        <p v-if="tasksData.status">
          <strong>Status : </strong>
          <span
            :style="`cursor: pointer;  color: ${statusColor(tasksData.status)};`"
            class="hover-pointer-empty"
            @click="viewStaus(tasksData.id)"
          >
            {{ tasksData.status }}
          </span>
        </p>
        <p v-if="tasksData.updated_at">
          <strong>Updated At : </strong> {{ formattedDate(tasksData.updated_at) }}
        </p>
      </div>

      <!-- Other task details here... -->

      <div class="mt-1">
        <h5>Assigned Users</h5>
        <div class="d-flex flex-wrap">

          <b-card
            v-for="(assigned, index) in tasksData.assigned"
            :key="index"
            class="mb-3 mr-1 card-tasks-assigned"
          >
            <p><strong>Name:</strong> <span>{{ assigned.user.name }}</span></p>
            <p><strong>Email:</strong> <span>{{ assigned.user.email }}</span></p>
            <p><strong>Assigned Date:</strong> <span>{{ formattedDate(assigned.created_at) }}</span></p>
            <P><strong>Updated Date:</strong> <span>{{ formattedDate(assigned.updated_at) }}</span></p>
          </b-card>

        </div>
      </div>

      <div class="task-comments">
        <b-col
          cols="12"
          style="border-bottom: 1px solid #d8d6de;"
        >
          <h3 class="py-1">
            comments ({{ tasksData.comments.length }})
          </h3>
        </b-col>
        <b-col
          cols="12"
          style="border-bottom: 1px solid #d8d6de;"
        >

          <ul class="list-unstyled mt-2">
            <b-media tag="li">
              <template #aside>
                <div
                  style="background-color: #f0f2f7;"
                  class="px-1 py-1"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="94"
                  />
                </div>
              </template>
              <h5 class="mt-0 mb-1">
                Add Your Comment
              </h5>
              <b-input-group>
                <b-col cols="12">
                  <b-form-textarea v-model="newComment" />
                </b-col>
                <!-- File upload icon and hidden input -->
                <b-row class="py-1 px-2 w-100">
                  <b-col
                    cols="11"
                    class="d-flex pl-3"
                  >
                    <div
                      v-if="selectedFiles.length"
                      class="mt-2 w-100"
                    >
                      <h6>Selected Files:</h6>
                      <ul>
                        <li
                          v-for="(file, index) in selectedFiles"
                          :key="index"
                          class="pb-1"
                        >
                          {{ file.name }}
                          <b-button
                            variant="danger"
                            size="sm"
                            class="ml-2"
                            @click="removeFile(index)"
                          >
                            Remove
                          </b-button>
                        </li>
                      </ul>
                    </div>
                  </b-col>
                  <b-col
                    cols="1"
                    class="d-flex justify-content-end"
                  >
                    <div>
                      <feather-icon
                        icon="PaperclipIcon"
                        size="30"
                        @click="triggerFileUpload"
                      />
                      <input
                        ref="fileInput"
                        type="file"
                        multiple
                        style="display: none;"
                        @change="handleFileUpload"
                      >
                    </div>
                  </b-col>
                </b-row>

                <!-- Display selected files -->

              </b-input-group>
            </b-media>

          </ul>
          <div class="d-flex justify-content-end mb-1">
            <b-button
              v-if="!loadingBtn"
              variant="primary"
              @click="addComment"
            >
              Add commnet
            </b-button>
            <b-button
              v-if="loadingBtn"
              variant="primary"
              disabled
              class="mr-1"
            >
              <b-spinner variant="grow" />

            </b-button>

          </div>
        </b-col>
        <div class="control-all-comments">
          <b-col
            v-for="(com, index) in tasksData.comments.slice().reverse()"
            :key="index"
            cols="12"
            class="my-1 text-center d-flex align-items-center justify-content-start"
          >
            <div class="d-flex w-100">
              <div
                style="background-color: #f0f2f7;"
                class="px-2 py-1 mx-2 d-flex align-items-center justify-content-center"
              >
                <feather-icon
                  icon="UserIcon"
                  :size="com.attachments.length > 0 ? '47': '30'"
                />
              </div>
              <div class="w-100 ">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex justify-content-start"
                  >
                    <p>
                      <span style="color: #67b3f5;  font-size: x-large;">  {{ com.user.name }} </span>
                      <span style="color: #c7d0d4; font-size: medium;">{{ formattedDate(com.created_at) }}</span>
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                    class="d-flex justify-content-end"
                  >
                    <span style="color: #297dd6; font-size: medium;">  {{ com.user.email }} </span>
                  </b-col>

                </b-row>

                <p class="mb-0  d-flex">
                  {{ com.comment }}
                </p>
                <p class="mb-0 pt-1 d-flex flex-wrap justify-content-start align-items-center">
                  <span
                    v-for="(attachment, index) in com.attachments"
                    :key="index"
                    class="mr-1"
                    style="border: 1px solid #d8d6de; padding: 4px 10px "
                  >
                    <a
                      :href="`${attachment.url}`"
                      target="_blank"
                      class="text-dark"
                    >
                      <feather-icon
                        icon="FileIcon"
                        size="15"
                      />
                      {{ attachment.file_name }}
                    </a>
                    <feather-icon
                      icon="TrashIcon"
                      size="15"
                      color="red"
                      class="ml-2 cursor-pointer"

                      @click="deleteAttachment(attachment.id , com.id )"
                    />
                  </span>
                </p>
              </div>

            </div>

          </b-col>
        </div>
      </div>

    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      tasksData: [],
      newComment: '',
      loadingBtn: false,
      dataLoad: false,
      selectedFiles: [], // Array to hold selected files
    }
  },

  mounted() {
    this.showTasks()
  },

  methods: {
    statusColor(data) {
      switch (data) {
        case 'in-progress':
          return 'green'
        case 'pending':
          return '#f5851d'
        case 'completed':
          return 'blue'
        case 'cancelled':
          return 'red'
        default:
          return 'black' // Fallback color
      }
    },
    formattedDate(getDate) {
      const date = new Date(getDate)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0')
      const hours = date.getHours() % 12 || 12 // Convert to 12-hour format
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

      return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
    },
    triggerFileUpload() {
      this.$refs.fileInput.click() // Trigger file input on icon click
    },
    handleFileUpload(event) {
      this.selectedFiles = Array.from(event.target.files) // Store selected files
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1) // Remove file from selected files array
    },
    showTasks() {
      this.dataLoad = true
      axios.get(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}`).then(res => {
        this.tasksData = decryptData(res.data.payload).data
        // eslint-disable-next-line eqeqeq
        if (res.status == 200) {
          this.dataLoad = false
          this.newComment = ''
        }
      }).catch(err => {
        // console.error('Error fetching task data:', err)
        const errorMessage = err.data.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorMessage,
            icon: 'BellIcon',
            variant: 'error',
          },
        })
      })
        .finally(() => {
          this.dataLoad = false
          this.newComment = ''
        })
    },
    deleteAttachment(id, comId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to delete this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .delete(`https://gulftic-system.fci.group/api/task/comment/${comId}/delete-file/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.showTasks()
            }).catch(error => {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'You don\'t have permission to delete this comment.'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    addComment() {
      this.loadingBtn = true
      const formData = new FormData()
      formData.append('comment', this.newComment)
      this.selectedFiles.forEach((file, index) => formData.append(`attachments[${index}]`, file)) // Append each file to formData

      axios.post(`https://gulftic-system.fci.group/api/task/${this.$route.params.id}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if (res.status === 200) {
          this.loadingBtn = false
          this.newComment = ''
          this.selectedFiles = []
          this.showTasks()
        }
      })
        .catch(error => {
          if (error.response.status) {
            const payload = decryptData(error.response.data.payload).message
            const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
            const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loadingBtn = false
          this.newComment = ''
        })
    },

    viewStaus(id) {
      this.$swal({
        title: 'Select status',
        input: 'select',
        inputOptions: {
          'in-progress': 'In Progress',
          completed: 'Completed',
        },
        inputPlaceholder: 'Select Status',
        showCancelButton: true,
        inputValidator: value => new Promise((resolve, reject) => {
          if (value) {
            resolve()
          } else {
            reject(new Error('You need to select a status'))
          }
        }),
      }).then(result => {
        if (result.isConfirmed) {
          const selectedStatus = result.value
          axios.put(`https://gulftic-system.fci.group/api/task/${id}/status`, {
            status: selectedStatus,
          })

            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Status Updated',
                text: `You selected: ${selectedStatus}`,
              }).then(() => {
                // Reload the page if the status update was successful
                window.location.reload()
              })
            }).catch(error => {
              // console.error('Error updating status:', error)
              this.$swal({
                icon: 'error',
                title: `${error.data.message}`,
                text: 'Failed to update status.',
              })
            })
        }
      })
    },
  },
}
</script>

  <style lang="scss">
 @import '@core/scss/vue/libs/vue-select.scss';
  .card-tasks-assigned {
    border: 1px solid #d8d6de;
    width: fit-content;
    padding: 8px;
    display: flex;
    flex-direction: column;
    p {
      text-wrap: nowrap;
    }
  }
  .show-data-tasks {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .show-data-tasks > * {
    flex: 1 1 50%;
    box-sizing: border-box;
  }
  .task-comments {
    margin: auto;
    width: 70vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 5px;
    font-size: large;
    border: 1px solid #d8d6de;
  }
  .control-all-comments{
    max-height: 27rem;
    overflow-y: scroll;
  }
  .control-all-comments::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
  height: 6px; /* Height of the scrollbar */
}

.control-all-comments::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px;
}

.control-all-comments::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
  border-radius: 10px;
}

.control-all-comments::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb color on hover */
}
  @media screen and (max-width: 768px) {
    .task-comments {
      min-width: 80vw;
      overflow-x: scroll;
  }
}
  </style>
