var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('general-table',{attrs:{"api-url":_vm.APIURL,"edit-component":_vm.editComponent,"delete-content":false,"block-content":false,"edit-content":true,"view-content":false,"columns":_vm.columns,"type":_vm.type,"per-page":_vm.perPage,"add-component-name":_vm.addComponentName,"add-type":_vm.addType},scopedSlots:_vm._u([{key:"cell(engineers)",fn:function(data){return [_vm._t("actions",function(){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('feather-icon',{staticClass:"mr-50 cursor-pointer",attrs:{"icon":"UsersIcon","size":"16"},on:{"click":function($event){return _vm.ApprovalRow(data.item.engineers)}}})],1)]},null,data)]}},{key:"cell(components)",fn:function(data){return [_vm._t("info",function(){return [_c('div',{staticClass:"text-center"},[_c('span',{attrs:{"title":"View"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"EyeIcon"},on:{"click":function($event){return _vm.$router.push({
                  name: 'components',
                  params: { CM_id: data.item.id, Components_Name: data.item.name.en }
                })}}})],1)])]},null,data)]}},{key:"cell(copy_certificate_components)",fn:function(data){return [_vm._t("info",function(){return [_c('div',{staticClass:"text-center"},[_c('span',{attrs:{"title":"Copy"}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"CopyIcon"},on:{"click":function($event){return _vm.toggleModal(data.item.id)}}}),_c('b-modal',{attrs:{"id":("modal-" + (data.item.id)),"visible":_vm.modalShow[data.item.id] || false,"centered":"","size":"lg","title":"Copy Certificate Components","ok-only":"","ok-variant":"dark","ok-title":"Close"},on:{"hide":function($event){return _vm.closeModal(data.item.id)}}},[_c('h3',{staticClass:"my-4"},[_vm._v("provide the ID of the certificate the component should be added")]),(_vm.loader)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"label":"Large Spinner","variant":"primary"}})],1):_c('div',[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":"","type":"number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputAttrs = ref.inputAttrs;
                var inputHandlers = ref.inputHandlers;
                var addTag = ref.addTag;
                var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"type":"number","placeholder":"New tag - Press enter to add"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v("Add")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((_vm.copyArrayComponents),function(tag){return _c('b-card',{key:tag,staticClass:"mt-1 mr-1",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_vm._v("remove")])],1)}),1)]}}],null,true),model:{value:(_vm.copyArrayComponents),callback:function ($$v) {_vm.copyArrayComponents=$$v},expression:"copyArrayComponents"}})],1),_c('b-alert',{attrs:{"variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_vm._v(" "+_vm._s(_vm.errorMes)+" ")]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CopyComponentsAPi(data.item.id)}}},[_vm._v("Copy Components "+_vm._s(data.item.id))])],1)],1)],1)])]},null,data)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }