export default [
  {
    path: '/requests',
    name: 'requests',
    component: () => import('@/views/requests/Requests.vue'),
    meta: {
      title: 'requests ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-request',
    name: 'add-request',
    component: () => import('@/views/requests/control-requests/AddEditRequests.vue'),
    meta: {
      title: 'Add Request',
      requiresAuth: false,
      action: 'browes',
      resource: 'Add Request',
      pageTitle: 'Add Request',
      breadcrumb: [
        {
          text: 'Add Request',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/EditSubCertificate/:id',
    name: 'edit-sub-certificate',
    component: () => import('@/views/requests/control-requests/EditSubCertificate.vue'),
    meta: {
      title: 'Requests Edit Certificate ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests Edit Certificate',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/RequestHistories/:id',
    name: 'request-histories',
    component: () => import('@/views/requests/RequestHistories.vue'),
    meta: {
      title: 'Requests Histories ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Requests Histories',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/SetOffer/:id',
    name: 'set-offer',
    component: () => import('@/views/requests/control-requests/SetOffer.vue'),
    meta: {
      title: 'Set Offer ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Set Offer ',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/AssignEngineer/:id/:type',
    name: 'assign-engineer',
    component: () => import('@/views/requests/control-requests/AssignEngineer.vue'),
    meta: {
      title: 'Assign Engineer',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Assign Engineer',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/EditPaymentStatus/:id',
    name: 'edit-payment-status',
    component: () => import('@/views/requests/control-requests/EditPaymentStatus.vue'),
    meta: {
      title: 'Edit Payment Status ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requests',
      pageTitle: 'Requests',
      breadcrumb: [
        {
          text: 'Edit Payment Status',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/show-invoice/:id',
    name: 'show-invoice',
    component: () => import('@/views/requests/control-requests/ShowInvoice.vue'),
    meta: {
      title: 'Invoice',
      requiresAuth: false,
      action: 'browes',
      resource: 'Invoice',
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'Invoice Info',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parent_request_code/:id',
    name: 'parent_request_code',
    component: () => import('@/views/requests/control-requests/ParentRequestCode.vue'),
    meta: {
      title: 'Parent Request',
      requiresAuth: false,
      action: 'browes',
      resource: 'Parent Request',
      pageTitle: 'Parent Request',
      breadcrumb: [
        {
          text: 'Parent Request',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
