<template>
  <b-row
    style="height: 80vh"
    class="d-flex align-content-center justify-content-center"
  >
    <b-col
      cols="12"
      class="d-flex justify-content-center"
    >
      <Logo />
    </b-col>
    <b-col
      cols="7"
      class="d-flex justify-content-center py-1"
    >
      <LogoText class="w-100" />
    </b-col>

    <b-col
      cols="12"
      class="py-4"
    >
      <div
        v-if="!loader"
        style="width: 100%;"
      >
        <SparkBoxes :data-table="dataTable" />
      </div>

      <div
        v-else
        class="text-center"
      >
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem; margin-top: 2rem;"
          label="Large Spinner"
        />
      </div>
    </b-col>

  </b-row>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'
import LogoText from './components/LogoText.vue'
import Logo from './components/Logo.vue'
import SparkBoxes from './components/SparkBoxes.vue'

export default {
  components: {
    // apexchart: VueApexCharts,
    LogoText,
    Logo,
    SparkBoxes,
  },
  data() {
    return {
      dataTable: [],
      loader: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.getAnalysis()
    }, 5000)
  },
  methods: {
    getAnalysis() {
      axios.get('/analysis').then(res => {
        const data = decryptData(res.data.payload).data?.analysis?.requests_analysis
        const jeem1Data = data.find(item => item.source === 'jeem1')
        const moiatData = data.find(item => item.source === 'moiat')
        const saberData = data.find(item => item.source === 'saber')
        const sfdaData = data.find(item => item.source === 'sfda')
        const slsData = data.find(item => item.source === 'sls')
        this.dataTable = [
          ['Jeem1', jeem1Data.total_count],
          ['Saber', saberData.total_count],
          ['Sfda', sfdaData.total_count],
          ['Moiat', moiatData.total_count],
          ['Sls', slsData.total_count],
        ]
        this.loader = false
      }).catch(error => {
        if (error) {
          this.loader = false
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style></style>
