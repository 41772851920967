<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :per-page="perPage"
    />
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: `certificate/${this.$route.params.MCR_id}/${this.$route.params.CM_id}/components/${this.$route.params.SE_id}/section/${this.$route.params.RQ_id}/requirement`,
      addType: 'Add Requirement',
      addComponentName: 'add-requirements',
      editComponent: 'edit-requirements',
      viewContent: true,
      type: 'page',
      perPage: 50,
      columns: [
        { key: 'id', sortable: false },
        { key: 'label_lang.name_ar', label: 'Label Ar' },
        { key: 'label_lang.name_en', label: 'Label En' },
        { key: 'value_lang.name_ar', label: 'Value Ar' },
        { key: 'value_lang.name_en', label: 'Value En' },
        { key: 'section_component_name', label: 'Section Component Name' },
        { key: 'actions' },
      ],
    }
  },
  methods: {

  },
}
</script>

    <style>

    </style>
