<template>
  <div>
    <slot :status="moduleStatus" />
    <b-card v-if="!moduleStatus">
      <b-col
        v-if="!dataLoad"
        cols="12"
        md="12"
        class="d-flex align-items-center justify-content-end"
      >
        <MakeSheetExcel
          :need-excel="needExcel"
          :search-query="searchQuery"
          :skip-page="skipPage"
        />

        <b-button
          v-if="addType.length"
          ref="actions"
          variant="primary"
          class="ml-auto mr-1"
          @click="goToPageOrOpenModal()"
        >
          <span class="text-nowrap"> {{ addType }} </span>
        </b-button>
      </b-col>

      <searchAndFilterDataTable
        :search-box="searchBox"
        :data-load="dataLoad"
        :show-date-input="SelectedItemSearchOptions.length > 0 ? true : false"
        :selected-item-search-options="SelectedItemSearchOptions"
        :get-all-data="getAllData"
        :search-and-select="searchAndSelect"
        @handelSelectedItem="handelSelectedItem"
        @handleSearchItemsArray="handleSearchItemsArray"
      />

      <selectDateReq
        :show-date-input="SelectedItemSearchOptions.length > 0 ? true : false"
        :data-load="dataLoad"
        @handleDate="handleDate"
      />

      <selectCertificateReq
        :selectcertificate="selectcertificate"
        :data-load="dataLoad"
        :certificate-options="certificateOptions"
        :select-subcertificate="selectSubcertificate"
        :get-all-data="getAllData"
        @getIdCertificate="handleCertificateId"
        @getIdSubCertificate="handleSubCertificateId"
      />

      <b-row>
        <b-col md="12">
          <div
            v-if="dataLoad"
            class=" text-center my-2"
          >
            <b-spinner
              type="grow"
              label="Loading..."
            />
          </div>
          <b-table
            v-else
            ref="productsTable"
            :key="tableDataKey"
            :items="rows"
            :fields="columns"
            :current-page="page"
            :per-page="perPage"
            aria-busy="true"
            class="position-relative"
            responsive
            show-empty
            bordered
            primary-key="id"
            :no-local-sorting="true"
            :post-content="PostContent"
            >
            <template
              v-for="(_, slotName) of $scopedSlots"
              v-slot:[slotName]="scope"
            >
              <slot
                :name="slotName"
                v-bind="scope"
              />
            </template>

            <template #cell(actions)="data">
              <slot
                name="actions"
                v-bind="data"
              >
                <div class="padd">
                  <span
                    v-if="PostContent"
                    title="Post"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      class="cursor-pointer"
                      @click="PostEntry(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="PostContent"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="
                        $router.push({
                          name: 'PostEntry',
                          params: { id: data.item.id }
                        })
                      "
                    />
                  </span>
                  <span
                    v-if="viewContent"
                    title="View"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="cursor-pointer"
                      @click="viewPage(data.item)"
                    />
                  </span>
                  <span
                    v-if="editContent"
                    title="Edit"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="cursor-pointer"
                      @click="
                        editToPage(
                          data.item.id,
                        )
                      "
                    />
                  </span>
                  <span
                    v-if="deleteContent"
                    title="Delete"
                  >
                    <feather-icon
                      id="popover-button-1"
                      icon="TrashIcon"
                      class="cursor-pointer"
                      @click="deleteRow(data.item.id)"
                    />
                  </span>
                  <span
                    v-if="CancelContent"
                    title="Cancel"
                  >
                    <feather-icon
                      icon="SlashIcon"
                      class="cursor-pointer"
                      @click="CancelEntry(data.item.id)"
                    />
                  </span>
                </div>
              </slot>
            </template>

            <template #cell(name)="data">
              <span style="font-size: smaller; text-wrap: nowrap">
                {{ data.item.name }}
              </span>
            </template>

          </b-table>
        </b-col>

        <!-- //////////////////////////// pagination //////////////////////////// -->
      </b-row>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-between "
          >
            <div>
              Results: <span class="font-weight-bold">{{ totalRows }}</span>
            </div>
            <!-- pagination -->
            <b-pagination
              :value="page"
              :total-rows="totalRows"
              :per-page="perPage"
              align="end"
              first-number
              last-number
              :total-visible="7"
              class="mb-0 mt-1"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="getAllData($event)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapMutations } from 'vuex'
import table from './table'
import decryptData from '@/store/auth/decryptData'
import selectCertificateReq from './filter-by/SelectCertificateReq.vue'
import selectDateReq from './filter-by/SelectDateReq.vue'
import searchAndFilterDataTable from './filter-by/SearchAndFilterDataTable.vue'
import MakeSheetExcel from './specific-action/MakeSheetExcel.vue'

export default {
  components: {
    selectCertificateReq,
    selectDateReq,
    searchAndFilterDataTable,
    MakeSheetExcel,
  },
  props: {
    SelectedItemSearchOptions: {
      type: Array,
      default: () => [],
    },
    apiUrl: {
      type: String,
      default: () => '',
    },
    addType: {
      type: String,
      default: () => '',
    },
    addComponentName: {
      type: String,
      default: () => '',
    },
    editComponent: {
      type: String,
      default: () => '',
    },
    viwComponent: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    needExcel: {
      type: Boolean,
      default: () => false,
    },
    viewContent: {
      type: Boolean,
      default: () => true,
    },
    editContent: {
      type: Boolean,
      default: () => true,
    },
    PostContent: {
      type: Boolean,
      default: () => false,
    },
    deleteContent: {
      type: Boolean,
      default: () => true,
    },
    CancelContent: {
      type: Boolean,
      default: () => false,
    },
    searchBox: {
      type: Boolean,
      default: () => true,
    },
    certificateOptions: {
      type: Array,
      default: () => [],
    },
    selectcertificate: {
      type: Boolean,
      default: () => false,
    },
    selectSubcertificate: {
      type: Boolean,
      default: () => false,
    },
    subCertificateOptions: {
      type: Array,
      default: () => [],
    },
    searchAndSelect: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      selectedItem: '' || { value: 'all', text: 'All Data' },
      rows: [],
      totalRows: null,
      page: 1,
      searchQuery: this.$route.query.search,
      dataLoad: false,
      tableDataKey: 0,
      perPage: 20,
      getIdCertificate: null,
      getIdSubCertificate: null,
      skipPage: 0,
      searchItems: [],
    }
  },
  setup() {
    const { moduleStatus } = table()

    return {
      moduleStatus,
    }
  },
  /* eslint-disable eqeqeq */
  watch: {
    '$route.query': {
      handler(newQuery) {
        // Handle page change
        const newPage = newQuery.page || 1
        if (newPage !== this.page) {
          this.page = newPage
          this.getAllData(newPage)
        }

        // Handle search query change
        const newSearch = newQuery.search || ''
        if (newSearch !== this.searchQuery) {
          // console.log(newSearch)
          this.searchQuery = newSearch
          this.getAllData(newPage) // Pass the current page to maintain context
        }
      },
      immediate: true, // Ensures the watcher triggers on component mount
      deep: true, // Watches for deep changes in the query object
    },
  },
  mounted() {
    this.getAllData(this.page)
  },
  methods: {
    handleCertificateId(id) {
      this.getIdCertificate = id
    },
    handleSubCertificateId(id) {
      this.getIdSubCertificate = id
    },
    handleDate(fromDate, toDate) {
      this.fromDate = fromDate
      this.toDate = toDate
    },
    handelSelectedItem(item) {
      this.selectedItem = item
    },
    handleSearchItemsArray(search) {
      this.searchItems = search
    },

    editToPage(id, name) {
      if (this.type === 'page') {
        this.$router.push({ name: this.editComponent, params: { id, name } })
      } else {
        this.$parent.$refs.genmodal.show(id)
        this.$store.commit('generalIds/SET_ID', id)
      }
    },
    viewPage(data) {
      if (this.viwComponent) {
        this.$router.push({ name: this.viwComponent, params: { id: data.id } })
        this.$store.commit('generalIds/SET_ID', data.id)
      } else {
        this.$parent.$refs['view-modal'].show()
        this.$store.commit('generalIds/SET_ID', data.id)
      }
    },

    goToPageOrOpenModal() {
      if (this.type === 'page') {
        this.$router.push({ name: this.addComponentName })
      } else {
        this.$parent.$refs.genmodal.show()
      }
    },

    ...mapMutations('app', ['setShowFilterRow']),

    async getAllData(page = 1) {
      this.setShowFilterRow(false)

      this.dataLoad = true
      try {
        const skip = (page - 1) * this.perPage
        this.skipPage = skip
        const params = {
          skip,
          certificate: this.getIdCertificate,
          sub: this.getIdSubCertificate,
          ...(this.fromDate && { from: this.fromDate }),
          ...(this.toDate && { to: this.toDate }),
          // eslint-disable-next-line
          ...(this.searchItems && this.searchItems.length > 0
            ? this.searchItems.reduce((acc, item) => {
              acc[this.selectedItem.value] = this.searchQuery || ''
              if (item.searchQuery && item.selectedOption) {
                acc[item.selectedOption.value] = item.searchQuery || ''
              }
              return acc
            }, {})
            : this.SelectedItemSearchOptions.length > 0
              ? { [this.selectedItem.value]: this.searchQuery }
              : { search: this.searchQuery }),
        }

        const response = await axios.get(this.apiUrl, { params })

        if (response.status === 200) {
          const responseData = decryptData(response.data.payload)
          // console.log(responseData)
          this.setShowFilterRow(true)
          this.rows = responseData?.data[Object.keys(responseData?.data)[0]]
          this.totalRows = responseData.data.count || responseData.data.length
        }
      } catch (error) {
        if (error.response.status === 500) {
          // console.log(decryptData(error.response.data.payload))
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Server Error',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      } finally {
        this.dataLoad = false
        // this.setShowFilterRow(false)
      }
    },

    deleteRow(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          const apiUrlUsed = this.apiUrl.indexOf('?') !== -1 ? this.apiUrl.slice(0, this.apiUrl.indexOf('?')) : this.apiUrl
          axios
            .delete(`${apiUrlUsed}/${id}`)
            .then(() => {
              this.$swal('Deleted!', 'Deleted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    CancelEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/cancel`)
            .then(() => {
              this.$swal('Cancel!', 'Cancel Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },

    PostEntry(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Post it!',
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .put(`${this.apiUrl}/${id}/post`)
            .then(() => {
              this.$swal('Posted!', 'Posted Successfully.', 'success')
              this.getAllData()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.table-responsive::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.table-responsive .b-table tbody tr:hover {
  background-color: #297dd6;
  color: white;
  .toggle-content {
    color: white;
  }
  .toggle-content:hover {
    color: black;
  }
  .currency-conversion-div-button{
    background-color: black !important;
  }
  .currency-conversion-div{
    border: 1.5px solid white;
    .badge{
    background-color: black !important;;
  }

  }
}
.padd span {
  padding: 2px;
}
td {
  padding: 0px !important;
  text-align: center !important;
  text-wrap: nowrap !important;
}
.table-responsive {
  position: relative;
  overflow: auto;
  max-height: 680px;
}
table thead {
  position: sticky !important;
  top: 0;
  background: white;
  z-index: 3;
}
#table-spinner {
  position: absolute;
  right: 50%;
  top: 5rem;
  z-index: 999999999;
}
.change-status {
  cursor: pointer;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
