<template>
  <b-card>
    <b-row>
      <b-col md="12">
        <div
          v-if="dataLoad"
          class="text-center my-2"
        >
          <b-spinner
            type="grow"
            label="Loading..."
          />
        </div>

        <div v-else>

          <h1 class="text-center py-2">
            Transaction
          </h1>
          <div>
            <b-row class="w-100">
              <b-col
                cols="12"
                md="3"
              > <b-form-group
                label="Title"
                label-for="Title"
              >
                <b-form-input
                  id="title"
                  v-model="accountingEntry.title"
                  disabled
                />
              </b-form-group></b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Code"
                  label-for="Code"
                >
                  <b-form-input
                    id="code"
                    v-model="accountingEntry.code"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Created By"
                  label-for="created by"
                >
                  <b-form-input
                    id="created_by"
                    v-model="accountingEntry.created_by"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Created At"
                  label-for="created at"
                >
                  <b-form-input
                    id="created_at"
                    v-model="formattedDate"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="w-100">
              <b-col
                cols="12"
                md="3"
              > <b-form-group
                label="Amount"
                label-for="Amount"
              >
                <b-form-input
                  id="amount"
                  v-model="amountInput"
                  disabled
                />
              </b-form-group></b-col>
              <b-col
                cols="12"
                md="3"
              > <b-form-group
                label="Date"
                label-for="Date"
              >
                <b-form-input
                  id="date"
                  v-model="accountingEntry.date"
                  disabled
                />
              </b-form-group></b-col>
              <b-col
                cols="12"
                md="3"
              > <b-form-group
                label="Status"
                label-for="Status"
              >
                <b-form-input
                  id="status"
                  v-model="accountingEntry.status"
                  disabled
                />
              </b-form-group></b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Office"
                  label-for="Office"
                >
                  <b-form-input
                    id="office"
                    v-model="accountingEntry.office.name"
                    disabled
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row class="w-100">

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Description"
                  label-for="Description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="accountingEntry.description"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="accountingEntry.status === 'post'"
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Posted By"
                  label-for="Posted By"
                >
                  <b-form-input
                    id="posted_by"
                    v-model="accountingEntry.posted_by"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="accountingEntry.status === 'post'"
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Posted At"
                  label-for="Posted At"
                >
                  <b-form-input
                    id="posted_at"
                    v-model="accountingEntry.posted_at"
                    disabled
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </div>
          <b-table
            :items="formattedTransactions"
            :fields="columns"
            striped
            hover
            responsive
            class="text-center my-3 position-relative"
          >
            <!-- <template #cell(id)="data , index">

              <div>
                {{index}}
              </div>
            </template> -->
            <template #cell(d_amount)="data">

              <div>
                {{ data.item.d_amount }} {{ data.item.d_currency }}
              </div>
            </template>
            <template #cell(amount)="data">

              <div>
                {{ data.item.amount }} {{ data.item.currency }}
              </div>
            </template>
          </b-table>
        </div>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      accountingEntry: [],
      transactions: [],
      amountInput: '',

      columns: [
        // { key: 'id', label: '#' },
        { key: 'account_name', label: 'Account' },
        { key: 'd_amount', label: 'Default Amount' },
        { key: 'amount', label: 'Amount' },
        { key: 'rate', label: 'Rate' },
        { key: 'type', label: 'Type' },
      ],
      dataLoad: false,
    }
  },
  computed: {
    formattedTransactions() {
      return this.transactions.map(transaction => ({
        ...transaction,
        account_name: transaction.account ? `(${transaction.account.code}) ${transaction.account.name}` : '',
      }))
    },
    formattedDate() {
      const date = new Date(this.accountingEntry.created_at)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0')
      const hours = date.getHours() % 12 || 12 // Convert to 12-hour format
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

      return `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`
    },
  },
  mounted() {
    this.getAccountingEntry()
  },
  methods: {
    getAccountingEntry() {
      this.dataLoad = true
      axios
        .get(`accounting-entry/${this.$route.params.id}`)
        .then(res => {
          const decryptedData = decryptData(res.data.payload)
          this.accountingEntry = decryptedData?.data?.entry
          this.amountInput = `${decryptedData?.data?.entry.amount} ${decryptedData?.data?.entry.currency}`

          this.transactions = decryptedData?.data?.entry?.transactions
          // console.log(this.accountingEntry)
        })
        .finally(() => {
          this.dataLoad = false
        })
    },
  },
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
