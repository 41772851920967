export default [
  {
    path: '/post',
    name: 'post',
    component: () => import('@/views/Post/Post.vue'),
    meta: {
      title: 'Post',
      requiresAuth: false,
      action: 'browes',
      resource: 'Post',
      pageTitle: 'Post',
      breadcrumb: [
        {
          text: 'Post',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post/add',
    name: 'add-post',
    component: () => import('@/views/Post/control-post/AddEditPost.vue'),
    meta: {
      title: 'Add Post',
      requiresAuth: false,
      action: 'create',
      resource: 'post',
      pageTitle: 'post',
      breadcrumb: [
        {
          text: 'post',
          to: '/post',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/post/edit/:id',
    name: 'edit-post',
    component: () => import('@/views/Post/control-post/AddEditPost.vue'),
    meta: {
      title: 'Edit Post',
      requiresAuth: false,
      pageTitle: 'post',
      breadcrumb: [
        {
          text: 'post',
          to: '/post',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
