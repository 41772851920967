<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="request_code"
            rules="request_code"
          >
            <b-form-group
              label="Add Request Code To Assign Parent For Request"
            >
              <b-input
                id="request_code"
                v-model="assignParentForm.request_code"
                :state="getValidationState(validationContext)"
                placeholder="Request Code"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignParent()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="assignParent()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {},
  data() {
    return {
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      loading: false,

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const assignParentForm = ref({})
    return {
      getValidationState,
      assignParentForm,
    }
  },
  mounted() {
    if (this.$route.query.parent_code) {
      this.assignParentForm.request_code = this.$route.query.parent_code
    }
  },
  methods: {
    assignParent() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.assignParentForm) {
          formData.append(key, this.assignParentForm[key])
        }

        axios
          .post(`https://gulftic-system.fci.group/api/requests/${this.$route.params.id}/set-parent-request`, formData)
          .then(res => {
            if (res.status === 200) {
              this.$router.push({ name: 'requests' })
              this.$toasted.show('Updated Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMessage,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
