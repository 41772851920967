<template>
  <b-modal
    v-model="localShowModal"
    :title="`Add Currency Conversion TO ${officeName}`"
    centered
    ok-only
    :ok-title="`Cancel`"
  >
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <validation-provider
        #default="validationContext"
        name="currency"
        rules="required"
      >
        <b-form-group
          label="currency"
          label-for="currency"
          :state="getValidationState(validationContext)"
        >
          <b-form-input
            id="currency"
            v-model="conversionRateForm.currency"
            placeholder="Select Currency"
            readonly
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="validationContext"
        name="Conversion Rate"
        rules="required"
      >
        <b-form-group
          label="Conversion Rate"
          label-for="conversion_rate"
          :state="getValidationState(validationContext)"
        >
          <b-form-input
            id="conversion_rate"
            v-model="conversionRateForm.conversion_rate"
            :state="getValidationState(validationContext)"
            trim
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <b-button
        v-if="!loader"
        variant="primary"
        :disabled="invalid || Object.values(errors).length > 0"
        @click="addCurrencyConversion(officeId)"
      >
        Edit Currency
      </b-button>
      <b-button
        v-if="loader"
        variant="primary"
        disabled
        class="mr-1"
      >
        <b-spinner
          small
          type="grow"
        />
        Loading...
      </b-button>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'

export default {
  name: 'EditCurrencyConversion',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    officeName: {
      type: String,
      required: true,
    },
    itemCurrency: {
      type: String,
      required: true,
    },
    itemConversionRate: {
      type: String,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    officeId: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const conversionRateForm = ref({
    })
    return {
      getValidationState,
      conversionRateForm,
    }
  },
  data() {
    return {
      required,
      validationErrors: {},
      loader: false,
      errors: {},
      localShowModal: this.showModal,
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal
    },
    localShowModal(newVal) {
      this.$emit('update:showModal', newVal)
      this.$emit('refreshCurrencyConversions')
    },
  },
  mounted() {
    this.conversionRateForm = {
      conversion_rate: this.itemConversionRate,
      currency: this.itemCurrency,
    }
  },
  methods: {
    addCurrencyConversion() {
      this.loader = true
      const formData = new FormData()

      formData.append('_method', 'PUT')
      formData.append('office_id', this.officeId)
      Object.entries(this.conversionRateForm).forEach(([key, value]) => {
        formData.append(key, value)
      })

      axios
        .post(`currency/${this.itemId}`, formData)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            this.showModal = false
            this.$emit('refreshCurrencyConversions')
            this.conversionRateForm = {}
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
        .catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        })
        .finally(() => {
          this.loader = false
          this.$emit('refreshCurrencyConversions')
        })
    },
  },
}
</script>
