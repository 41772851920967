export default [
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement',
    name: 'requirements',
    component: () => import(
      '@/views/main-certificate//certificate/components/section/requirements/Requirements.vue'
    ),
    meta: {
      title: 'Requirements ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Requirements',
      pageTitle: 'Requirements',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'Requirements',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement/add',
    name: 'add-requirements',
    component: () => import(
      '@/views/main-certificate/certificate/components/section/requirements/control-requirements/AddEditRequirements.vue'
    ),
    meta: {
      title: 'Add requirements ',
      requiresAuth: false,
      action: 'create',
      resource: 'Add Requirements',
      pageTitle: 'Add Requirements',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'Requirements',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path:
      '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement/:id',
    name: 'edit-requirements',
    component: () => import(
      '@/views/main-certificate/certificate/components/section/requirements/control-requirements/AddEditRequirements.vue'
    ),
    meta: {
      title: 'Edit requirements ',
      requiresAuth: false,
      pageTitle: 'Edit Requirements',
      breadcrumb: [
        {
          text: 'Main Certificate',
          to: '/mainCertificate',
        },
        {
          text: 'Certificate',
          to: '/mainCertificate/:MCR_id/:Certificate_Name',
        },
        {
          text: 'Components',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name',
        },
        {
          text: 'Sections',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section',
        },
        {
          text: 'Requirements',
          to:
            '/mainCertificate/:MCR_id/:Certificate_Name/certificate/:CM_id/:Components_Name/components/:SE_id/:Section_Name/section/:RQ_id/:Requirements_Name/requirement',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
