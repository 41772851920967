<template>
  <b-row class="mb-2">
    <b-col
      v-if="selectcertificate"
      cols="6"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <v-select
        v-if="!dataLoad"
        v-model="showNameCertificate"
        class="d-inline-block w-100"
        :options="certificateOptions"
        placeholder="Select By Certificate"
        label="text"
        :searchable="true"
        :clearable="true"
        @input="searchCertificateTable"
        @clear="clearCertificateSelection"
      />
    </b-col>
    <b-col
      v-if="selectSubcertificate"
      cols="6"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <v-select
        v-if="!dataLoad"
        v-model="showNameSubCertificate"
        class="d-inline-block w-100"
        :options="localSubCertificateOptions"
        placeholder="Select By Sub Certificate"
        label="text"
        :searchable="true"
        :clearable="true"
        @input="searchSubCertificateTable"
        @clear="clearSubCertificateSelection"
      />
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    'v-select': VSelect,
  },
  props: {
    selectcertificate: {
      type: Boolean,
      default: () => false,
    },
    dataLoad: {
      type: Boolean,
      default: () => false,
    },
    certificateOptions: {
      type: Array,
      default: () => [],
    },
    selectSubcertificate: {
      type: Boolean,
      default: () => false,
    },
    getAllData: {
      type: Function,
      default: () => () => {},
    },
  },
  data() {
    return {
      localSubCertificateOptions: [],
      showNameCertificate: null,
      showNameSubCertificate: null,
    }
  },
  watch: {
    showNameCertificate(newValue) {
      if (newValue === null) {
        this.clearCertificateSelection()
      }
    },
  },
  mounted() {
    if (this.selectSubcertificate === true) {
      axios
        .get('certificate/get-sub')
        .then(res => {
          const dataSubCertificate = decryptData(res.data.payload)?.data
            ?.groups
          this.localSubCertificateOptions = dataSubCertificate.map(item => ({
            value: item.id,
            text: `${item.name?.ar} - ${item.name?.en}`,
          }))
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  methods: {
    async searchCertificateTable(selectedOption) {
      if (!selectedOption) {
        this.$emit('getIdCertificate', null)
        this.showNameCertificate = null
      } else {
        this.$emit('getIdCertificate', selectedOption.value)
        this.showNameCertificate = selectedOption.text

        try {
          const response = await axios.get(
            `certificate/${selectedOption.value}`,
          )
          const data = decryptData(response.data.payload)?.data?.certificates
          this.localSubCertificateOptions = data.map(item => ({
            value: item.id,
            text: `${item.name?.ar} - ${item.name?.en}`,
          }))
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error}`,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      }

      await this.getAllData()
    },

    async searchSubCertificateTable(selectedOption) {
      if (!selectedOption) {
        this.$emit('getIdSubCertificate', null)

        this.showNameSubCertificate = null
      } else {
        this.$emit('getIdSubCertificate', selectedOption.value)
        this.showNameSubCertificate = selectedOption.text
      }

      await this.getAllData()
    },

    async clearCertificateSelection() {
      this.$emit('getIdCertificate', null)
      this.showNameCertificate = null
      this.$emit('getIdSubCertificate', null)
      this.showNameSubCertificate = null
      try {
        const response = await axios.get('certificate/get-sub')
        const data = decryptData(response.data.payload)?.data?.groups

        this.localSubCertificateOptions = data.map(item => ({
          value: item.id,
          text: `${item.name?.ar} - ${item.name?.en}`,
        }))
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${error}`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }

      await this.getAllData()
    },

    async clearSubCertificateSelection() {
      this.$emit('getIdSubCertificate', null)
      this.showNameSubCertificate = null
      await this.getAllData()
    },
  },
}
</script>
